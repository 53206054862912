import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetTeamFullMigsV2Document,
  MigInputV2,
  UpdateTeamMigDocument,
} from '../../../generated/graphql';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../services/howweErrorParser';
import { ErrorAlert } from '../../ErrorAlert';
import { FullscreenModal } from '../../FullscreenModal';
import { MigModalState } from '../MigSetupPage';
import { MigSetupModalContent } from './MigSetupModalContent';
import { useTeamMigLazyV2 } from './hooks/useTeamMigV2';
import { migDTOtoMigData } from '../../../services/migUtils';

interface Props {
  onClose: () => void;
  teamId: string;
  tenantId?: string;
  migModalState: MigModalState | null;
}

export const EditMigModal = ({
  onClose,
  teamId,
  tenantId,
  migModalState,
}: Props) => {
  const { t } = useTranslation();
  const { fetchTeamMig, teamMig, loading } = useTeamMigLazyV2();
  const isOpen = migModalState?.type === 'EditMig';

  useEffect(() => {
    if (migModalState?.type === 'EditMig') {
      fetchTeamMig({
        variables: {
          teamId,
          migId: migModalState.id,
          tenantId,
        },
      });
    }
  }, [fetchTeamMig, migModalState, teamId, tenantId]);

  const teamMigWithAdjustedPeriodData =
    teamMig && migDTOtoMigData(teamMig, true);

  const [updateCurrentMig, { error: updateError, loading: updateLoading }] =
    useMutation(UpdateTeamMigDocument, {
      onCompleted: (response) => {
        showNotification('success', {
          message: <strong>{t('EditMigModal.saveSuccess')}</strong>,
          description: response.updateTeamMig.name,
        });

        onClose();
      },
      refetchQueries: () => [
        {
          query: GetTeamFullMigsV2Document,
          variables: { teamId, tenantId },
        },
      ],
    });

  const handleSave = (migData: MigInputV2) => {
    if (migModalState?.type !== 'EditMig') {
      throw new Error(
        `Invalid type for edit MIG, expected EditMig and got: ${migModalState?.type} `
      );
    }

    if (!teamMigWithAdjustedPeriodData) {
      throw new Error("TeamMig is null, can't update it");
    }

    updateCurrentMig({
      variables: {
        teamId,
        tenantId,
        migId: migModalState.id,
        rev: teamMigWithAdjustedPeriodData.rev,
        mig: migData,
      },
    });
  };

  return (
    <FullscreenModal
      title={t('EditMigModal.title')}
      footer={null}
      open={isOpen}
      onCancel={onClose}
      styles={{ body: { padding: 0 } }}
      destroyOnClose
    >
      <MigSetupModalContent
        key={teamMig?.id}
        migData={teamMigWithAdjustedPeriodData}
        fetchLoading={loading}
        saveLoading={updateLoading}
        saveErrors={howweErrorParser(updateError)}
        handleSave={handleSave}
        onClose={onClose}
        teamId={teamId}
        tenantId={tenantId}
      />
      <ErrorAlert error={updateError} title={t('EditMigModal.error')} />
    </FullscreenModal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_MIG = gql`
  mutation updateTeamMig(
    $teamId: ID!
    $migId: ID!
    $mig: MigInputV2!
    $rev: String!
    $tenantId: ID
  ) {
    updateTeamMig(
      teamId: $teamId
      migId: $migId
      mig: $mig
      rev: $rev
      tenantId: $tenantId
    ) {
      id
      name
      description
      unit
      startDate
      timePeriodInterval
      lastOutcomeDate
      firstOutcomeDate
      preferredOutcome
      minY
      maxY
      weight
      periodData {
        targetDate
        goal
        actual
        historic
      }
      team2 {
        id
        domainId {
          itemId
        }
        name
      }
    }
  }
`;
