import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useTeams } from '../../../../hooks/useTeams';
import { stringSort } from '../../../../services/stringSort';

interface Props extends SelectProps {
  showDefaultOption?: boolean;
}

const ALL_TEAMS_KEY = 'all_teams';

export const TeamSelector = ({
  onChange,
  onClear,
  loading,
  value,
  id,
  showDefaultOption = false,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const { teams } = useTeams();
  const { Option } = Select;

  const teamsOptions = teams
    .sort((a, b) => stringSort(a.name, b.name))
    .map((t) => (
      <Option title={t.name} key={t.domainId.itemId} value={t.domainId.itemId}>
        {t.name}
      </Option>
    ));

  return (
    <Select
      id={id}
      showSearch
      loading={loading}
      placeholder={t('common.searchTeamPlaceholder')}
      optionFilterProp="children"
      style={{ width: '255px' }}
      value={!value && showDefaultOption ? ALL_TEAMS_KEY : value}
      allowClear
      onClear={onClear}
      onChange={(value, option) => {
        if (value === ALL_TEAMS_KEY) {
          return onClear?.();
        }
        return onChange?.(value, option);
      }}
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      {...props}
    >
      {showDefaultOption && (
        <Option
          title={t('TeamSelector.all_teams')}
          key={ALL_TEAMS_KEY}
          value={undefined}
        >
          {t('TeamSelector.all_teams')}
        </Option>
      )}

      {teamsOptions}
    </Select>
  );
};
