import { gql } from '@apollo/client';
import {
  DurationStatus,
  InitiativeTable__TopInitiativeReportFragment,
} from '../../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../componentLibrary/Colors';
import Table, { ColumnProps } from 'antd/es/table';
import { match } from 'ts-pattern';
import { CheckIcon } from '../../../../../icons/overviewIcons/CheckIcon';
import { WarningIcon } from '../../../../../icons/performanceIcons/WarningIcon';
import { UpcomingEventIcon } from '../../../../../icons/overviewIcons/UpcomingEventIcon';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import { ManageSearchIcon } from '../../../../../icons/ManageSearch';
import { Link } from 'react-router-dom';
import { stringSort } from '../../../../../services/stringSort';
import { MilestoneOrActiveToggleEnum } from '../../../initiatives/initiativeDetails/milestoneOrActivityToggle/MilestoneOrActiveToggle';

interface Props {
  initiatives: InitiativeTable__TopInitiativeReportFragment[];
  milestonesOrActivities: MilestoneOrActiveToggleEnum;
}

interface Stats {
  milestones: { completed: number; overdue: number; upcoming: number };
  activities: { completed: number; overdue: number; upcoming: number };
}

interface Entity {
  accumulatedStats: Stats;
}

type StatType = 'completed' | 'overdue' | 'upcoming';

function sorterFunction(
  entityA: Entity,
  entityB: Entity,
  milestonesOrActivities: MilestoneOrActiveToggleEnum,
  statType: StatType
): number {
  if (milestonesOrActivities === MilestoneOrActiveToggleEnum.Milestones) {
    return (
      entityA.accumulatedStats.milestones[statType] -
      entityB.accumulatedStats.milestones[statType]
    );
  } else {
    return (
      entityA.accumulatedStats.activities[statType] -
      entityB.accumulatedStats.activities[statType]
    );
  }
}

export const InitiativeTable = ({
  initiatives,
  milestonesOrActivities,
}: Props) => {
  const { t } = useTranslation();
  const dataIndex =
    milestonesOrActivities === MilestoneOrActiveToggleEnum.Milestones
      ? 'milestones'
      : 'activities';
  const initiativeColumns: ColumnProps<InitiativeTable__TopInitiativeReportFragment>[] =
    [
      {
        title: t('common.status'),
        width: 120,
        key: 'status',
        dataIndex: ['initiative', 'metadata'],
        sorter: (a, b) =>
          stringSort(
            a.initiative.metadata.status,
            b.initiative.metadata.status
          ),
        render: (metadata) => {
          const statusToRender = match(metadata)
            .with({ status: DurationStatus.COMPLETED }, () => (
              <div className="bold txt--success">
                {t('common.milestone.status.completed')}
              </div>
            ))
            .with({ status: DurationStatus.UPCOMING }, () => (
              <div
                className="bold"
                style={{ color: Colors.Status.FUTURE_PURPLE }}
              >
                {t('common.milestone.status.upcoming')}
              </div>
            ))
            .otherwise(() => '');

          return <div className="text-c">{statusToRender}</div>;
        },
      },
      {
        title: t('InitiativeTable.tagColumn'),
        width: 120,
        key: 'status',
        dataIndex: ['initiative', 'tag'],
        render: () => {
          return (
            <InitiativeTag
              tag={{ title: 'test', colorCode: 'pink', iconId: 'carIcon' }}
              hideTitle
            />
          );
        },
      },
      {
        title: t('InitiativeTable.initiativeColumn'),
        key: 'name',
        sorter: (a, b) => stringSort(a.initiative.name, b.initiative.name),
        dataIndex: ['initiative', 'name'],
        render: (text) => {
          return (
            <div>
              <div>{text}</div>
            </div>
          );
        },
      },
      {
        title: <CheckIcon className="txt--success" style={{ fontSize: 15 }} />,
        key: 'completed',
        sorter: (a, b) =>
          sorterFunction(a, b, milestonesOrActivities, 'completed'),
        width: 90,
        dataIndex: ['accumulatedStats', dataIndex, 'completed'],
      },
      {
        title: (
          <WarningIcon
            style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
            className="space--r"
          />
        ),
        sorter: (a, b) =>
          sorterFunction(a, b, milestonesOrActivities, 'overdue'),
        key: 'overdue',
        dataIndex: ['accumulatedStats', dataIndex, 'overdue'],
        width: 90,
      },
      {
        title: (
          <UpcomingEventIcon
            style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
            className="space--r"
          />
        ),
        sorter: (a, b) =>
          sorterFunction(a, b, milestonesOrActivities, 'upcoming'),
        key: 'upcoming',
        width: 90,
        dataIndex: ['accumulatedStats', dataIndex, 'upcoming'],
      },
      {
        title: '',
        key: 'details',
        width: 90,
        render: (_, initiative) => (
          <Link to={`initiatives/${initiative.domainId.itemId}`}>
            <ManageSearchIcon
              style={{ fontSize: 20, color: Colors.Action.BLUE }}
            />
          </Link>
        ),
      },
    ];

  return (
    <Table
      columns={initiativeColumns}
      dataSource={initiatives}
      style={{ borderRadius: '6px' }}
      pagination={false}
      rowKey={(initiative) => initiative.id}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_TABLE__TOP_INITIATIVE_REPORT = gql`
  fragment initiativeTable__TopInitiativeReport on TopInitiativeReport2 {
    id
    domainId {
      itemId
      tenantId
    }
    initiative {
      domainId {
        itemId
        tenantId
      }
      name
      id
      tag {
        title
        iconId
        colorCode
      }
      metadata {
        completedAt
        archived
        status
      }
    }
    accumulatedStats {
      milestones {
        completed
        overdue
        upcoming
      }
      activities {
        completed
        overdue
        upcoming
      }
    }
  }
`;
