import { useTranslation } from 'react-i18next';
import { RenderedMarkdown } from '../../../../../../../../../components/RenderedMarkdown';
import { gql } from '@apollo/client';
import { WkaDetailsSection_WeeklyKeyActivityFragment } from '../../../../../../../../../generated/graphql';
import { Link } from 'react-router-dom';
import './WkaDetailsSection.less';
import { getColorForMigStatus } from './wkaDetailsSection/getColorForMigStatus';

interface Props {
  wka: WkaDetailsSection_WeeklyKeyActivityFragment;
}

export const WkaDetailsSection = ({ wka }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <RenderedMarkdown
        html={wka.descriptionHtml}
        emptyPlaceholder={
          <div className="txt--disabled">
            {t('WkaDetailsSection.noWkaDescription')}
          </div>
        }
      />
      <div className="mb--l mt--l">
        <h4 className="mb--s">
          {t('WkaDetailsSection.definitionOfDoneHeader')}
        </h4>
        <RenderedMarkdown
          html={wka.acceptanceCriteriaHtml}
          emptyPlaceholder={
            <div className="txt--disabled">
              {t('WkaDetailsSection.noWkaDefinitionOfDone')}
            </div>
          }
        />
      </div>

      {wka.supportsMigsDetails?.length > 0 && (
        <div className="mb--m">
          <h4>{t('common.supports')}</h4>
          {wka.supportsMigsDetails.map((mig, index) => (
            <div className="WkaDetailsSection__supports mt--s" key={index}>
              <Link
                className="space--r"
                to={`/team/${mig.team2.domainId.itemId}/overview`}
                target={'_blank'}
              >
                {mig.team2.name}
              </Link>
              <span className="txt--secondary">
                {t('WkaDetailsSection.supportHeader')}
              </span>

              <ul className="pl">
                <li>
                  <span style={{ color: getColorForMigStatus(mig) }}>
                    {t(`common.mig.status.${mig.status.toLowerCase()}`)}
                  </span>
                  {' - ' + mig.name}
                </li>
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WKA_DETAILS_SECTION_FRAGMENT = gql`
  fragment WkaDetailsSection_WeeklyKeyActivity on WeeklyKeyActivity {
    id
    descriptionHtml
    acceptanceCriteriaHtml
    supportsMigsDetails {
      id
      name
      status
      team2 {
        id
        domainId {
          itemId
        }
        name
      }
    }
  }
`;
