import { gql, useLazyQuery } from '@apollo/client';
import { CollapseCard } from '../overview/components/collapseCard/CollapseCard';

import { useEffect, useState } from 'react';
import {
  GetInitiativeForSectionCardDocument,
  InitiativeSectionCard_HeaderContentFragment,
  InitiativeSectionCard_MilestoneFragment,
} from '../../../generated/graphql';
import { InitiativeIcon } from '../../../components/initiative/InitiativeIcon';
import { Tabs, TabsProps } from 'antd';
import { MilestonePlaner } from './MilestonePlaner';
import { DisplayDate } from '../../../components/DisplayDate';
import { InitiativeStatus } from '../../../components/initiative/InitiativeStatus';
import { KeyActivityStatusIcon } from '../../../components/KeyActivityStatusIcon';

interface Props {
  teamId: string;
  initiative: InitiativeSectionCard_HeaderContentFragment;
  milestones?: InitiativeSectionCard_MilestoneFragment[];
  className?: string;
}

export const InitiativeSectionCard = ({
  teamId,
  initiative,
  milestones,
  className,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const numberOfCompletedMilestones = milestones?.filter(
    (m) => m.metadata.status === 'COMPLETED'
  ).length;
  const numberOfUpcomingMilestones = milestones?.filter(
    (m) => m.metadata.status === 'UPCOMING'
  ).length;
  const numberOfOverdueMilestones = milestones?.filter(
    (m) => m.metadata.status === 'OVERDUE'
  ).length;

  const [fetchInitiative, { data }] = useLazyQuery(
    GetInitiativeForSectionCardDocument,
    {
      variables: { initiativeId: initiative.domainId.itemId },
    }
  );

  useEffect(() => {
    if (isExpanded) {
      fetchInitiative();
    }
  }, [isExpanded, fetchInitiative]);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Milestone execution plan',
      children: (
        <MilestonePlaner initiative={data?.initiative} teamId={teamId} />
      ),
    },
    {
      key: '2',
      label: 'Directly Connected activities',
      children: <div style={{ minHeight: 300 }}>Content of Tab Pane 2</div>,
    },
    {
      key: '3',
      label: 'Initiative Details',
      children: 'Content of Tab Pane 3',
    },
  ];
  return (
    <CollapseCard
      className={className}
      headerContent={
        <div>
          <h3>{initiative.name}</h3>
          <div className="flx flx--ai-center font-size--sm">
            <span className="font-size--lg">
              <InitiativeIcon iconId={initiative.tag.iconId} />
            </span>
            <div className="ml--s mr--xl bold">{initiative.name}</div>
            <div className="mr--xl">
              <span className="txt--secondary mr--s">Status:</span>
              <span className="bold">
                <InitiativeStatus
                  status={initiative.metadata.status}
                  archived={initiative.metadata.archived}
                />
              </span>
            </div>
            <div>
              <span className="txt--secondary mr--s">End date:</span>
              <DisplayDate date={initiative.endAt} className="bold" />
            </div>
            <div className="ml--xl">
              <span className="txt--secondary mr--s">Tracked milestones:</span>
              <span className="mr">
                <KeyActivityStatusIcon
                  status={'COMPLETED'}
                  className="mr--xs"
                />
                {numberOfCompletedMilestones}
              </span>
              <span className="mr">
                <KeyActivityStatusIcon status={'OVERDUE'} className="mr--xs" />{' '}
                {numberOfOverdueMilestones}
              </span>
              <span className="mr">
                <KeyActivityStatusIcon status={'UPCOMING'} className="mr--xs" />
                {numberOfUpcomingMilestones}
              </span>
            </div>
          </div>
        </div>
      }
      onClick={() => setIsExpanded(!isExpanded)}
      expanded={isExpanded}
    >
      <Tabs className="fullWidth pa" defaultActiveKey="1" items={items} />
    </CollapseCard>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_INITIATIVE_FOR_SECTION_CARD = gql`
  query GetInitiativeForSectionCard($initiativeId: ID!) {
    initiative(initiativeId: $initiativeId) {
      id
      domainId {
        itemId
      }
      ...MilestonePlaner_Initiative
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_SECTION_CARD_MILESTONE = gql`
  fragment InitiativeSectionCard_Milestone on MilestoneWithLinks {
    id
    domainId {
      itemId
    }
    metadata {
      status
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_SECTION_CARD_InitiativeWithLinksFragment = gql`
  fragment InitiativeSectionCard_HeaderContent on InitiativeWithLinks {
    id
    domainId {
      itemId
    }
    name
    metadata {
      status
      archived
    }
    endAt
    tag {
      colorCode
      iconId
    }
  }
`;
