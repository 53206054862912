/**
 * Colors in this file should always be kept in-sync with corresponding
 * less variables in Colors.less.
 *
 * Note: when possible write your own css classes and use the less variables instead
 * */

import { MilestoneStatus } from '../../generated/graphql';

const BaseColors = {
  CHARCOAL: '#303030',
  IRON: '#868686',
  TITANIUM: '#bdbdbd',
  SILVER: '#e0e0e0',
  ALUMINUM: '#ebebeb',
  PLATINUM: '#f5f5f5',
};

export const Colors = {
  Howwe: {
    MAIN_ORANGE: '#FF6309',
    MAIN_BLUE: '#092D47',
  },
  Status: {
    OK_GREEN: '#439D19',
    FUTURE_PURPLE: '#9427CA',
    ERROR_RED: '#B00000',
    WARNING_ORANGE: '#FF6309',
    OVERDUE: '#8C3D10',
    NEW_OVERDUE: '#FF6309',
  },
  Action: {
    BLUE: '#197DE1',
    HOVER_BLUE: '#1771CA',
    SELECTED_BLUE: '#0062A9',
    LIGHT_ACTIVE: '#D1E5F9',
    HOVER_WHITE_TO_BLUE: '#E8F2FC',
    CTA_BUTTON_HOVER: '#E65908',
    DISABLED_FONT: '#BDBDBD',
  },
  Grays: {
    MAIN_FONT: BaseColors.CHARCOAL,
    SECONDARY_FONT: BaseColors.IRON,
    DISABLED_FONTS: BaseColors.TITANIUM,
    FRAMES_AND_LINES: BaseColors.SILVER,
    DISABLED_BG: BaseColors.ALUMINUM,
    DISABLED_LINES: BaseColors.ALUMINUM,
    BACKGROUND: BaseColors.PLATINUM,
    WHITE: '#FFF',
  },
  Lights: {
    WARNING: '#FFF1CE',
    ERROR: '#EBBFBF',
    OK: '#D9EBD1',
    FUTURE: '#EAD4F4',
    ACTIVE: '#D1E5F9',
    LIGHT_WARNING: '#FFF2C3',
  },
};

export const milestoneStatusColor: Record<MilestoneStatus, string> = {
  COMPLETED: Colors.Status.OK_GREEN,
  OVERDUE: Colors.Status.OVERDUE,
  UPCOMING: Colors.Status.FUTURE_PURPLE,
};
