import dayjs from 'dayjs';
import { MonthlyMilestones } from './MonthlyMilestones';
import { SelectedMilestone } from './SelectedMilestone';
import { gql } from '@apollo/client';
import { MilestoneSidebar_MilestoneWithLinksFragment } from '../../../../generated/graphql';

interface Props {
  milestonesLoading: boolean;
  selectedMilestone?: MilestoneSidebar_MilestoneWithLinksFragment | null;
  teamId: string;
  milestonesByMonth: [string, MilestoneSidebar_MilestoneWithLinksFragment[]][];
  onSelectMilestone: (milestoneId: string | null) => void;
}

export const MilestoneSidebar = ({
  milestonesLoading,
  selectedMilestone,
  teamId,
  milestonesByMonth,
  onSelectMilestone,
}: Props) => {
  if (milestonesLoading) {
    return (
      <>
        <h3 className="mb">Milestones</h3>
        <MonthlyMilestones.Skeleton />
      </>
    );
  }

  if (selectedMilestone) {
    return (
      <>
        <h3 className="mb">Selected Milestone</h3>
        <SelectedMilestone
          selectedMilestone={selectedMilestone}
          teamId={teamId}
          onClear={() => onSelectMilestone(null)}
        />
      </>
    );
  }

  return (
    <>
      <h3 className="mb">Milestones</h3>
      {milestonesByMonth.map(([monthKey, milestones]) => (
        <MonthlyMilestones
          monthTitle={dayjs(monthKey)}
          teamId={teamId}
          key={monthKey}
          milestones={milestones}
          onMilestoneSelect={onSelectMilestone}
        />
      ))}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MILESTONEs = gql`
  fragment MilestoneSidebar_MilestoneWithLinks on MilestoneWithLinks {
    id
    ...MonthlyMilestones_MilestoneWithLinksForTeam
    ...SelectedMilestone_MilestoneWithLinks
  }
`;
