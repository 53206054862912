import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '../Auth0Provider';
import { envConf } from '../envConf';
import { TeamMemberRoles } from '../generated/graphql';
import { useMeLazy } from './useMe';

export function useIntercom() {
  const { isAuthenticated } = useAuth0();
  const [getUser, { data }] = useMeLazy();
  const { INTERCOM_KEY } = envConf;

  useEffect(() => {
    if (isAuthenticated) {
      getUser();
    }
  }, [getUser, isAuthenticated]);

  useEffect(() => {
    if (!data || !INTERCOM_KEY) return;

    const dateInSeconds = Math.floor(Date.now() / 1000);

    const accDays = data.currentUser.memberOf.map(
      (ta) => ta.team.data.accelerationDay
    );

    (window as any).Intercom('boot', {
      api_base: 'https://api-iam.eu.intercom.io',
      app_id: INTERCOM_KEY,
      user_id: data.currentUser.domainId.itemId,
      name: data.currentUser.name, // Full name
      email: data.currentUser.email, // Email address
      created_at: data.currentUser.metadata.signedUpAt,
      company: {
        company_id: data.currentUser.domainId.tenantId,
      },
      isLeader: data.currentUser.memberOf.some((ta) =>
        ta.roles.some((r) => r === TeamMemberRoles.TEAM_LEADER)
      ),
      language_override: data.currentUser.locale,
      last_seen_on_main_app_at: dateInSeconds,
      signed_up_at: data.currentUser.metadata.signedUpAt,
      teamIds: data.currentUser.memberOf
        .map((ta) => ta.team.domainId.itemId)
        .join(','),
      accelerationDays: accDays
        .filter((item, index) => accDays.indexOf(item) === index)
        .join(','),
    });
  }, [data, INTERCOM_KEY]);

  const location = useLocation();
  useEffect(() => {
    if (!data || !INTERCOM_KEY) return; // if there's no data then we haven't booted yet
    (window as any).Intercom('update');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
}
