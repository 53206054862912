import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUnique } from '../../../../../../services/getUnique';
import './MitemFilter.less';

type Owner = {
  id: string;
  domainId: {
    itemId: string;
  };
  email: string;
  name: string | null;
  displayName: string | null;
};
interface Props {
  owners: Owner[];
  value: string;
  onChange: (ownerId: string) => void;
}
/** @deprecated: kill when sprints are killed */
export const OwnerFilter = ({ owners, value, onChange }: Props) => {
  const [filteredOwners, setFilteredOwners] = useState<Owner[] | null>(null);
  const { t } = useTranslation();
  const { Option } = Select;

  useEffect(() => {
    const uniqueOwners = getUnique(owners);
    setFilteredOwners(uniqueOwners);
  }, [owners]);

  return (
    <div
      className="MitemFilters__filterContainer mr--s"
      data-intercom-target="Sprint View Table Owner Filter"
    >
      <h5>{t('common.owner')}</h5>
      <Select
        defaultValue={value}
        onChange={onChange}
        className="MitemFilter__Select"
      >
        <Option value="ALL" className="MitemFilters__divider">
          {t('OwnerFilter.AllOwners')}
        </Option>
        {filteredOwners?.map((o) => (
          <Option key={o.domainId.itemId} value={o.domainId.itemId}>
            {o.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};
