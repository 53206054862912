import { useModal } from '../../../../../../../../../ModalProvider';
import { useMemo } from 'react';
import { gql, useSuspenseQuery } from '@apollo/client';
import { ActivityTable } from '../../../../../../../activities/listPage/ActivityTable/ActivityTable';
import { Btn } from '../../../../../../../../../components/Button';
import {
  GetActivitiesForOwnerBasedCommitmentStepDocument,
  GetOverdueActivitiesForOwnerBasedCommitmentStepDocument, // Import the new query document
  OwnerBasedCommitments_MitemFragment,
} from '../../../../../../../../../generated/graphql';
import { UserAvatar } from '../../../../../../../../../components/UserAvatar';
import { DisplayName } from '../../../../../../../../../components/DisplayName';
import dayjs from 'dayjs';
import { friendlyUsername } from '../../../../../../../../../services/friendlyUsername';

// Define the type for the owner based on the generated fragment
// Ensure OwnerBasedCommitments_MitemFragment['owner2'] is not null/undefined where used
type OwnerInfo = NonNullable<OwnerBasedCommitments_MitemFragment['owner2']>;
// Use the generated fragment type for activities
type Activity = OwnerBasedCommitments_MitemFragment;

// Define the type for grouped activities using the generated types
type GroupedActivities = Map<
  string,
  { owner: OwnerInfo; activities: Activity[] }
>;

interface Props {
  teamId: string;
}

export const OwnerBasedCommitments = ({ teamId }: Props) => {
  const { openModal } = useModal();

  // Fetch upcoming/current activities
  const { data: currentData, error: currentError } = useSuspenseQuery(
    GetActivitiesForOwnerBasedCommitmentStepDocument,
    {
      variables: {
        teamId: teamId,
        archived: false,
        startDate: dayjs().format('YYYY-MM-DD'), // Fetch activities starting from today
      },
    }
  );

  // Fetch overdue activities
  const { data: overdueData, error: overdueError } = useSuspenseQuery(
    GetOverdueActivitiesForOwnerBasedCommitmentStepDocument,
    {
      variables: {
        teamId: teamId,
        endDate: dayjs().format('YYYY-MM-DD'), // Use startDate if needed
        // Remove startDate and endDate if the query doesn't need them or should fetch all
      },
    }
  );

  const groupedActivities = useMemo(() => {
    const currentActivities =
      currentData?.sprintKeyActivities.sprintKeyActivities ?? [];
    const overdueActivities =
      overdueData?.sprintKeyActivities.sprintKeyActivities ?? [];

    // Combine and deduplicate activities based on ID
    const combinedActivitiesMap = new Map<string, Activity>();
    [...overdueActivities, ...currentActivities].forEach((activity) => {
      if (activity && !activity.completed) {
        // Ensure activity is not completed
        combinedActivitiesMap.set(activity.id, activity);
      }
    });

    const allValidActivities = Array.from(combinedActivitiesMap.values());

    return groupActivitiesByOwner(allValidActivities);
  }, [currentData, overdueData]);

  // Handle error states (Suspense handles loading, ErrorBoundary should catch errors)
  // We check errors primarily for logging or specific UI feedback if needed beyond ErrorBoundary
  if (currentError || overdueError) {
    console.error('Error loading activities:', currentError || overdueError);
    // Optionally return an error component, though ErrorBoundary is preferred
    return (
      <div>
        Error loading activities:{' '}
        {currentError?.message || overdueError?.message}
      </div>
    ); // Replace with ErrorAlert component
  }

  return (
    <div className="mt--xl flx">
      <div className="flx--1">
        <h3>Activities by Owner</h3>

        {/* Render tables per owner */}
        {Array.from(groupedActivities.entries()).map(
          ([ownerId, { owner, activities }]) => (
            <ActivityTable
              teamId={teamId}
              committable={true}
              key={ownerId}
              className="mt--xl"
              // Cast activities to the type expected by ActivityTable if necessary,
              // but OwnerBasedCommitments_MitemFragment includes ActivityTable_MitemFragment
              activities={activities}
              header={
                <div className="flx flx--center-y mb--s">
                  <UserAvatar user={owner} size="small" />
                  <h4 className="m--0 ml--s">
                    <DisplayName user={owner} />
                  </h4>
                  <Btn
                    size="small"
                    type="link"
                    className="ml--auto"
                    onClick={() =>
                      openModal({
                        type: 'createActivity',
                        teamId,
                        // Correctly structure prefilledValues
                        formType: {
                          type: 'simple',
                          prefilledValues: { ownerId: owner.domainId.itemId },
                        },
                      })
                    }
                  >
                    + Add Activity
                  </Btn>
                </div>
              }
            />
          )
        )}
      </div>
    </div>
  );
};

// Helper function to group activities by owner
const groupActivitiesByOwner = (activities: Activity[]): GroupedActivities => {
  const grouped = new Map<
    string,
    { owner: OwnerInfo; activities: Activity[] } // Use Activity type here
  >();
  activities.forEach((activity) => {
    const owner = activity.owner2;
    if (!grouped.has(owner.id)) {
      grouped.set(owner.id, { owner, activities: [] });
    }
    grouped.get(owner.id)?.activities.push(activity);
  });
  // Sort owners alphabetically by displayName or name
  return new Map(
    [...grouped.entries()].sort(([_keyA, a], [_keyB, b]) => {
      const nameA = friendlyUsername(a.owner);
      const nameB = friendlyUsername(b.owner);
      return nameA.localeCompare(nameB);
    })
  );
};

// Define the GraphQL Fragment for data needed by this component and ActivityTable
// Rename fragment to avoid conflicts and reflect its usage
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_LIST_OWNER_MITEM = gql`
  fragment OwnerBasedCommitments_Mitem on Mitem {
    id
    name
    deadline
    completed
    status # Ensure status is fetched if needed by ActivityTable or logic
    owner2 {
      id
      name
      email
      initials
      displayName
      domainId {
        itemId
      }
    }
    # Include the fragment needed by ActivityTable
    ...ActivityTable_Mitem
  }
`;

// Define the GraphQL Query for Overdue Activities
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_OVERDUE_ACTIVITIES_FOR_OWNER_COMMITMENT = gql`
  query GetOverdueActivitiesForOwnerBasedCommitmentStep(
    $teamId: ID!
    $startDate: String # Optional: May not be needed for overdue
    $endDate: String # Optional: May not be needed for overdue
  ) {
    sprintKeyActivities(
      teamId: $teamId
      overdue: true
      startDate: $startDate # Pass if required by backend logic for overdue
      endDate: $endDate # Pass if required by backend logic for overdue
    ) {
      sprintKeyActivities {
        id
        # Use the same fragment as the main query
        ...OwnerBasedCommitments_Mitem
      }
    }
  }
`;

// Define the GraphQL Query for Upcoming/Current Activities
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_ACTIVITIES_FOR_OWNER_COMMITMENT = gql`
  query GetActivitiesForOwnerBasedCommitmentStep(
    $teamId: ID!
    $archived: Boolean
    $startDate: String
  ) {
    sprintKeyActivities(
      teamId: $teamId
      archived: $archived
      startDate: $startDate
    ) {
      sprintKeyActivities {
        id
        # Use the new fragment
        ...OwnerBasedCommitments_Mitem
      }
    }
  }
`;
