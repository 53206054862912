import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../components/Button';
import { AddOrUpdateTagModal } from './AddOrUpdateTagModal';

interface Props {
  teamId: string;
  onOpen?: () => void;
  onClose?: () => void;
}

export enum modalType {
  createTag,
  modifyTag,
}

export const AddLabelModal = ({ teamId, onOpen, onClose }: Props) => {
  const [openAddLabelModal, setOpenAddLabelModal] = useState(false);
  const { t } = useTranslation();
  const closeAddLabelModal = () => {
    onClose?.();
    setOpenAddLabelModal(false);
  };
  return (
    <>
      <Btn
        type="link"
        onClick={() => {
          onOpen?.();
          setOpenAddLabelModal(!openAddLabelModal);
        }}
      >
        {t('Tags.addTag')}
      </Btn>
      <AddOrUpdateTagModal
        teamId={teamId}
        visible={openAddLabelModal}
        onCancel={closeAddLabelModal}
        onCompleted={closeAddLabelModal}
        title={t('Tags.createTag')}
        modalActionType={modalType.createTag}
      />
    </>
  );
};
