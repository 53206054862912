import 'react-app-polyfill/stable';
import 'array.prototype.tosorted/auto';
import '@ant-design/v5-patch-for-react-19';
import * as Sentry from '@sentry/react';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Auth0Provider } from './Auth0Provider';
import { envConf } from './envConf';
import './index.less';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import 'moment/locale/sv'; // important!
import 'moment/locale/en-gb'; // important!
import { AuthorizedApolloProvider } from './AuthorizedApolloProvider';
import { TenantOverrideProvider } from './TenantOverrideProvider';
import 'react-loading-skeleton/dist/skeleton.css';
import { Spin } from 'antd';
import './dayjsConf';
import { ModalProvider } from './ModalProvider';

const history = createBrowserHistory();

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: 'https://d190b9ea2730460dbb58727bfb0b0bfb@sentry.io/1549303',
    release: envConf.COMMIT_SHA,
    environment: envConf.APP_ENV,
    normalizeDepth: 8,
    tracePropagationTargets: [envConf.API_BASE_URL.replace('https://', '')],
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        beforeStartSpan: (context) => {
          return {
            ...context,
            name: history.location.pathname.replace(
              /\/[a-f0-9-]{36}/g,
              '/<id>'
            ),
          };
        },
        shouldCreateSpanForRequest: (url) => {
          // Do not create spans for outgoing requests to a `/version/` endpoint
          return !url.match(/\/version\/?$/);
        },
      }),
    ],
    tracesSampleRate: 1.0,
    beforeSend(event) {
      const tenantId = event.tags?.TenantId as string;
      const requestUrl = event.request?.url;

      if (tenantId && requestUrl && event.contexts?.['Useful Urls']) {
        event.contexts['Useful Urls']['shareUrl'] =
          requestUrl + `?tenantId=${tenantId}`;
      }

      return event;
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'fetch' && breadcrumb.data?.url) {
        const url: string = breadcrumb.data?.url ?? '';

        if (url.endsWith('/graphql')) {
          try {
            const query = hint?.input[1];
            const queryBody = JSON.parse(query.body);
            breadcrumb.data = queryBody;
          } catch {
            // do nothing if we fail to retrieve queryBody and pass along the breadcrumb as is
          }
        }
      }

      return breadcrumb;
    },
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
  if (appState.targetUrl) {
    history.replace(appState.targetUrl);
  }
};
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Suspense
    fallback={
      <div className="center-content">
        <Spin size="large" delay={300} />
      </div>
    }
  >
    <Auth0Provider onRedirectCallback={onRedirectCallback}>
      <TenantOverrideProvider>
        <AuthorizedApolloProvider>
          <ModalProvider>
            <Router history={history}>
              <I18nextProvider i18n={i18n}>
                <App />
              </I18nextProvider>
            </Router>
          </ModalProvider>
        </AuthorizedApolloProvider>
      </TenantOverrideProvider>
    </Auth0Provider>
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
