import { gql, useQuery } from '@apollo/client';
import { MigCompactGraph } from '../../../../../../../components/graphs/MigCompactGraph';
import { Divider, Empty, Spin, Typography } from 'antd';
import cx from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ErrorAlert } from '../../../../../../../components/ErrorAlert';
import { NoData } from '../../../../../../../components/NoData';
import {
  GetMigDrawerDataDocument,
  TeamMigFragment,
} from '../../../../../../../generated/graphql';
import { useMigRelatedSprintKeyActivitiesLazy } from '../../../../../../../hooks/useMigRelatedSprintKeyActivities';
import { useMigRelatedWeeklyKeyActivitiesLazy } from '../../../../../../../hooks/useMigRelatedWeeklyActivities';
import { showNotification } from '../../../../../../../services/fetchNotificationProperties';
import { isDefined } from '../../../../../../../services/isDefined';
import { lineColor } from '../../../../../../../styleVars';
import './MigDrawerCard.less';
import { DetailsTabFooter } from './migDrawerCard/DetailsTabFooter';
import { SprintKeyActivityCard } from './migDrawerCard/SprintKeyActivityCard';
import { WeeklyKeyActivityCard } from './migDrawerCard/WeeklyActivityCard';

interface Props {
  mig: TeamMigFragment;
  activeDot?: number | null;
  className?: string;
  graphHeight?: number;
  showDescription?: boolean;
}

export const MigDrawerCard = ({
  mig,
  activeDot,
  className,
  graphHeight = 180,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useQuery(GetMigDrawerDataDocument, {
    variables:
      isDefined(mig.domainId.itemId) && isDefined(mig.team2?.domainId.itemId)
        ? {
            migId: mig.domainId.itemId,
            teamId: mig.team2.domainId.itemId,
          }
        : undefined,
    onError: () => {
      showNotification('error', {
        message: t('MigDrawerCard.fetchError'),
      });
    },
  });

  const [
    fetchRelatedSprintKeyActivities,
    {
      data: relatedSprintKeyActivitiesData,
      loading: relatedSprintKeyActivitiesLoading,
      error: relatedSprintKeyActivitiesError,
    },
  ] = useMigRelatedSprintKeyActivitiesLazy();

  const [
    fetchRelatedWeeklyKeyActivities,
    {
      data: relatedWeeklyKeyActivitiesData,
      loading: relatedWeeklyKeyActivitiesLoading,
      error: relatedWeeklyKeyActivitiesError,
    },
  ] = useMigRelatedWeeklyKeyActivitiesLazy();

  useEffect(() => {
    if (
      isDefined(mig.team2.domainId.itemId) &&
      isDefined(mig.domainId.itemId)
    ) {
      fetchRelatedSprintKeyActivities({
        variables: {
          teamId: mig.team2.domainId.itemId,
          migId: mig.domainId.itemId,
        },
      });
      fetchRelatedWeeklyKeyActivities({
        variables: {
          teamId: mig.team2.domainId.itemId,
          migId: mig.domainId.itemId,
        },
      });
    }
  }, [fetchRelatedSprintKeyActivities, fetchRelatedWeeklyKeyActivities, mig]);

  const hasRelatedSprintKeyActivities =
    !relatedSprintKeyActivitiesLoading && relatedSprintKeyActivitiesData;

  const hasRelatedWeeklyKeyActivities =
    !relatedWeeklyKeyActivitiesLoading &&
    relatedWeeklyKeyActivitiesData?.keyActivities &&
    relatedWeeklyKeyActivitiesData.keyActivities.length > 0;

  return (
    <div className={cx('MigDrawerCard', className)}>
      <div className="DetailsTabContent__tableRow">
        <div className="DetailsTabContent__tableTitle">
          {t('MigDrawerCard.team')}
        </div>
        <div className="DetailsTabContent__tableData">
          <Link to={`/team/${mig.team2.domainId.itemId}`}>
            {data?.teamMig.team2.name}
          </Link>
        </div>
      </div>
      <div className="DetailsTabContent__tableRow">
        <div className="DetailsTabContent__tableTitle">
          {t('MigDrawerCard.title')}
        </div>
        <div className="DetailsTabContent__tableData">
          <b>{mig.name}</b>
        </div>
      </div>
      <div className="DetailsTabContent__tableRow">
        <div className="DetailsTabContent__tableTitle">
          {t('common.description')}
        </div>
        <div className="DetailsTabContent__tableData">
          {mig.description ? (
            <span>{mig.description}</span>
          ) : (
            <NoData text={t('MigDrawerCard.noDescription')} />
          )}
        </div>
      </div>
      <div className="DetailsTabContent__tableRow DetailsTabContent__tableRow--graph">
        <div className="DetailsTabContent__tableTitle">
          {t('MigDrawerCard.performance')}
        </div>
        <div className="DetailsTabContent__tableData mt--xl">
          <MigCompactGraph
            height={graphHeight}
            lineData={mig.periodData.map((pd) => ({
              ...pd,
              label: pd.targetDate,
            }))}
            minY={mig.minY}
            maxY={mig.maxY}
            lineConf={[
              {
                dataKey: 'historic',
                stroke: lineColor.historic,
                strokeWidth: '1',
                name: t('common.historic') as string,
              },
              {
                dataKey: 'goal',
                stroke: lineColor.goal,
                name: t('common.goal') as string,
              },
              {
                dataKey: 'actual',
                stroke: lineColor.actual,
                name: t('common.actual') as string,
              },
            ]}
            referenceLine={mig.mostRecentReport.targetDate}
            activeDot={activeDot}
          />
        </div>
      </div>
      <DetailsTabFooter
        targetDate={mig.mostRecentReport.targetDate}
        targetValue={mig.mostRecentReport.goal}
        actualValue={mig.mostRecentReport.actual}
        targetFulfillment={mig.mostRecentReport.goalFulfillment}
        mig={mig}
      />
      <Divider />
      <Typography.Title level={4}>
        {t('MigDrawerCard.relatedKeyActivities')}
        <span className="BetaBadge space--l">Beta</span>
      </Typography.Title>
      <div className="flx flx--wrap">
        {relatedSprintKeyActivitiesLoading && <Spin />}
        {hasRelatedSprintKeyActivities && (
          <SprintKeyActivityCard cardData={relatedSprintKeyActivitiesData} />
        )}
        {relatedWeeklyKeyActivitiesLoading && <Spin />}
        {hasRelatedWeeklyKeyActivities &&
          relatedWeeklyKeyActivitiesData.keyActivities.map((activity, i) => (
            <WeeklyKeyActivityCard
              key={i}
              name={activity.name}
              id={activity.id}
              teamId={activity.teamId}
            />
          ))}

        {!hasRelatedSprintKeyActivities && !hasRelatedWeeklyKeyActivities && (
          <div className="center-content flx--1">
            <Empty
              description={t('MigDrawerCard.noData')}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </div>
      <ErrorAlert
        error={
          relatedSprintKeyActivitiesError || relatedWeeklyKeyActivitiesError
        }
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MIG_DRAWER = gql`
  query getMigDrawerData($teamId: ID!, $migId: ID!) {
    teamMig(teamId: $teamId, migId: $migId) {
      id
      status
      team2 {
        id
        name
        domainId {
          itemId
        }
      }
      ...DetailsTabFooterMig
    }
  }
`;
