import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LearnMoreLink } from '../../../../components/LearnMoreLink';
import { useSprintPlanningData } from '../../common/hooks/useSprintPlanningData';
import { CreateMitemModal } from '../common/hooks/useCreateMitemModal';
import {
  migFilterCompare,
  milestoneFilterCompare,
  ownerFilterCompare,
  statusFilterCompare,
  useMitemFilters,
} from '../common/hooks/useMitemFilters';
import './SprintPlanningPage.less';
import { CreateMitemDrawer } from './components/CreateMitemDrawer';
import { PlanViewFilterHeader } from './components/PlanViewFilterHeader';
import { Btn } from '../../../../components/Button';
import { PermissionChecker } from '../../../../PermissionChecker';
import { useEffect } from 'react';
import { standardDateFormat } from '../../../../services/dateFormats';
import { useAiDemoFeatureFlag } from '../../../../hooks/featureFlagHooks';
import { AiSuggestDemoButton as AiDemoButton } from '../common/components/aiSuggest-demo/AiSuggestButton';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  Action,
  SprintManagerMilestoneQueryDocument,
  SprintManagerQueryDocument,
} from '../../../../generated/graphql';
import { stringSort } from '../../../../services/stringSort';
import { MitemPlanningBoard } from './components/MitemPlanningBoard';
import { useTenantDetails } from '../../../../hooks/useTenantDetails';

interface Props {
  teamId: string;
  createMitemModal: CreateMitemModal;
}

export const SprintPlanningPage = ({ teamId, createMitemModal }: Props) => {
  const { t } = useTranslation();
  const { features, loading } = useTenantDetails();
  const aiDemoEnabled = useAiDemoFeatureFlag();

  const { data: sprintData } = useSprintPlanningData(teamId);

  const [fetchSkaLazy, { data }] = useLazyQuery(SprintManagerQueryDocument);
  const { data: milestoneData } = useQuery(
    SprintManagerMilestoneQueryDocument,
    { variables: { teamId } }
  );

  const sprintKeyActivities = [
    ...(data?.sprintKeyActivities.sprintKeyActivities ?? []),
  ].toSorted((a, b) => stringSort(a.deadline, b.deadline));

  const startDate = sprintData?.startDate;
  const endDate = sprintData?.endDate;

  useEffect(() => {
    if (startDate && endDate && !loading) {
      fetchSkaLazy({
        variables: {
          teamId,
          startDate: standardDateFormat(startDate),
          endDate: standardDateFormat(endDate),
          archived: false,
        },
      });
    }
  }, [startDate, endDate, fetchSkaLazy, teamId, features, loading]);

  const { currentFilters, setFilter } = useMitemFilters();

  const filteredMitems = sprintKeyActivities
    ?.filter((m) =>
      ownerFilterCompare(m.owner2.domainId.itemId, currentFilters?.ownerId)
    )
    .filter((m) => statusFilterCompare(m.status, currentFilters?.status))
    .filter((m) =>
      milestoneFilterCompare(
        m.supportsMilestoneLinks,
        currentFilters?.milestoneId
      )
    )
    .filter((m) => {
      return migFilterCompare(
        m.supportedMigs?.map((mig) => mig.domainId.itemId) ?? [],
        currentFilters?.migId
      );
    });

  const filteredMilestone = milestoneData?.milestonesWithLinksForTeam.milestones
    .filter((m) => {
      return milestoneFilterCompare(
        [{ id: m.id }],
        currentFilters?.milestoneId
      );
    })
    .filter((m) => m.metadata.archived == false);

  return (
    <div className="mb--xxl">
      <Helmet title={t('SprintPlanningPage.planView')} />

      <div className="flx flx--ai-center mb--xl mt--l">
        <LearnMoreLink urlTemplate="https://help.howwe.io/{{locale}}/articles/28919-key-activities-in-sprint" />
      </div>
      <div className="flx flx--ai-center flx--jc-space-between mb--l">
        <PlanViewFilterHeader
          currentFilters={currentFilters}
          setFilter={setFilter}
          mitems={sprintKeyActivities}
          teamId={teamId}
        />
        <PermissionChecker
          resourceOwner={{
            type: 'TeamResource',
            teamId: teamId,
            requestedAction: {
              resource: 'sprintKA',
              action: Action.CREATE,
            },
          }}
        >
          <Btn
            className="SprintPlanningPage__create-mitem"
            type="primary"
            onClick={() => {
              createMitemModal.openModal();
            }}
            data-intercom-target="Plan View Create Sprint Key Activity Button"
          >
            {t('SprintPlanningPage.createMItem')}
          </Btn>
          {aiDemoEnabled && <AiDemoButton teamId={teamId} />}
        </PermissionChecker>
      </div>
      {sprintData?.currentSprintAndOnward && sprintData?.sprintPeriodMap && (
        <MitemPlanningBoard
          mitems={filteredMitems}
          sprints={sprintData.currentSprintAndOnward}
          milestones={filteredMilestone ?? []}
          sprintPeriodMap={sprintData.sprintPeriodMap}
          teamId={teamId}
          onOpenCreateForm={createMitemModal.openModal}
        />
      )}
      <CreateMitemDrawer
        teamId={teamId}
        showModal={createMitemModal.isOpen}
        onCancel={createMitemModal.closeModal}
        prefilledValues={createMitemModal.prefilledValues}
      />
    </div>
  );
};

export const MIG_ASSOCIATION_OTHER = 'MIG_ASSOCIATION_OTHER';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_MANAGER_QUERY = gql`
  query sprintManagerQuery(
    $teamId: ID!
    $startDate: String
    $endDate: String
    $archived: Boolean
  ) {
    sprintKeyActivities(
      teamId: $teamId
      archived: $archived
      startDate: $startDate
      endDate: $endDate
    ) {
      sprintKeyActivities {
        id
        status
        deadline
        supportsMilestoneLinks {
          id
        }
        ...PlanViewFilterHeader_Mitem
        ...MitemPlaningBoard_Mitem
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_MANAGER_MILESTONES = gql`
  query sprintManagerMilestoneQuery($teamId: ID!) {
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        metadata {
          archived
        }
        ...MitemPlaningBoard_Milestone
      }
    }
  }
`;
