import React from 'react';
import { TreeLink } from './components/TreeLink';
import { ToggleableNode } from '../Tree';
import { CaretDownFilled, DownOutlined, UpOutlined } from '@ant-design/icons';
import './RecursiveTreeNode.less';

interface Props {
  node: ToggleableNode<Team>;
  onToggle: (
    node: ToggleableNode<Team>,
    e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => void;
  onClick?: (data: Team) => void;
  component: React.ReactNode;
  width: number;
  height: number;
  modifier: ModifierKeys;
}

interface ModifierKeys {
  altKey: boolean;
  ctrlKey: boolean;
  shiftKey: boolean;
  metaKey: boolean;
}

interface Team {
  id: string;
  domainId: {
    itemId: string;
  };
  name: string;
  component: React.ReactNode;
}

export const RecursiveTreeNode = (props: Props) => {
  const { node, onToggle, onClick, width, height, modifier } = props;
  const children = node.children || node._children;
  const nodeLayout = {
    transform: `translate(${node.x - width / 2}px,${node.y - height}px)`,
    width: `${width}px`,
    height: `${height}px`,
  };

  return (
    <div className="RecursiveTreeNode">
      {node.parent && (
        <TreeLink
          source={node.parent}
          target={{ x: node.x, y: node.y }}
          nodeHeight={height}
        />
      )}
      <div style={nodeLayout} className="RecursiveTreeNode__nodewrapper">
        {node.data.component}
        {!!children && (
          <div
            className="RecursiveTreeNode__toggleButton"
            onClick={(e) => props.onToggle(node, e)}
            onTouchEnd={(e) => props.onToggle(node, e)}
          >
            <span>
              {modifier.altKey ? descendants(node).length : children.length}{' '}
              {children.length === 1 ? 'team' : 'teams'}
            </span>
            <div>
              {modifier.altKey ? (
                <CaretDownFilled />
              ) : node.children ? (
                <UpOutlined />
              ) : (
                <DownOutlined />
              )}
            </div>
          </div>
        )}
      </div>
      {node.children && (
        <div
          className="RecursiveTreeNode__children"
          style={{ transformOrigin: `${node.x}px ${node.y}px` }}
        >
          {node.children.map((childNode) => (
            <RecursiveTreeNode
              key={childNode.data.domainId.itemId} //Todo: use key that's not aware of shape of data
              node={childNode}
              onToggle={onToggle}
              onClick={onClick}
              component={childNode.data.component}
              width={width}
              height={height}
              modifier={modifier}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const descendants = (d: ToggleableNode<Team>): ToggleableNode<Team>[] => {
  let desc: ToggleableNode<Team>[] = [];
  const children = d.children || d._children;

  if (children && children.length) {
    desc = desc.concat(children);
    return desc.concat(...children.map(descendants));
  }

  return desc;
};
