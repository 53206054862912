import { Card, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { BetaBadge } from '../../../../../../../../components/BetaBadge';
import { DisplayDateRange } from '../../../../../../../../components/DisplayDateRange';
import { usePreviousPeriodSummary } from './hooks/usePreviousPeriodSummary';
import { WeeklyKeyActivityProgressLog } from './weeklyKeyActivitySummary/WeeklyKeyActivityProgressLog';
import { WeeklyKeyActivityTeamSummary } from './weeklyKeyActivitySummary/WeeklyKeyActivityTeamSummary';
import { TourLink } from '../../../../../../../../components/TourLink';

interface Props {
  teamId: string;
  tenantId?: string;
}

export const WeeklyKeyActivitySummary = ({ teamId, tenantId }: Props) => {
  const { t } = useTranslation();

  const { userContributionData, data, loading } = usePreviousPeriodSummary({
    teamId,
    tenantId,
  });

  const perviousPeriodWeeklyKeyActivities =
    data?.accelerationMeetingPeriodSummary.weeklyKeyActivities || [];
  const weeklyActivityTeamContributions =
    data?.accelerationMeetingPeriodSummary.weeklyKeyActivityTeamContributions ??
    [];

  return (
    <Spin spinning={loading}>
      <div className="mb">
        <h2 className="mb--none">
          {t('WeeklyKeyActivitySummary.title')}
          <BetaBadge className="space--l" />
        </h2>
        <Typography.Text type="secondary">
          <DisplayDateRange
            startDate={data?.accelerationMeetingPeriodSummary.startDate}
            endDate={data?.accelerationMeetingPeriodSummary.endDate}
          />
        </Typography.Text>
        <TourLink
          intercomTarget="Acceleration meeting - Weekly key activity summary help modal"
          engTourId={3560}
          sweTourId={3416}
        />
      </div>
      <div className="flx">
        <Card style={{ width: 360, minWidth: 360 }}>
          <WeeklyKeyActivityTeamSummary
            startDate={data?.accelerationMeetingPeriodSummary.startDate}
            weeklyActivities={perviousPeriodWeeklyKeyActivities}
            weeklyKeyActivityTeamContributions={weeklyActivityTeamContributions}
          />
        </Card>
        <Card style={{ width: '100%', marginLeft: 16, minWidth: 780 }}>
          <WeeklyKeyActivityProgressLog
            userContributionData={userContributionData}
            teamId={teamId}
          />
        </Card>
      </div>
    </Spin>
  );
};
