import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { InitiativeSectionCard } from './InitiativeSectionCard';
import {
  ActivityPlanner_MilestoneFragment,
  ActivityPlanner_InitiativeFragment,
  TeamInitiativesForActivityPlanerDocument,
} from '../../../generated/graphql';
import { getUnique } from '../../../services/getUnique';

interface Props {
  teamId: string;
}

/**
 * Process initiatives and milestones data to organize them for rendering
 */
const processInitiativesAndMilestones = (
  initiatives: ActivityPlanner_InitiativeFragment[] = [],
  milestones: ActivityPlanner_MilestoneFragment[] = []
) => {
  const milestonesInitiatives = milestones.flatMap((m) =>
    m.metadata.supportsInitiatives.map((si) => si.data)
  );

  const uniqueInitiatives = getUnique(
    [...initiatives, ...milestonesInitiatives],
    (item) => item.id
  );

  const milestonesByInitiative = new Map<
    string,
    ActivityPlanner_MilestoneFragment[]
  >();

  milestones.forEach((milestone) => {
    milestone.metadata.supportsInitiatives.forEach((initiative) => {
      if (!milestonesByInitiative.has(initiative.data.domainId.itemId)) {
        milestonesByInitiative.set(initiative.data.domainId.itemId, []);
      }
      milestonesByInitiative
        .get(initiative.data.domainId.itemId)!
        .push(milestone);
    });
  });

  return { uniqueInitiatives, milestonesByInitiative };
};

export const ActivityPlanner: React.FC<Props> = ({ teamId }) => {
  const { data } = useQuery(TeamInitiativesForActivityPlanerDocument, {
    variables: { teamId },
  });

  const { uniqueInitiatives, milestonesByInitiative } = useMemo(() => {
    const initiatives = data?.initiativesLinkedToTeam?.initiatives ?? [];
    const milestones = data?.milestonesWithLinksForTeam?.milestones ?? [];
    return processInitiativesAndMilestones(initiatives, milestones);
  }, [data]);

  return (
    <div className="maxWidth">
      <h1 className="mb">Activity Planner</h1>

      {uniqueInitiatives.map((initiative) => (
        <InitiativeSectionCard
          key={initiative.id}
          teamId={teamId}
          initiative={initiative}
          milestones={
            milestonesByInitiative.get(initiative.domainId.itemId) ?? []
          }
          className="mb"
        />
      ))}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_PLANNER_INITIATIVE = gql`
  fragment ActivityPlanner_Initiative on InitiativeWithLinks {
    id
    domainId {
      itemId
    }
    name
    tag {
      title
      colorCode
      iconId
    }
    ...InitiativeSectionCard_HeaderContent
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_PLANNER_MILESTONE = gql`
  fragment ActivityPlanner_Milestone on MilestoneWithLinks {
    id
    domainId {
      itemId
    }
    metadata {
      supportsInitiatives {
        id
        data {
          id
          ...ActivityPlanner_Initiative
        }
      }
    }
    ...InitiativeSectionCard_Milestone
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_PLANER_QUERY = gql`
  query TeamInitiativesForActivityPlaner($teamId: ID!) {
    initiativesLinkedToTeam(teamId: $teamId) {
      initiatives {
        id
        ...ActivityPlanner_Initiative
      }
    }
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        ...ActivityPlanner_Milestone
      }
    }
  }
`;
