import { Timeline } from 'antd';

import { ActivityMilestone } from './ActivityMilestone';
import Skeleton from 'react-loading-skeleton';

export const MonthlyMilestonesSkeleton = () => {
  const timelineItems = Array.from({ length: 3 }, () => {
    return {
      children: (
        <>
          <div className="txt--secondary">
            <Skeleton />
          </div>
          <ActivityMilestone.Skeleton />
        </>
      ),
      color: 'grey',
    };
  });

  return (
    <div>
      <h4 className="mb">{<Skeleton />}</h4>
      <Timeline items={timelineItems} />
    </div>
  );
};
