import { PerformanceTag } from '../../../../../../components/PerformanceTag';
import {
  MigHeaderContent_MigV2Fragment,
  MigStatus,
} from '../../../../../../generated/graphql';
import './MigHeaderContent.less';
import { SubHeader } from '../../SubHeader';
import { PreviewGraph } from '../../../../../../components/PreviewGraph';
import cx from 'classnames';
import { gql } from '@apollo/client';
import { roundToFloor } from '../../../../../../services/roundNumbersHelper';

interface Props {
  mig: MigHeaderContent_MigV2Fragment;
  isMirrored?: boolean;
  warnings?: number;
  expanded: boolean;
  actions?: React.ReactNode;
}

export const MigHeaderContent = ({
  mig,
  warnings,
  isMirrored,
  expanded,
  actions,
}: Props) => {
  return (
    <div className="flx flx--1 flx--ai-center ">
      <div className="flx flx--1 flx--jc-space-between flx--column">
        <div className="flx flx--ai-center">
          {mig.status !== MigStatus.UPCOMING && (
            <PerformanceTag
              value={mig.mostRecentReport?.goalFulfillment}
              className="MigHeaderContent__performanceValue mr--l"
              data-intercom-target="Performance value for MIG"
            />
          )}
          <h3>{mig.name}</h3>
        </div>

        <SubHeader
          lastOutcomeDate={mig.lastOutcomeDate}
          unit={mig.unit}
          warnings={warnings}
          mirroredFromTeam={isMirrored ? mig.team2 : undefined}
        />
      </div>

      {!expanded && (
        <div
          className={cx({
            'MigHeaderContent--fadeIn': !expanded,
          })}
        >
          <PreviewGraph
            periodData={mig.periodData}
            goalLegend={roundToFloor(mig.mostRecentReport.goal)}
            progressLegend={roundToFloor(mig.mostRecentReport.actual)}
            data-intercom-target="Preview graph for MIG"
          />
        </div>
      )}

      <div className="MigHeaderContent__actions ml--xxl mr--xxl">{actions}</div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_HEADER_CONTENT_MIG_FRAGMENT = gql`
  fragment MigHeaderContent_MigV2 on MigV2 {
    id
    status
    name
    unit
    lastOutcomeDate
    team2 {
      id
      name
      domainId {
        itemId
      }
    }
    mostRecentReport {
      goalFulfillment
      goal
      actual
    }
    periodData {
      targetDate
      actual
      goal
      historic
    }
  }
`;
