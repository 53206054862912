import { gql, useQuery } from '@apollo/client';
import { TenantTeamsDocument } from '../generated/graphql';
import { stringSort } from '../services/stringSort';

export interface HierarchicalTeam {
  name: string;
  id: string;
  domainId: {
    itemId: string;
  };
  umbrellaTeam: boolean | null;
  parentTeam?: { domainId: { itemId: string } } | null;
  children?: HierarchicalTeam[] | null;
  descendantIds?: string[] | null;
}

export function buildHierarchicalTeamTree(
  teams: HierarchicalTeam[],
  parentId: string | null = null
): HierarchicalTeam[] {
  const result: HierarchicalTeam[] = [];

  const filteredTeams = teams.filter(
    (team) => team.parentTeam?.domainId.itemId == parentId
  );

  filteredTeams.forEach((team) => {
    const children = buildHierarchicalTeamTree(teams, team.domainId.itemId);

    let node = {
      ...team,
      children: null as HierarchicalTeam[] | null,
      descendantIds: null as string[] | null,
    };

    if (children.length > 0) {
      node.children = children;
      node.descendantIds = children.flatMap((c) => [
        c.id,
        ...(c.descendantIds ?? []),
      ]);
    }

    result.push(node);
  });
  return result;
}

export const useTeams = (tenantId?: string) => {
  const { data, loading, error } = useQuery(TenantTeamsDocument, {
    variables: { tenantId },
  });

  const teams = data?.teams2.teams.filter((t) => !t.umbrellaTeam) ?? [];

  // we shallow clone `teams` to prevent the sort from mutating `teamAssociations` in the gql cache
  const sortedTeams = [...teams].sort((a, b) => {
    return stringSort(a.name, b.name);
  });

  return {
    loading,
    error,
    teams: sortedTeams,
    teamsWithUmbrellaTeams: data?.teams2.teams ?? [],
  };
};

export const useHierarchicalTeams = (tenantId?: string) => {
  const { teamsWithUmbrellaTeams, loading, error } = useTeams(tenantId);

  const hierarchicalTeamTree = buildHierarchicalTeamTree(
    teamsWithUmbrellaTeams
  );

  return {
    loading,
    error,
    hierarchicalTeams: hierarchicalTeamTree ?? [],
  };
};

export const TENANT_TEAMS = gql`
  fragment TenantTeam on Team2 {
    name
    id
    domainId {
      itemId
    }
    umbrellaTeam
    parentTeam {
      domainId {
        itemId
      }
    }
  }
  query tenantTeams($tenantId: ID) {
    teams2(tenantId: $tenantId, filters: { includeArchived: false }) {
      teams {
        ...TenantTeam
      }
    }
  }
`;
