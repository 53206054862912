import { gql } from '@apollo/client';
import { MonthlyMilestones_MilestoneWithLinksForTeamFragment } from '../../../../generated/graphql';
import { useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { friendlyDate } from '../../../../services/dateFormats';

import { ActivityMilestone } from './ActivityMilestone';
import { stringSort } from '../../../../services/stringSort';
import { MonthlyMilestonesSkeleton } from './MonthlyMilestones.Skeleton';
import VertTimeline from '../../../../components/VertTimeline';
import { Dot } from '../../../../components/Dot';
import { Colors } from '../../../componentLibrary/Colors';

interface Props {
  teamId: string;
  monthTitle: Dayjs;
  milestones: MonthlyMilestones_MilestoneWithLinksForTeamFragment[];
  onMilestoneSelect: (milestoneId: string | null) => void;
}

export const MonthlyMilestones = ({
  teamId,
  monthTitle,
  milestones,
  onMilestoneSelect,
}: Props) => {
  const isCurrentYear = monthTitle.isSame(dayjs(), 'year');

  const format = isCurrentYear ? 'MMMM' : 'MMMM YYYY';
  const timelineItems = useMemo(() => {
    // Group milestones by deadline date
    const groupedByDate = milestones.reduce(
      (groups, milestone) => {
        const date = dayjs(milestone.deadlineAt).format('YYYY-MM-DD');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(milestone);
        return groups;
      },
      {} as Record<string, typeof milestones>
    );

    // Create timeline items from groups
    return Object.entries(groupedByDate).map(([date, milestonesForDate]) => {
      return {
        children: (
          <>
            <div className="txt--secondary mb--xs">
              {friendlyDate(dayjs(date))}
            </div>
            {milestonesForDate
              .sort((a, b) => stringSort(a.deadlineAt, b.deadlineAt))
              .map((milestone) => (
                <ActivityMilestone
                  className="mb--skipLast"
                  teamId={teamId}
                  key={milestone.id}
                  milestone={milestone}
                  onClick={() => onMilestoneSelect(milestone.domainId.itemId)}
                />
              ))}
          </>
        ),
        dot: <Dot color={Colors.Grays.DISABLED_FONTS} size={8} />,
      };
    });
  }, [teamId, milestones, onMilestoneSelect]);

  return (
    <div className="mb--xl">
      <h4 className="mb--s">{monthTitle.format(format)}</h4>
      <VertTimeline items={timelineItems} />
    </div>
  );
};

MonthlyMilestones.Skeleton = MonthlyMilestonesSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MONTHLY_MILESTONES_MILESTONE_WITH_LINKS = gql`
  fragment MonthlyMilestones_MilestoneWithLinksForTeam on MilestoneWithLinks {
    id
    domainId {
      itemId
      tenantId
    }
    name
    description
    deadlineAt
    metadata {
      completedAt
      status
      supportsInitiatives {
        id
        domainId {
          itemId
          tenantId
        }
      }
    }
    ...ActivityMilestone_MilestoneWithLinksForTeam
  }
`;
