import { gql } from '@apollo/client';
import { MilestoneCard2_MilestoneFragment } from '../../../../../generated/graphql';
import { MilestoneStatusIcon } from '../../../../../components/MilestoneStatusIcon';
import './MilestoneCard2.less';
import { BaseCard } from './BaseCard';
import { Modal } from 'antd';
import { useState } from 'react';
import { MilestoneCardModalContent } from '../../../../company/initiatives/initiativeDetails/progressSection/components/milestoneTable/components/milestoneActions/components/MilestoneCardModalContent';

interface Props {
  milestone: MilestoneCard2_MilestoneFragment;
}

export const MilestoneCard2 = ({ milestone }: Props) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <BaseCard
        className={`MilestoneCard2 MilestoneCard2--${milestone.metadata.status} clickable mb--s`}
        onClick={() => setShowDetails(true)}
      >
        <MilestoneStatusIcon metadata={milestone.metadata} />
        {milestone.name}
      </BaseCard>
      <Modal
        open={showDetails}
        footer={null}
        width={800}
        onCancel={() => setShowDetails(false)}
      >
        <MilestoneCardModalContent
          milestoneId={milestone.domainId.itemId}
          tenantId={milestone.domainId.tenantId}
          onCloseModal={() => setShowDetails(false)}
        />
      </Modal>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_CARDS__MILESTONE = gql`
  fragment MilestoneCard2_Milestone on MilestoneWithLinks {
    id
    domainId {
      itemId
      tenantId
    }
    name
    metadata {
      completedAt
      status
    }
    ...MilestoneStatusIcon_Milestone
  }
`;
