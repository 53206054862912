import { Select } from 'antd';
import { useGetTags } from '../../../../hooks/useGetTags';
import { TagImage } from '../../setup/overviewPage/labels/TagImage';
import { useTranslation } from 'react-i18next';

interface Props {
  teamId: string;
  value: string[] | null;
  onChange: (tags: string[] | null) => void;
}

export const TagFilter = ({ teamId, value, onChange }: Props) => {
  const { t } = useTranslation();
  const { tags } = useGetTags(teamId);

  const setTagsForFiltering = (selectedTags: string[]) => {
    onChange(selectedTags.length > 0 ? selectedTags : null);
  };

  return (
    <div
      className="flx--1"
      data-intercom-target="Sprint View Table Owner Filter"
    >
      <div className="flx">
        <h5>{t('TagFilter.title')}&nbsp;</h5>
      </div>

      <Select
        className="d-block"
        popupMatchSelectWidth={false}
        placeholder={t('TagFilter.addTag')}
        mode="multiple"
        value={value}
        onChange={setTagsForFiltering}
        showSearch
        filterOption={(input, option) => {
          if (!option) return false;
          return option.plainText.toLowerCase().includes(input.toLowerCase());
        }}
        options={tags.map((tag) => ({
          label: <TagImage tag={tag} />,
          plainText: tag.name,
          value: tag.id,
        }))}
      />
    </div>
  );
};
