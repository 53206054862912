import { Table, Empty, Radio } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import {
  MigDomainId,
  MigPreviewTable_MigV2Fragment,
  TeamMigFragment,
} from '../../../../generated/graphql';
import { friendlyDate } from '../../../../services/dateFormats';
import './MigPreviewTable.less';
import { gql } from '@apollo/client';

interface Props {
  previewMigs?: TeamMigFragment[];
  loading?: boolean;
  onChange: (migId: MigDomainId) => void;
  value: MigDomainId | null;
  linkedMigs?: MigPreviewTable_MigV2Fragment[];
}

export const MigPreviewTable = ({
  linkedMigs,
  previewMigs,
  loading,
  onChange,
  value,
}: Props) => {
  const { t } = useTranslation();
  const linkedMigIds = linkedMigs?.map((mig) => mig.domainId.itemId) ?? [];
  const columns: ColumnProps<TeamMigFragment>[] = [
    {
      title: t('MigPreviewTable.selected'),
      key: 'id',
      width: 60,
      dataIndex: ['domainId', 'itemId'],
      render: (itemId: string, record) => {
        if (linkedMigIds?.includes(itemId)) {
          return (
            <div className="text-c">{t('MigPreviewTable.alreadyMirrored')}</div>
          );
        }
        return (
          <Radio
            checked={itemId === value?.itemId}
            onClick={() => onChange(record.domainId)}
          ></Radio>
        );
      },
    },
    {
      title: t('common.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_, mig) => {
        return mig.isMirrored ? (
          <div>
            <strong>{mig.name}</strong>
            <div>
              {t(`common.mig.managedBy`)}
              {mig.team2.name}
            </div>
          </div>
        ) : (
          <strong>{mig.name}</strong>
        );
      },
    },
    {
      title: t('common.startDate'),
      defaultSortOrder: 'ascend',
      dataIndex: 'startDate',
      key: 'startDate',

      render: (_, { startDate }) => {
        return <div className="flx">{friendlyDate(startDate)}</div>;
      },
    },
    {
      title: t('common.endDate'),
      dataIndex: 'lastOutcomeDate',
      key: 'lastOutcomeDate',
      render: (_, mig) => {
        return friendlyDate(mig.lastOutcomeDate);
      },
    },
  ];

  return (
    <Table
      size="small"
      className="MigPreviewTable"
      dataSource={previewMigs}
      onRow={(record) => {
        return {
          onClick: () => {
            if (linkedMigIds?.includes(record.domainId.itemId)) {
              return null;
            }
            onChange(record.domainId);
          },
        };
      }}
      rowClassName={(record) => {
        if (linkedMigIds?.includes(record.domainId.itemId)) {
          return 'TableRow disabled';
        }
        return record.domainId.itemId === value?.itemId
          ? 'SelectedRow TableRow'
          : 'TableRow';
      }}
      columns={columns}
      pagination={false}
      bordered
      loading={loading}
      rowKey={(previewMig) => previewMig.id}
      style={{ minHeight: 220 }}
      locale={{
        emptyText: (
          <div>
            {!loading && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span className="center-content pb pt">
                    {t('common.mig.noData')}
                  </span>
                }
              />
            )}
          </div>
        ),
      }}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_PREVIEW_TABLE_MIG_V2 = gql`
  fragment MigPreviewTable_MigV2 on MigV2 {
    id
    domainId {
      itemId
      tenantId
    }
  }
`;
