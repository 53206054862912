import { Switch } from 'antd';
import { useAccelerationMeeting } from '../AccelerationMeetingProvider';

interface Props {
  activityId: string;
  userId: string;
}

export const ActivityCommitmentSwitch = ({ activityId, userId }: Props) => {
  const { sprintKeyActivityContext } = useAccelerationMeeting();

  const usersCommitments =
    sprintKeyActivityContext.getUsersCommitments(activityId);
  const ownerCommitment = usersCommitments?.commitment;

  const handleChange = (checked: boolean) => {
    sprintKeyActivityContext.setUserCommitment(activityId, {
      committed: checked,
      userId,
    });
  };

  return (
    <Switch checked={ownerCommitment?.committed} onChange={handleChange} />
  );
};
