import { useMe, useMeLazy } from './useMe';

export const useCurrentLocale = () => {
  const response = useMe();

  return { ...response, locale: response.data?.currentUser?.locale };
};
export const useCurrentLocaleLazy = () => {
  const [load, response] = useMeLazy();

  return [
    load,
    { ...response, locale: response.data?.currentUser?.locale },
  ] as const;
};
