import dayjs, { Dayjs } from 'dayjs';
import { useState, useMemo, useEffect } from 'react';
import { Btn } from '../../../../components/Button';
import { ApolloQueryResult, gql, useMutation, useQuery } from '@apollo/client';
import {
  ActivityCardsMilestoneQueryDocument,
  ActivityCardsPage_MilestoneFragment,
  ActivityCardsPage_MitemFragment,
  GetActivitiesForTeamTableQuery,
  UpdateActivityDeadlineDocument,
} from '../../../../generated/graphql';
import { getMonthsBetween } from '../TeamActivitiesRoutes';
import { MonthQuickPicker } from '../../common/MonthQuickPicker';
import './ActivityCards.page.less';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { stringSort } from '../../../../services/stringSort';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { WeekView } from './components/WeekView';
import { showNotification } from '../../../../services/fetchNotificationProperties';
import { ActivityCard } from './components/ActivityCard';
import cx from 'classnames';
import VertTimeline from '../../../../components/VertTimeline';
import { Colors } from '../../../componentLibrary/Colors';
import { useModal } from '../../../../ModalProvider';

interface Props {
  teamId: string;
  startDate: Dayjs;
  groupedActivities: Map<string, ActivityCardsPage_MitemFragment[]>;
  fetchMore: (
    startDate: Dayjs,
    endDate: Dayjs
  ) => Promise<ApolloQueryResult<GetActivitiesForTeamTableQuery>>;
  onJumpToMonth: (date: Dayjs) => void;
}

export const ActivityCardsPage = ({
  teamId,
  startDate,
  groupedActivities,
  fetchMore,
  onJumpToMonth,
}: Props) => {
  const [monthRange, setMonthRange] = useState<{
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  }>(() => dateToDefaultRange(startDate));

  const { data } = useQuery(ActivityCardsMilestoneQueryDocument, {
    variables: { teamId },
  });

  const [mutateDeadline] = useMutation(UpdateActivityDeadlineDocument, {
    onError: () =>
      showNotification('error', {
        message: 'Something went wrong. Failed to update deadline.',
      }),
  });

  const updateDeadline = (
    activity: ActivityCardsPage_MitemFragment,
    newDeadline: string
  ) => {
    mutateDeadline({
      variables: {
        teamId: activity.teamId,
        mitemId: activity.id,
        deadline: {
          dueDate: newDeadline,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateMitemDeadline: {
          ...activity,
          deadline: newDeadline,
        },
      },
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // Require pointer to move by 5 pixels before activating draggable
        // Allows nested onClicks/buttons/interactions to be accessed
        distance: 5,
      },
    })
  );

  const { openModal } = useModal();

  useEffect(() => {
    setMonthRange(dateToDefaultRange(startDate));
  }, [startDate]);

  const monthsToDisplay = useMemo(
    () => getMonthsBetween(monthRange.start, monthRange.end),
    [monthRange]
  );

  const groupedByMonthAndWeek: MonthGroup[] = monthsToDisplay.map(
    (monthKey) => ({
      month: monthKey,
      weeks: getWeeksInMonth(
        monthKey,
        groupedActivities.get(monthKey) || [],
        data?.milestonesWithLinksForTeam.milestones || []
      ),
    })
  );

  const [draggedActivity, setDraggedActivity] =
    useState<ActivityCardsPage_MitemFragment | null>(null);

  const [draggedOver, setDraggedOver] = useState<string | null>(null);

  const handleDragStart = (event: DragStartEvent) => {
    const activity = event.active.data.current?.activity;
    setDraggedActivity(activity);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setDraggedActivity(null);
    setDraggedOver(null);

    const targetDate = event.over?.data.current?.week?.to;
    const activity = event.active.data.current?.activity;

    if (!targetDate || !activity) return;
    if (targetDate === activity.deadline) return;
    updateDeadline(activity, targetDate);
  };

  const handleDragOver = (event: DragOverEvent) => {
    const targetDate = event.over?.data.current?.week?.to;

    setDraggedOver(targetDate);
  };

  const handleDragAbort = () => {
    setDraggedActivity(null);
    setDraggedOver(null);
  };

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragAbort={handleDragAbort}
      onDragCancel={handleDragAbort}
    >
      <div className="ActivityCardsPage">
        <div className="flx mt">
          <Btn
            size="small"
            className="mr"
            type="link"
            icon={<LeftOutlined />}
            onClick={() => {
              const previousMonth = monthRange.start.subtract(1, 'months');
              fetchMore(
                previousMonth.startOf('month'),
                previousMonth.endOf('month')
              ).then(() => {
                setMonthRange({
                  start: previousMonth.startOf('month'),
                  end: monthRange.end.subtract(1, 'months').endOf('month'),
                });
              });
            }}
          >
            Previous
          </Btn>
          <MonthQuickPicker
            btnProps={{ className: 'ml' }}
            onConfirm={onJumpToMonth}
          />
          <Btn
            size="small"
            type="link"
            className="ml--auto"
            icon={<RightOutlined />}
            iconPosition="end"
            onClick={() => {
              const nextMonth = monthRange.end.add(1, 'months');
              fetchMore(
                nextMonth.startOf('month'),
                nextMonth.endOf('month')
              ).then(() => {
                setMonthRange({
                  start: monthRange.start.add(1, 'months').startOf('month'),
                  end: nextMonth.endOf('month'),
                });
              });
            }}
          >
            Next
          </Btn>
        </div>
        <div className="flx mt--l">
          {groupedByMonthAndWeek.map((month) => {
            const isCurrentYear = dayjs(month.month).isSame(dayjs(), 'year');
            // Compare the activity deadline with the week's end date
            return (
              <div key={month.month} className="mr--xxl">
                <h4 className="mb">
                  {dayjs(month.month).format(
                    isCurrentYear ? 'MMMM' : 'MMMM YYYY'
                  )}
                </h4>
                <VertTimeline
                  items={month.weeks?.map((week) => {
                    const isDraggingToSameWeek =
                      draggedActivity?.deadline === week.to;
                    const canDrop =
                      !isDraggingToSameWeek && draggedOver === week.to;
                    return {
                      dot: (
                        <div
                          className={cx('ActivityCardsPage__timelineDot', {
                            'ActivityCardsPage__timelineDot--drop': canDrop,
                          })}
                        >
                          {canDrop && <span>+</span>}
                        </div>
                      ),
                      tailColor: canDrop ? Colors.Action.HOVER_BLUE : undefined,
                      children: (
                        <WeekView
                          week={week}
                          teamId={teamId}
                          onCreateActivity={() =>
                            openModal({
                              type: 'createActivity',
                              teamId: teamId,
                              formType: {
                                type: 'simple',
                              },
                            })
                          }
                        />
                      ),
                    };
                  })}
                />
              </div>
            );
          })}
        </div>
        <DragOverlay style={{ opacity: 0.7 }}>
          {draggedActivity ? <ActivityCard activity={draggedActivity} /> : null}
        </DragOverlay>
      </div>
    </DndContext>
  );
};

const dateToDefaultRange = (date: Dayjs) => ({
  start: date,
  end: date.add(3, 'months').endOf('month'),
});

/*
 * Note: Week might not start on Monday if the month starts on a Tuesday, for example
 * This is because we want to show the week that contains the first day of the month
 * and likewise for the last week of the month, it might not end on Sunday
 */
export interface Week {
  from: string;
  to: string;
  activitiesAndMilestones: (
    | ActivityCardsPage_MitemFragment
    | ActivityCardsPage_MilestoneFragment
  )[];
}

interface MonthGroup {
  month: string;
  weeks: Week[];
}

const getWeeksInMonth = (
  monthKey: string,
  activities: ActivityCardsPage_MitemFragment[] = [],
  milestones: ActivityCardsPage_MilestoneFragment[] = []
) => {
  const date = dayjs(monthKey);
  const startOfMonth = date.startOf('month');
  const endOfMonth = date.endOf('month');

  let weekStart = startOfMonth;
  const weeks: Week[] = [];

  while (weekStart.isBefore(endOfMonth)) {
    // For any week except the last one, end on Sunday
    let weekEnd = weekStart.day(7);

    // If this would go beyond the end of month, use end of month instead
    if (weekEnd.isAfter(endOfMonth)) {
      weekEnd = endOfMonth;
    }

    const activitiesInWeek = activities.filter((activity) => {
      const deadline = dayjs(activity.deadline);
      return (
        deadline.isSameOrAfter(weekStart, 'day') &&
        deadline.isSameOrBefore(weekEnd, 'day')
      );
    });

    const milestonesInWeek = milestones.filter((milestone) => {
      const deadline = dayjs(milestone.deadlineAt);
      return (
        deadline.isSameOrAfter(weekStart, 'day') &&
        deadline.isSameOrBefore(weekEnd, 'day')
      );
    });

    // Combine and sort by deadline (activities) or deadlineAt (milestones)
    const combinedItems = [...activitiesInWeek, ...milestonesInWeek].sort(
      (a, b) => {
        const deadlineA = a.__typename === 'Mitem' ? a.deadline : a.deadlineAt;
        const deadlineB = b.__typename === 'Mitem' ? b.deadline : b.deadlineAt;

        return stringSort(deadlineA, deadlineB);
      }
    );

    weeks.push({
      from: weekStart.format('YYYY-MM-DD'),
      to: weekEnd.format('YYYY-MM-DD'),
      activitiesAndMilestones: combinedItems,
    });

    if (weekEnd.isSame(endOfMonth)) {
      break;
    }

    weekStart = weekEnd.add(1, 'day');
  }

  return weeks;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_CARDS__MITEM = gql`
  fragment ActivityCardsPage_Mitem on Mitem {
    id
    deadline
    ...ActivityCard_Mitem
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_CARDS__MILESTONE = gql`
  fragment ActivityCardsPage_Milestone on MilestoneWithLinks {
    id
    deadlineAt
    ...MilestoneCard2_Milestone
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ActivityList_Milestones = gql`
  query ActivityCardsMilestoneQuery($teamId: ID!) {
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        ...ActivityCardsPage_Milestone
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UPDATE_MITEM_DEADLINE = gql`
  mutation UpdateActivityDeadline(
    $teamId: ID!
    $mitemId: ID!
    $deadline: MitemDeadlineInput
  ) {
    updateMitemDeadline(
      teamId: $teamId
      mitemId: $mitemId
      deadline: $deadline
    ) {
      id
      deadline
      status
    }
  }
`;
