import { gql, useMutation } from '@apollo/client';
import { Form, Input, Modal, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { UpdateUserBasicSettingsDocument } from '../../../generated/graphql';
import { availableLanguages } from '../../../languages';
import { showNotification } from '../../../services/fetchNotificationProperties';

const DISPLAY_NAME_MIN_LENGTH = 2;
const DISPLAY_NAME_MAX_LENGTH = 50;

const NAME_MIN_LENGTH = 5;
const NAME_MAX_LENGTH = 110;
interface Props {
  field: 'name' | 'displayName' | 'locale';
  value?: string | null;
  rev: string;
  onClose: () => void;
}

export const UpdateMySettingsModal = ({
  field,
  value,
  rev,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [updateUser, { error, loading }] = useMutation(
    UpdateUserBasicSettingsDocument,
    {
      onCompleted: () => {
        // Put the notification invocation at the bottom of the stack so that it is displayed AFTER the language changes.
        setTimeout(() => {
          showNotification('success', {
            message: t('UpdateMySettingsModal.saved'),
          });
        });
        onClose();
      },
    }
  );

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      updateUser({
        variables: {
          user: { [field]: values[field], rev },
        },
      });
    });
  };

  const formItem = () => {
    switch (field) {
      case 'name':
        return (
          <Form.Item
            name="name"
            label={t('common.name')}
            rules={[
              {
                min: NAME_MIN_LENGTH,
                max: NAME_MAX_LENGTH,
                message: t('UpdateMySettingsModal.name.lengthValidation', {
                  min: NAME_MIN_LENGTH,
                  max: NAME_MAX_LENGTH,
                }),
              },
            ]}
          >
            <Input autoComplete="off" type="text" autoFocus />
          </Form.Item>
        );
      case 'displayName':
        return (
          <Form.Item
            name="displayName"
            label={t('UpdateMySettingsModal.displayName.label')}
            rules={[
              {
                min: DISPLAY_NAME_MIN_LENGTH,
                max: DISPLAY_NAME_MAX_LENGTH,
                message: t(
                  'UpdateMySettingsModal.displayName.lengthValidation',
                  {
                    min: DISPLAY_NAME_MIN_LENGTH,
                    max: DISPLAY_NAME_MAX_LENGTH,
                  }
                ),
              },
            ]}
          >
            <Input autoComplete="off" type="text" autoFocus />
          </Form.Item>
        );

      case 'locale':
        return (
          <Form.Item
            name="locale"
            label={t('UpdateMySettingsModal.locale.label')}
          >
            <Select allowClear>
              {availableLanguages.map((language) => {
                return (
                  <Select.Option key={language.code} value={language.code}>
                    {t(`locale.${language.code}`)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        );
      default:
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        throw new Error('Invalid user property: ' + field);
    }
  };

  return (
    <Modal
      title={
        t('UpdateMySettingsModal.modal.title') +
        ' | ' +
        t(`UpdateMySettingsModal.${field}.label`)
      }
      open
      onOk={handleSubmit}
      okButtonProps={{ loading }}
      onCancel={onClose}
    >
      <div className="companyDetails">
        <Form layout="vertical" form={form} initialValues={{ [field]: value }}>
          {formItem()}
        </Form>
        <ErrorAlert
          error={error}
          title={t('UpdateMySettingsModal.modal.genericError')}
        />
      </div>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_USER = gql`
  mutation updateUserBasicSettings($user: EditUser2Input!) {
    editCurrentUser(user: $user) {
      id
      name
      displayName
      locale
      email
      rev
    }
  }
`;
