import { gql, useQuery } from '@apollo/client';
import {
  GetTeamsWithMigsDocument,
  MigStatus,
} from '../../../../generated/graphql';

export const useTeamsWithMigsPreview = (tenantId?: string) => {
  const { loading, data, error } = useQuery(GetTeamsWithMigsDocument, {
    variables: { tenantId: tenantId },
  });

  return {
    teamsWithMigs: data,
    error,
    loading,
  };
};
export interface TeamWithMig {
  id: string;
  name: string;
  domainId: { itemId: string };
  teamMigs: {
    id: string;
    name: string;
    status: MigStatus;
    lastOutcomeDate: string;
    domainId: {
      itemId: string;
      tenantId: string;
    };
    team2: {
      id: string;
      domainId: {
        itemId: string;
      };
      name: string;
    };
  }[];
  parentTeam?: {
    id: string;
    domainId: { itemId: string };
    data: { id: string; name: string };
  } | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAMS_WITH_MIGS = gql`
  query getTeamsWithMigs($tenantId: ID) {
    teams2(tenantId: $tenantId) {
      teams {
        name
        id
        domainId {
          itemId
        }
        teamMigs(status: [ACTIVE, UPCOMING]) {
          id
          name
          status
          lastOutcomeDate
          domainId {
            itemId
            tenantId
          }
          team2 {
            id
            domainId {
              itemId
            }
            name
          }
        }
        parentTeam {
          id
          domainId {
            itemId
          }
          data {
            id
            name
          }
        }
      }
    }
  }
`;
