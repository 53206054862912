import { useTranslation } from 'react-i18next';
import { ManageInitiativeTeamsModalContentSkeleton } from './ManageInitiativeTeamsModalContent.skeleton';
import {
  Action,
  GetTeamsLinkedToInitiativeDocument,
  ManageInitiativeTeams__TeamFragment,
  TeamLinkTypes,
  TeamResourceType,
} from '../../../../../generated/graphql';

import Table, { ColumnProps } from 'antd/es/table';
import { EnrollTeamToInitiative } from './EnrollTeamToInitiative';
import { RemoveTeamLink } from './RemoveTeamLink';
import { gql, useSuspenseQuery } from '@apollo/client';

interface Props {
  initiativeId: string;
}

export const ManageInitiativeTeamsModalContent = ({ initiativeId }: Props) => {
  const { t } = useTranslation();

  const { data } = useSuspenseQuery(GetTeamsLinkedToInitiativeDocument, {
    variables: {
      initiativeId,
      requestPermission: {
        resourceType: TeamResourceType.TEAM_LINKS,
        action: Action.DELETE,
      },
    },
  });

  const columns: ColumnProps<ManageInitiativeTeams__TeamFragment>[] = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      key: 'name',
    },
    {
      defaultSortOrder: 'ascend',
      key: 'actions',
      width: 150,
      render: (_, record) => {
        return (
          <RemoveTeamLink
            domainId={{
              itemId: initiativeId,
              tenantId: record.domainId.tenantId,
            }}
            teamId={record.domainId.itemId}
            linkType={TeamLinkTypes.INITIATIVE}
            mutationOptions={{
              refetchQueries: [
                {
                  query: GetTeamsLinkedToInitiativeDocument,
                  variables: {
                    initiativeId,
                    requestPermission: {
                      resourceType: TeamResourceType.TEAM_LINKS,
                      action: Action.DELETE,
                    },
                  },
                },
              ],
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="flx flx--column">
      <div className="mb--xl">
        <h4 className="mb">
          {t('ManageInitiativeTeamsModalContent.assignTeam')}
        </h4>
        <EnrollTeamToInitiative
          initiativeId={initiativeId}
          linkedTeams={data.teamsLinkedToInitiative2.linkedTeams}
        />
      </div>
      <h4 className="mb">
        {t('ManageInitiativeTeamsModalContent.alreadyAssignedTeams')}
      </h4>

      <Table
        size="middle"
        className="showScrollBar"
        rowKey={(record) => record.name}
        dataSource={data.teamsLinkedToInitiative2.linkedTeams.filter((team) =>
          data.permittedToPerformActionForTeam.permittedResources.some(
            (prt) => prt.teamId === team.domainId.itemId
          )
        )}
        columns={columns}
        loading={false}
        pagination={false}
      />
    </div>
  );
};

ManageInitiativeTeamsModalContent.Skeleton =
  ManageInitiativeTeamsModalContentSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MANAGE_INITIATIVE_TEAMS_GET_LINKED_TEAMS = gql`
  query getTeamsLinkedToInitiative(
    $tenantId: ID
    $initiativeId: ID!
    $requestPermission: PermissionsForTeamsInput!
  ) {
    teamsLinkedToInitiative2(tenantId: $tenantId, initiativeId: $initiativeId) {
      id
      linkedTeams {
        ...EnrollTeamToInitiative__TeamId

        ...manageInitiativeTeams__team
      }
    }
    permittedToPerformActionForTeam(requestPermission: $requestPermission) {
      permittedResources {
        teamId
        resourceType
        action
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MANAGE_INITIATIVE_TEAMS__TEAM = gql`
  fragment manageInitiativeTeams__team on Team2 {
    id
    domainId {
      itemId
      tenantId
    }
    name
  }
`;
