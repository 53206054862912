import { Form } from 'antd';
import { Btn } from '../../../../../../components/Button';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { atLeastOne } from '../../../../../../services/error-parser/helpers';
import { gql, useQuery } from '@apollo/client';
import {
  MitemFormTenantInitiativeFormItem_InitiativeFragment,
  MitemFormTenantInitiativesForTeamDocument,
} from '../../../../../../generated/graphql';
import { TenantInitiativeSelector } from './TenantInitiativeSelector';
import { RecursiveOmit } from '../../../../../../services/typeHelpers';

type PlainInitiative = RecursiveOmit<
  MitemFormTenantInitiativeFormItem_InitiativeFragment,
  '__typename'
>;
interface Props {
  teamId: string;
  selectedInitiativeIds: string[];
  initialSupportInitiatives?: PlainInitiative[];
}

export const MitemFormTenantInitiativeFormItem = ({
  teamId,
  selectedInitiativeIds,
  initialSupportInitiatives,
}: Props) => {
  const { t } = useTranslation();
  const { data } = useQuery(MitemFormTenantInitiativesForTeamDocument, {
    variables: { teamId },
  });

  const joinedInitiatives: PlainInitiative[] =
    data?.initiativesLinkedToTeam.initiatives ?? [];

  const selectableTeamInitiatives = joinedInitiatives.filter(
    (joinedInit) =>
      (joinedInit.metadata.archived === false &&
        joinedInit.metadata.completedAt == null) ||
      initialSupportInitiatives?.some((init) => init.id === joinedInit.id)
  );

  // if initialSupportInitiatives contain an initiative that is not in the joinedInitiatives, add it to the selectableTeamInitiatives
  initialSupportInitiatives?.forEach((init) => {
    if (!joinedInitiatives.some((joinedInit) => joinedInit.id === init.id)) {
      selectableTeamInitiatives.push(init);
    }
  });

  const teamHasInitiatives = atLeastOne(joinedInitiatives);

  return (
    <Form.Item label={t('MitemForm.initiative')}>
      <Form.List name="supportsInitiativeIds">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map(({ key, ...field }, index) => (
                <div className="flx" key={key + index}>
                  <Form.Item className="flx--1" {...field}>
                    <TenantInitiativeSelector
                      initiatives={selectableTeamInitiatives ?? []}
                      selectedInitiativeIds={selectedInitiativeIds}
                      className={
                        index > 0 ? 'SprintKaForm__initiativeSelector' : ''
                      }
                      disabled={!teamHasInitiatives}
                      placeHolder={
                        !teamHasInitiatives
                          ? t('MitemForm.noInitiativesAvailable')
                          : undefined
                      }
                    />
                  </Form.Item>
                  {index > 0 && (
                    <Btn
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  )}
                </div>
              ))}

              <Form.Item>
                <Btn
                  type="link"
                  style={{ padding: 0 }}
                  icon={<PlusOutlined />}
                  disabled={
                    !teamHasInitiatives ||
                    joinedInitiatives?.length === selectedInitiativeIds?.length
                  }
                  onClick={() => {
                    add();
                  }}
                >
                  {t('MitemForm.addInitiative')}
                </Btn>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </Form.Item>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MITEM_FORM_INITIATIVES_FOR_TEAM = gql`
  query mitemFormTenantInitiativesForTeam($tenantId: ID, $teamId: ID!) {
    initiativesLinkedToTeam(tenantId: $tenantId, teamId: $teamId) {
      initiatives {
        ...MitemFormTenantInitiativeFormItem_initiative
      }
    }
  }
`;

//eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_FRAGMENT = gql`
  fragment MitemFormTenantInitiativeFormItem_initiative on InitiativeWithLinks {
    id
    tag {
      title
      iconId
      colorCode
    }
    metadata {
      completedAt
      status
      archived
    }
  }
`;
