import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getUnique } from '../../../../../../services/getUnique';
import './MitemFilter.less';

type FilterMig = {
  id: string;
  name: string;
  domainId: {
    itemId: string;
  };
};

interface Props {
  migs: FilterMig[];
  value: string;
  onChange: (migId: string) => void;
}

/** @deprecated: kill when sprints are killed */
export const MigFilter = ({ migs, value, onChange }: Props) => {
  const { t } = useTranslation();
  const { Option } = Select;

  const uniqueMigs = getUnique(migs);

  return (
    <div
      className="MitemFilters__filterContainer mr--s"
      data-intercom-target="Sprint View Table MIG Filter"
    >
      <h5>{t('common.mostImportantGoal')}</h5>
      <Select
        defaultValue={value}
        onChange={onChange}
        className="MitemFilter__Select"
      >
        <Option value="ALL">{t('MigFilter.AllMigs')}</Option>
        {uniqueMigs?.map((o) => {
          return (
            <Option key={o.domainId.itemId} value={o.domainId.itemId}>
              {o.name}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};
