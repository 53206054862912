import { gql } from '@apollo/client';
import { KeyActivityStatusIcon } from '../../../../../components/KeyActivityStatusIcon';
import { getSimpleStatus } from '../../../../../services/mitemUtils';
import { ActivityCard_MitemFragment } from '../../../../../generated/graphql';
import { BaseCard } from './BaseCard';
import { useKeyActivityDetailsModal } from '../../../../../hooks/useKeyActivityDetailsModal';

interface Props {
  activity: ActivityCard_MitemFragment;
}

export const ActivityCard = ({ activity }: Props) => {
  const keyActivityDetailsModal = useKeyActivityDetailsModal();

  return (
    <BaseCard
      className="mb--s clickable"
      onClick={() =>
        keyActivityDetailsModal.openModal(activity.id, activity.teamId)
      }
    >
      <KeyActivityStatusIcon status={getSimpleStatus(activity.status)} />
      {activity.name}
    </BaseCard>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_CARDS__MITEM = gql`
  fragment ActivityCard_Mitem on Mitem {
    id
    name
    status
    teamId
  }
`;
