import { gql, useMutation } from '@apollo/client';
import { Switch } from 'antd';
import { UpdateUserNotificationSettingDocument } from '../../../generated/graphql';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { useTranslation } from 'react-i18next';

interface Props {
  rev: string;
  calendarNotifications: boolean;
}

export const UserCalendarIntegrationSwitch = ({
  calendarNotifications,
  rev,
}: Props) => {
  const { t } = useTranslation();
  const [updateUserNotification, { loading }] = useMutation(
    UpdateUserNotificationSettingDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: (
            <strong>{t('UserCalendarIntegrationSwitch.success')}</strong>
          ),
        });
      },
      onError: () => {
        showNotification('error', {
          message: t('UserCalendarIntegrationSwitch.saveError'),
        });
      },
    }
  );
  const handleOnChange = (value: boolean) => {
    updateUserNotification({
      variables: {
        user: { integrations: { calendarNotifications: value }, rev },
      },
    });
  };

  return (
    <Switch
      className="mr--xl"
      checked={calendarNotifications}
      onChange={(value) => handleOnChange(value)}
      loading={loading}
      disabled={loading}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_CALENDAR_NOTIFICATION_USER = gql`
  mutation updateUserNotificationSetting($user: EditUser2Input!) {
    editCurrentUser(user: $user) {
      id
      name
      displayName
      locale
      email
      integrations {
        calendarNotifications
      }
      rev
    }
  }
`;
