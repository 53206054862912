import {
  PublishStatus,
  WkaCollapsibleCard_WeeklyKeyActivityFragment,
} from '../../../../../../../../generated/graphql';
import { useImageSaver } from '../../../../../../../../hooks/useImageSaver';
import { friendlyDate } from '../../../../../../../../services/dateFormats';
import { CollapseCard } from '../../../../collapseCard/CollapseCard';
import { WkaDetails } from './WkaDetails';
import { WkaGraph } from './WkaGraph';
import { WkaHeaderContent } from './WkaHeaderContent';
import cx from 'classnames';
import './WkaCollapsibleCard.less';
import { gql } from '@apollo/client';

interface Props {
  wka: WkaCollapsibleCard_WeeklyKeyActivityFragment;
  isExpanded: boolean;
  onToggleExpand: () => void;
  loading?: boolean;
}

export const WkaCollapsibleCard = ({
  wka,
  isExpanded,
  onToggleExpand,
  loading,
}: Props) => {
  const { ref, SaveImageButton } = useImageSaver(
    `WKA - ${wka.team2.name} - ${wka.name}`
  );

  const periodData = wka.periodData.map((p) => ({
    ...p,
    label: friendlyDate(p.targetDate),
  }));

  return (
    <div ref={ref} className="mb--l">
      <CollapseCard
        onClick={onToggleExpand}
        expanded={isExpanded}
        className={cx({
          'WkaCollapsibleCard__headerBorder--upcomingMig':
            wka.status === PublishStatus.UPCOMING,
          'WkaCollapsibleCard__headerBorder--endedMig':
            wka.status === PublishStatus.ENDED,
          'WkaCollapsibleCard__headerBorder--archivedMig':
            wka.status === PublishStatus.ARCHIVED,
        })}
        loading={loading}
        headerContent={
          <WkaHeaderContent
            wka={wka}
            periodData={periodData}
            expanded={isExpanded}
          />
        }
      >
        <div className="borderRight">
          <WkaGraph
            keyActivity={wka}
            periodData={periodData}
            actions={<SaveImageButton />}
          />
        </div>
        <WkaDetails wka={wka} />
      </CollapseCard>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WKA_COLLAPSIBLE_CARD_FRAGMENT = gql`
  fragment WkaCollapsibleCard_WeeklyKeyActivity on WeeklyKeyActivity {
    id
    name
    status
    team2 {
      id
      domainId {
        itemId
      }
      name
    }
    ...WkaHeaderContent_WeeklyKeyActivity
    ...WkaGraph_WeeklyKeyActivity
    ...WkaDetails_WeeklyKeyActivity
  }
`;
