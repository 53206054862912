import { Input, Form, Divider, Alert } from 'antd';
import './CreateActivity.less';
import {
  FlagOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  PureQueryOptions,
  useSuspenseQuery,
  useMutation,
  gql,
} from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { skaValidationRules } from '../../../../appPages/team/common/utils/useSkaValidationRules';
import { TagsSelector } from '../../../../appPages/team/sprint/planning/components/MitemForm/TagsItem';
import {
  GetActivityForEditDocument,
  EditActivityDocument,
  SprintKaInput,
  ActivityFullForm_MitemFragment,
} from '../../../../generated/graphql';
import { useTenantDetails } from '../../../../hooks/useTenantDetails';
import { standardDateFormat } from '../../../../services/dateFormats';
import { getUnique } from '../../../../services/getUnique';
import { RecursiveOmit } from '../../../../services/typeHelpers';
import { Btn } from '../../../Button';
import { AlignmentIcon } from '../../../initiatives/InitiativeForm/Icons/AlignmentIcon';
import { EndingPointIcon } from '../../../initiatives/InitiativeForm/Icons/EndingPointIcon';
import { PersonSearchIcon } from '../../../initiatives/InitiativeForm/Icons/PersonSearchIcon';
import { TargetIcon } from '../../../initiatives/InitiativeForm/Icons/TargetIcon';
import { TeamTagIcon } from '../../../initiatives/InitiativeForm/Icons/TeamTagIcon';
import { TextAreaIconIcon } from '../../../initiatives/InitiativeForm/Icons/TextAreaIcon';
import { TitleIcon } from '../../../initiatives/InitiativeForm/Icons/TitleIcon';
import { DeadlinePickerFormItem } from './formItems/DeadlinePickerFormItem';
import { InitiativeSelectFormItem } from './formItems/InitiativeSelectFormItem';
import { MemberSelectFormItem } from './formItems/MemberSelectFormItem';
import { MigSelectFormItem } from './formItems/MigSelectFormItem';
import { MilestoneSelectFormItem } from './formItems/MilestoneSelectFormItem';

export interface ActivityAlignment {
  id: string;
  initiativeId?: string;
  milestoneId?: string;
}

interface Props {
  refetchQueries?: Array<string | PureQueryOptions>;
  onSuccess?: () => void;
  teamId: string;
  activityId: string;
}

export const EditActivity = ({
  teamId,
  activityId,
  onSuccess,
  refetchQueries,
}: Props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { data: activityData } = useSuspenseQuery(GetActivityForEditDocument, {
    variables: {
      teamId,
      activityId,
    },
  });

  const isCompleted = activityData.mitem.completed;

  // Map activity data to form values
  const { mapActivityToFormValues, initialEntities } = useMemo(() => {
    const directlyAlignedInitiatives =
      activityData.mitem.supportsInitiativeLinks?.map((i) => i.data) ?? [];

    const milestonesInitiatives =
      activityData.mitem.supportsMilestoneLinks.flatMap((m) =>
        m.data.metadata.supportsInitiatives.map((si) => si.data)
      );

    const uniqueInitiatives = getUnique(
      [...directlyAlignedInitiatives, ...milestonesInitiatives],
      (item) => item.id
    );

    if (!activityData?.mitem) return {};

    return {
      mapActivityToFormValues: {
        name: activityData.mitem.name,
        definitionOfDone: activityData.mitem.definitionOfDone,
        deadline: activityData.mitem.deadline
          ? dayjs(activityData.mitem.deadline)
          : undefined,
        ownerId: activityData.mitem.owner2?.domainId.itemId,
        tags: activityData.mitem.tags?.map((t) => t.id) ?? [],
        alignments: [
          // From direct initiative links
          ...(activityData.mitem.supportsInitiativeLinks?.map((link) => ({
            initiativeId: link.id,
            milestoneId: undefined,
          })) ?? []),
          // From milestone links with their initiatives
          ...(activityData.mitem.supportsMilestoneLinks?.map((msLink) => ({
            initiativeId: msLink.data.metadata.supportsInitiatives[0]?.id,
            milestoneId: msLink.data.id,
          })) ?? []),
        ] as { initiativeId: string; milestoneId?: string }[],
        supportMigId: activityData.mitem.supportedMigs?.[0]?.id,
      },
      initialEntities: {
        initiatives: uniqueInitiatives,
        milestones: activityData.mitem.supportsMilestoneLinks.map(
          (m) => m.data ?? []
        ),
        owner: activityData.mitem.owner2,
        tags: activityData.mitem.tags,
      },
    };
  }, [activityData]);

  const [editActivity, { loading: editPending }] = useMutation(
    EditActivityDocument,
    {
      onCompleted: () => {
        form.resetFields();
        onSuccess?.();
      },
    }
  );

  const { features } = useTenantDetails();

  const handleSubmit = (values: unknown) => {
    const result = activityFormSchema.safeParse(values);
    if (!result.success) {
      // Convert Zod errors to Ant Design form errors
      const formErrors = result.error.issues.map((issue) => ({
        name: issue.path,
        errors: [issue.message],
      }));
      form.setFields(formErrors);
      return;
    }

    const { alignments, tags } = result.data;

    const initiativeIds = alignments
      .filter((a) => !a.milestoneId)
      .map((a) => a.initiativeId)
      .filter((a) => typeof a === 'string');

    const milestoneIds = alignments
      .map((a) => a.milestoneId)
      .filter((a) => typeof a === 'string');

    // Only send alignments and tags for completed activities
    const skaToSubmit: SprintKaInput = isCompleted
      ? {
          supportsInitiative2Ids: initiativeIds,
          supportsMilestoneIds: milestoneIds,
          tags,
        }
      : {
          name: result.data.name,
          definitionOfDone: result.data.definitionOfDone,
          ownerId: result.data.ownerId,
          tags,
          deadline: standardDateFormat(result.data.deadline),
          supportsInitiative2Ids: initiativeIds,
          supportsMilestoneIds: milestoneIds,
          supportsMigIds: result.data.supportMigId
            ? [{ id: result.data.supportMigId }]
            : [],
        };

    editActivity({
      variables: {
        teamId,
        activityId,
        sprintKeyActivity: skaToSubmit,
      },
      refetchQueries,
    });
  };

  return (
    <div>
      <Form
        form={form}
        autoComplete="off"
        requiredMark={false}
        name="edit_activity_form"
        onFinish={handleSubmit}
        colon={false}
        initialValues={mapActivityToFormValues}
      >
        <Form.Item
          name="name"
          label={<TitleIcon className="font-size--lg txt--secondary" />}
          required
          rules={skaValidationRules.name}
          data-intercom-target="Sprint Key Activity Form Name Input"
        >
          <Input
            autoComplete="off"
            autoFocus
            placeholder={t('common.title')}
            disabled={isCompleted}
          />
        </Form.Item>
        {features.tenantInitiativesEnabled && (
          <Form.List name="alignments">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key} className="flx">
                    <Form.Item
                      key={`${field.key}-initiative`}
                      label={
                        <AlignmentIcon className="font-size--lg txt--secondary" />
                      }
                      name={[field.name, 'initiativeId']}
                      className="mr--l flx--1"
                    >
                      <InitiativeSelectFormItem
                        teamId={teamId}
                        alignedInitiatives={initialEntities?.initiatives}
                        onChange={() => {
                          const alignments = form.getFieldValue('alignments');
                          alignments[index].milestoneId = undefined;
                          form.setFieldsValue({ alignments });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      key={`${field.key}-milestone`}
                      label={
                        <FlagOutlined className="font-size--lg txt--secondary" />
                      }
                      className="flx--1 mr"
                      name={[field.name, 'milestoneId']}
                    >
                      <MilestoneSelectFormItem
                        teamId={teamId}
                        alignedMilestones={initialEntities?.milestones}
                        selectedInitiativeId={form.getFieldValue([
                          'alignments',
                          index,
                          'initiativeId',
                        ])}
                      />
                    </Form.Item>
                    <Form.Item key={`${field.key}-remove`} label={null}>
                      {fields.length > 1 && (
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                          className="txt--error"
                        />
                      )}
                    </Form.Item>
                  </div>
                ))}

                <Form.Item>
                  <Btn
                    type="link"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    {t('EditActivity.addAnotherInitiativeAlignment')}
                  </Btn>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}
        <div className="flx flx--gap">
          <Form.Item
            name="deadline"
            label={<EndingPointIcon className="font-size--lg txt--secondary" />}
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DeadlinePickerFormItem teamId={teamId} disabled={isCompleted} />
          </Form.Item>

          <Form.Item
            name="ownerId"
            label={
              <PersonSearchIcon className="font-size--lg txt--secondary" />
            }
            className="flx--1"
            required
            rules={skaValidationRules.ownerId}
            data-intercom-target="Sprint Key Activity Form Owner Input"
          >
            <MemberSelectFormItem
              teamId={teamId}
              initialOwner={initialEntities?.owner}
              disabled={isCompleted}
            />
          </Form.Item>
        </div>

        <Form.Item
          name="definitionOfDone"
          label={<TextAreaIconIcon className="font-size--lg txt--secondary" />}
          rules={skaValidationRules.definitionOfDone}
          data-intercom-target="Sprint Key Activity Form Definition of Done Input"
        >
          <Input.TextArea
            placeholder={t('common.definitionOfDone')}
            autoSize={{ minRows: 2, maxRows: 10 }}
            maxLength={1024}
            showCount
            disabled={isCompleted}
          />
        </Form.Item>
        <Divider className="mb--l" />
        <div className="flx flx--gap">
          <Form.Item
            className="flx--1"
            label={<TargetIcon className="font-size--lg txt--secondary" />}
            name="supportMigId"
          >
            <MigSelectFormItem teamId={teamId} disabled={isCompleted} />
          </Form.Item>
          <Form.Item
            name="tags"
            className="flx--1"
            label={<TeamTagIcon className="font-size--lg txt--secondary" />}
          >
            <TagsSelector teamId={teamId} />
          </Form.Item>
        </div>
        <Form.Item label={null}>
          <div className="flx flx--column">
            {isCompleted && (
              <Alert
                className="mb"
                message={t('EditActivity.activityCompletedTitle')}
                description={t('EditActivity.activityCompletedDescription')}
                type="info"
                showIcon
              />
            )}
            <div className="flx flx--jc-flx-end borderTop pt--l flx--ai-center mt--xl">
              <Btn type="primary" htmlType="submit" loading={editPending}>
                {t('EditActivity.updateActivityButton')}
              </Btn>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

const dayjsSchema = z.custom<Dayjs>(
  (data) => data && typeof data.isValid === 'function' && data.isValid(),
  'Invalid date'
);

// Alignment schema
const alignmentSchema = z.object({
  initiativeId: z.string().optional(),
  milestoneId: z.string().optional(),
});

// Main form schema
export const activityFormSchema = z.object({
  name: z.string().min(1, 'Title is required'),
  alignments: z.array(alignmentSchema),
  deadline: dayjsSchema,
  ownerId: z.string().min(1, 'Owner is required'),
  definitionOfDone: z.string().max(1024).optional(),
  supportMigId: z.string().optional(),
  tags: z.array(z.string()).optional(),
});

export type ActivityFullFormValues = Partial<
  z.infer<typeof activityFormSchema>
>;

export type ActivityInitialValues = Partial<
  RecursiveOmit<ActivityFullForm_MitemFragment, '__typename'>
>;

export const EDIT_MITEM_V2 = gql`
  mutation editActivity(
    $teamId: ID!
    $activityId: ID!
    $sprintKeyActivity: SprintKaInput!
  ) {
    editSprintKa(
      teamId: $teamId
      sprintKaId: $activityId
      sprintKaData: $sprintKeyActivity
    ) {
      ...EditActivityForm_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GET_ACTIVITY_FOR_EDIT = gql`
  query GetActivityForEdit($teamId: ID!, $activityId: ID!) {
    mitem(teamId: $teamId, mitemId: $activityId) {
      ...EditActivityForm_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_FORM_MITEM = gql`
  fragment EditActivityForm_Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportsMilestoneLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        domainId {
          itemId
          tenantId
        }
        name
        deadlineAt
        metadata {
          completedAt
          archived
          supportsInitiatives {
            id
            domainId {
              itemId
            }
            data {
              id
              name
              description
              tag {
                title
                iconId
                colorCode
              }
              metadata {
                completedAt
                status
                archived
              }
            }
          }
        }
      }
    }
    supportsInitiativeLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        id
        name
        description
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          status
          archived
        }
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner2 {
      id
      domainId {
        itemId
      }
      email
      initials
      name
      displayName
      archived
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    teamId
  }
`;
