import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetAssignedMilestonesForReportDrawerDocument,
  MilestoneStatus,
} from '../../../../generated/graphql';
import { Empty } from 'antd';
import { FlagOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { stringSort } from '../../../../services/stringSort';
import { MilestoneSectionSkeleton } from './MilestoneSection.Skeleton';
import { AnimatePresence, motion } from 'motion/react';
import { MilestoneCollapse } from './MilestoneSection/MilestoneCollapse';

export const MilestoneSection = () => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(
    GetAssignedMilestonesForReportDrawerDocument,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
    }
  );

  const milestonesSortedByDeadline =
    data.assignedInitiativesAndMilestones2.milestones
      .filter((ms) => ms.metadata.status !== MilestoneStatus.COMPLETED)
      .toSorted((a, b) => stringSort(a.deadlineAt, b.deadlineAt));

  return (
    <div>
      <div className="flx">
        <div>
          <FlagOutlined className="TeamSectionV2__teamIcon" />
        </div>
        <div className="flx--1" style={{ contain: 'paint' }}>
          <h3 className="mb">{t('MilestoneSection.title')}</h3>
          {milestonesSortedByDeadline.length === 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('MilestoneSection.noMilestones')}
            ></Empty>
          )}

          <AnimatePresence initial={false}>
            {milestonesSortedByDeadline.map((milestone) => (
              <motion.div
                key={milestone.id}
                exit={{ opacity: 0, height: 0, transform: 'translateX(100%)' }}
                transition={{
                  default: { duration: 0.6, delay: 0.8, ease: 'anticipate' },
                  height: { duration: 0.3, delay: 1.1 },
                }}
              >
                <MilestoneCollapse milestone={milestone} />
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

MilestoneSection.Skeleton = MilestoneSectionSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_SECTION__QUERY = gql`
  query getAssignedMilestonesForReportDrawer {
    assignedInitiativesAndMilestones2 {
      milestones {
        id
        domainId {
          itemId
        }
        name
        deadlineAt
        metadata {
          status
        }
      }
    }
  }
`;
