import { useQuery, gql } from '@apollo/client';
import { Alert, Empty } from 'antd';
import { Helmet } from 'react-helmet';
import { GetTeamsForAccelerationBoardDocument } from '../../../generated/graphql';
import { stringSort } from '../../../services/stringSort';
import { useTranslation } from 'react-i18next';
import { TeamTree } from './components/teamTree/TeamTree';

export const AccelerationBoardPage = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery(
    GetTeamsForAccelerationBoardDocument
  );

  if (loading || !data) {
    return null;
  }

  if (error) {
    return (
      <div>
        <Alert
          type="error"
          message={t('AccelerationBoardPage.failedToLoadTeamsError')}
        />
      </div>
    );
  }

  const tenantName = data?.tenant?.name ?? '';
  const teams = data.teams2.teams ?? [];

  return (
    <>
      <Helmet title={'Acceleration Board'} />

      {teams.length === 0 ? (
        <div className="center-content">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      ) : (
        <TeamTree
          teams={teams.sort((a, b) => stringSort(a.name, b.name))}
          tenantName={tenantName}
        />
      )}
    </>
  );
};

// this is used indirectly - `GetTeamsForAccelerationBoardDocument` is generated from it
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAMS = gql`
  query getTeamsForAccelerationBoard {
    teams2 {
      teams {
        name
        id
        domainId {
          itemId
        }
        umbrellaTeam
        parentTeam {
          id
          domainId {
            itemId
          }
        }
      }
    }
    tenant {
      id
      name
    }
  }
`;
