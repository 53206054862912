import './SectionHeader.less';
interface Props {
  title?: string | React.ReactNode;
  extra?: string | React.ReactNode;
  children?: React.ReactNode;
}

export const SectionHeader = ({ title, extra, children }: Props) => {
  return (
    <div>
      <span className="SectionHeader">
        <div className="SectionHeader__title">{title}</div>

        <div className="flx--jc-flx-end flx flx--1">{extra}</div>
      </span>
      {children}
    </div>
  );
};
