import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getUnique } from '../../../../services/getUnique';
import { friendlyUsername } from '../../../../services/friendlyUsername';

type Owner = {
  id: string;
  domainId: {
    itemId: string;
  };
  email: string;
  name: string | null;
  displayName: string | null;
};
interface Props {
  owners: Owner[];
  value: string | null;
  onChange: (ownerId: string | null) => void;
}

export const OwnerFilter = ({ owners, value, onChange }: Props) => {
  const { t } = useTranslation();

  const uniqueOwners = getUnique(owners);

  return (
    <div className="flx--1">
      <h5>{t('common.owner')}</h5>
      <Select
        allowClear
        onClear={() => onChange(null)}
        popupMatchSelectWidth={false}
        value={value}
        onChange={onChange}
        className="d-block"
        placeholder={t('OwnerFilter.AllOwners')}
        options={uniqueOwners.map((o) => ({
          label: friendlyUsername(o),
          value: o.domainId.itemId,
        }))}
      />
    </div>
  );
};
