import { Input, Form, Checkbox } from 'antd';
import './CreateActivity.less';
import { skaValidationRules } from '../../../../appPages/team/common/utils/useSkaValidationRules';
import { useTranslation } from 'react-i18next';
import { gql, PureQueryOptions, useMutation } from '@apollo/client';
import {
  CreateSimpleActivityDocument,
  SprintKaInput,
} from '../../../../generated/graphql';
import { TitleIcon } from '../../../initiatives/InitiativeForm/Icons/TitleIcon';
import { AlignmentIcon } from '../../../initiatives/InitiativeForm/Icons/AlignmentIcon';
import { useTenantDetails } from '../../../../hooks/useTenantDetails';
import { FlagOutlined } from '@ant-design/icons';
import { EndingPointIcon } from '../../../initiatives/InitiativeForm/Icons/EndingPointIcon';
import { PersonSearchIcon } from '../../../initiatives/InitiativeForm/Icons/PersonSearchIcon';
import { TextAreaIconIcon } from '../../../initiatives/InitiativeForm/Icons/TextAreaIcon';
import { Btn } from '../../../Button';
import { standardDateFormat } from '../../../../services/dateFormats';
import { useState } from 'react';
import { useModal } from '../../../../ModalProvider';
import { MilestoneTagSelectFormItem } from './formItems/MilestoneTagSelectFormItem';
import { ActivityFullFormValues } from './CreateActivityFullVersion';
import { zDayjs } from '../../../../services/zodCustomTypes';
import { z } from 'zod';
import { MemberSelectFormItem } from './formItems/MemberSelectFormItem';
import { DeadlinePickerFormItem } from './formItems/DeadlinePickerFormItem';
import { InitiativeTagSelectFormItem } from './formItems/InitiativeTagSelectFormItem';

interface Props {
  initialValues?: SimpleActivityFormValues;
  refetchQueries?: Array<string | PureQueryOptions>;
  submitPending?: boolean;
  onSuccess?: () => void;
  setIsFormDirty?: (dirty: boolean) => void;
  isEdit?: boolean;
  teamId: string;
}

export const CreateActivitySimpleVersion = ({
  teamId,
  initialValues,
  onSuccess,
  refetchQueries,
}: Props) => {
  const { openModal } = useModal();
  const [keepOpen, setKeepOpen] = useState(false);
  const [form] = Form.useForm();

  const [createActivitySimpleVersion, { loading: createPending }] = useMutation(
    CreateSimpleActivityDocument,
    {
      update(cache, { data }) {
        if (!data?.createSprintKeyActivity) return;

        cache.modify({
          id: `TeamActivitiesResponse:${teamId}`, // The cache ID of the TeamActivitiesResponse
          fields: {
            sprintKeyActivities(existingActivities = []) {
              const newActivityRef = cache.writeFragment({
                data: data.createSprintKeyActivity,
                fragment: gql`
                  fragment NewActivitySimple on Mitem {
                    id
                  }
                `,
              });

              return [...existingActivities, newActivityRef];
            },
          },
        });
      },
      onCompleted: () => {
        form.resetFields();
        if (!keepOpen) {
          onSuccess?.();
        }
      },
    }
  );

  const { t } = useTranslation();
  const { features } = useTenantDetails();

  const selectedInitiative = Form.useWatch('supportsInitiativeId', form);

  const handleSubmit = (values: unknown) => {
    const result = simpleActivitySchema.safeParse(values);

    if (!result.success) {
      // Convert Zod errors to Ant Design form errors
      const formErrors = result.error.issues.map((issue) => ({
        name: issue.path,
        errors: [issue.message],
      }));
      form.setFields(formErrors);
      return;
    }

    const { supportsInitiativeId, supportsMilestoneId, ...rest } = result.data;
    const supportsMilestoneIdsToBeSubmitted = supportsMilestoneId;
    const supportsInitiativeIdsToBeSubmitted =
      supportsMilestoneIdsToBeSubmitted == null ? supportsInitiativeId : null;

    const skaToSubmit: SprintKaInput = {
      ...rest,
      deadline: standardDateFormat(result.data.deadline),
      supportsMilestoneIds: supportsMilestoneIdsToBeSubmitted
        ? [supportsMilestoneIdsToBeSubmitted]
        : [],
    };

    if (features.tenantInitiativesEnabled) {
      skaToSubmit.supportsInitiative2Ids = supportsInitiativeIdsToBeSubmitted
        ? [supportsInitiativeIdsToBeSubmitted]
        : [];
    }

    createActivitySimpleVersion({
      variables: {
        teamId,
        sprintKeyActivity: skaToSubmit,
      },
      refetchQueries,
    });
  };

  const mapToFullFormValues = (values: any): ActivityFullFormValues => {
    return {
      name: values.name,
      alignments: [
        {
          initiativeId: values.supportsInitiativeId,
          milestoneId: values.supportsMilestoneId,
        },
      ],
      deadline: values.deadline,
      ownerId: values.ownerId,
      definitionOfDone: values.definitionOfDone,
    };
  };

  return (
    <div>
      <Form
        form={form}
        autoComplete="off"
        initialValues={initialValues}
        requiredMark={false}
        name="create_activity_form"
        onFinish={(values) => handleSubmit(values)}
        colon={false}
      >
        <Form.Item
          name="name"
          label={<TitleIcon className="font-size--lg txt--secondary" />}
          required
          rules={skaValidationRules.name}
          data-intercom-target="Sprint Key Activity Form Name Input"
        >
          <Input autoComplete="off" autoFocus placeholder={t('common.title')} />
        </Form.Item>

        {features.tenantInitiativesEnabled && (
          <Form.Item
            name="supportsInitiativeId"
            label={<AlignmentIcon className="font-size--lg txt--secondary" />}
          >
            <InitiativeTagSelectFormItem
              teamId={teamId}
              onChange={() =>
                form.setFieldValue('supportsMilestoneId', undefined)
              }
            />
          </Form.Item>
        )}

        <Form.Item
          label={<FlagOutlined className="font-size--lg txt--secondary" />}
          name="supportsMilestoneId"
        >
          <MilestoneTagSelectFormItem
            teamId={teamId}
            selectedInitiativeId={selectedInitiative}
          />
        </Form.Item>

        <div className="flx flx--gap">
          <Form.Item
            name="deadline"
            label={<EndingPointIcon className="font-size--lg txt--secondary" />}
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DeadlinePickerFormItem teamId={teamId} />
          </Form.Item>

          <Form.Item
            name="ownerId"
            label={
              <PersonSearchIcon className="font-size--lg txt--secondary" />
            }
            className="flx--1"
            required
            rules={skaValidationRules.ownerId}
            data-intercom-target="Sprint Key Activity Form Owner Input"
          >
            <MemberSelectFormItem teamId={teamId} />
          </Form.Item>
        </div>

        <Form.Item
          name="definitionOfDone"
          label={<TextAreaIconIcon className="font-size--lg txt--secondary" />}
          rules={skaValidationRules.definitionOfDone}
          data-intercom-target="Sprint Key Activity Form Definition of Done Input"
        >
          <Input.TextArea
            placeholder={t('common.definitionOfDone')}
            autoSize={{ minRows: 2, maxRows: 10 }}
            maxLength={1024}
            showCount
          />
        </Form.Item>
        <Form.Item label={null}>
          <div className="flx flx--jc-space-between borderTop pt--l flx--ai-center mt--xl">
            <Checkbox
              checked={keepOpen}
              onChange={(e) => setKeepOpen(e.target.checked)}
            >
              Keep open
            </Checkbox>
            <div>
              <Btn
                type="link"
                onClick={() =>
                  openModal({
                    type: 'createActivity',
                    teamId,
                    formType: {
                      type: 'full',
                      prefilledValues: mapToFullFormValues(
                        form.getFieldsValue()
                      ),
                    },
                  })
                }
              >
                More options
              </Btn>
              <Btn type="primary" htmlType="submit" loading={createPending}>
                Create Activity
              </Btn>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

// Custom Dayjs validation
const simpleActivitySchema = z.object({
  name: z.string().min(1, 'Title is required'),
  supportsInitiativeId: z.string().optional(),
  supportsMilestoneId: z.string().optional(),
  deadline: zDayjs,
  ownerId: z.string().min(1, 'Owner is required'),
  definitionOfDone: z.string().max(1024).optional(),
  tags: z.array(z.string()).optional(),
});

export type SimpleActivityFormValues = Partial<
  z.infer<typeof simpleActivitySchema>
>;

export const CREATE_MITEM_V2 = gql`
  mutation createSimpleActivity(
    $teamId: ID!
    $sprintKeyActivity: SprintKaInput!
  ) {
    createSprintKeyActivity(
      teamId: $teamId
      sprintKeyActivity: $sprintKeyActivity
    ) {
      ...ActivitySimpleForm_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_FORM_MITEM = gql`
  fragment ActivitySimpleForm_Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportsMilestoneLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        domainId {
          itemId
          tenantId
        }
        name
        deadlineAt
        metadata {
          completedAt
          archived
          supportsInitiatives {
            id
            domainId {
              itemId
            }
            data {
              id
              domainId {
                itemId
              }
              name
              tag {
                title
                iconId
                colorCode
              }
            }
          }
        }
      }
    }
    supportsInitiativeLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        name
        description
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          status
          archived
        }
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner2 {
      id
      domainId {
        itemId
      }
      email
      initials
      name
      displayName
      archived
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    teamId
  }
`;
