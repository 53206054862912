import { stringSort } from '../services/stringSort';
import { useMe } from './useMe';

export const useMyTeams = () => {
  const { data, loading, error } = useMe();

  const teams = data?.currentUser.memberOf.map((m) => m.team.data) ?? [];

  // we shallow clone `teams` to prevent the sort from mutating `teamAssociations` in the gql cache
  const sortedTeams = teams.sort((a, b) => {
    return stringSort(a.name, b.name);
  });

  return {
    loading,
    error,
    teams: sortedTeams,
  };
};
