import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { TeamInitiativesForInitiativeFilterDocument } from '../../../../generated/graphql';
import { InitiativeTag } from '../../../../components/initiative/InitiativeTag';

interface Props {
  value: string | null;
  onChange: (initiativeId: string | null) => void;
  teamId: string;
}

export const InitiativeFilter = ({ teamId, value, onChange }: Props) => {
  const { data, loading } = useQuery(
    TeamInitiativesForInitiativeFilterDocument,
    {
      variables: {
        teamId,
      },
    }
  );
  const { t } = useTranslation();

  const initiatives = data?.initiativesLinkedToTeam?.initiatives ?? [];

  return (
    <div className="flx--1">
      <h5>{t('common.initiativeTitle')}</h5>
      <Select
        loading={loading}
        allowClear
        onClear={() => onChange(null)}
        popupMatchSelectWidth={false}
        value={value}
        onChange={onChange}
        placeholder={t('InitiativeFilter.allInitiatives')}
        className="d-block"
        options={initiatives.map((o) => ({
          label: <InitiativeTag tag={o.tag} />,
          value: o.domainId.itemId,
        }))}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_FILTER_QUERY = gql`
  query TeamInitiativesForInitiativeFilter($teamId: ID!) {
    initiativesLinkedToTeam(teamId: $teamId) {
      initiatives {
        id
        domainId {
          itemId
        }
        name
        tag {
          title
          colorCode
          iconId
        }
      }
    }
  }
`;
