import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { PureQueryOptions, gql } from '@apollo/client';
import { Popconfirm, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorAlert } from '../../../../../components/ErrorAlert';
import {
  MitemStatus,
  SprintMitemTableActions_MitemFragment,
} from '../../../../../generated/graphql';
import { ArchiveOutlinedIcon } from '../../../../../icons/ArchiveIcon';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { useMitemArchivation } from '../../common/services/useMitemArchivation';
import { CopySprintKeyActivityDrawer } from '../../planning/components/mitemListEntry/CopySprintKeyActivityDrawer';
import { EditSprintKeyActivityDrawer } from '../../planning/components/mitemListEntry/EditSprintKeyActivityDrawer';
import { Btn } from '../../../../../components/Button';

interface Props {
  mitem: SprintMitemTableActions_MitemFragment;
  teamId: string;
  isLockedSprint: boolean;
  isAllowedToArchive: boolean;
  editable: boolean;
  refetchQueries?: Array<string | PureQueryOptions>;
  onEdited?: (sprintKaId: string, ownerId: string) => void;
  onCopied?: (sprintKaId: string, ownerId: string) => void;
  onSprintKeyActivityArchived?: (
    mitem: SprintMitemTableActions_MitemFragment
  ) => void;
}

export const SprintMitemTableActions = ({
  mitem,
  isLockedSprint,
  refetchQueries,
  isAllowedToArchive,
  editable,
  onEdited,
  onCopied,
  onSprintKeyActivityArchived,
}: Props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCopyModal, setShowCopyModal] = useState(false);

  const { t } = useTranslation();

  const [archiveMitem, { loading, error }] = useMitemArchivation({
    refetchQueries,
    onCompleted(data) {
      onSprintKeyActivityArchived?.(mitem);
      showNotification('success', {
        message: data.editSprintKa.archived
          ? t('MitemListEntry.archiveSuccess')
          : t('MitemListEntry.reactivateSuccess'),
      });
    },
  });

  const archive = {
    icon: isLockedSprint ? <ArchiveOutlinedIcon /> : <DeleteOutlined />,
    tooltipTitle: isLockedSprint
      ? t('MitemListEntry.archiveMitem')
      : t('MitemListEntry.deleteMitem'),
    confirmTitle: isLockedSprint
      ? t('MitemListEntry.confirmArchive.title.archive')
      : t('MitemListEntry.confirmArchive.title.remove'),
  };

  return (
    <div className="flx" onClick={(event) => event.stopPropagation()}>
      <Tooltip
        placement="top"
        title={t('MitemListEntry.copySka')}
        mouseEnterDelay={0.7}
      >
        <Btn
          className="txt--secondary SprintMitemTableActions__action"
          onClick={() => {
            setShowCopyModal(true);
          }}
          type="text"
          icon={<CopyOutlined />}
        />
      </Tooltip>
      {isAllowedToArchive && mitem.status !== MitemStatus.ARCHIVED && (
        <Popconfirm
          title={archive.confirmTitle}
          okText={t('MitemListEntry.confirmArchive.confirm')}
          onConfirm={() => archiveMitem(mitem.teamId, mitem.id)}
          cancelText={t('MitemListEntry.confirmArchive.cancel')}
          disabled={loading}
        >
          <Tooltip
            placement="top"
            title={archive.tooltipTitle}
            mouseEnterDelay={0.4}
          >
            <Btn
              className="txt--secondary SprintMitemTableActions__action"
              type="text"
              icon={archive.icon}
            />
          </Tooltip>
        </Popconfirm>
      )}
      {isAllowedToArchive && mitem.status === MitemStatus.ARCHIVED && (
        <Tooltip
          placement="top"
          title={t('MitemListEntry.reactivateMitem')}
          mouseEnterDelay={0.7}
        >
          <Btn
            className="txt--secondary SprintMitemTableActions__action SprintMitemTableActions__action--reactivate"
            onClick={() => {
              archiveMitem(mitem.teamId, mitem.id, { archive: false });
            }}
            type="text"
            icon={<UploadOutlined />}
          />
        </Tooltip>
      )}
      {editable && (
        <Tooltip
          placement="top"
          title={t('MitemListEntry.editMitem')}
          mouseEnterDelay={0.7}
        >
          <Btn
            className="txt--secondary SprintMitemTableActions__action"
            onClick={() => {
              setShowEditModal(true);
            }}
            type="text"
            icon={<EditOutlined />}
          />
        </Tooltip>
      )}

      <CopySprintKeyActivityDrawer
        mitem={mitem}
        showDrawer={showCopyModal}
        refetchQueries={refetchQueries}
        onCompleted={(data) => {
          onCopied?.(data.id, data.owner2.domainId.itemId);
          setShowCopyModal(false);
        }}
        onCancel={() => setShowCopyModal(false)}
      />
      <EditSprintKeyActivityDrawer
        mitem={mitem}
        showModal={showEditModal}
        refetchQueries={refetchQueries}
        onCompleted={(data) => {
          onEdited?.(data.id, data.owner2.domainId.itemId);
          setShowEditModal(false);
        }}
        onCancel={() => {
          setShowEditModal(false);
        }}
      />
      {error && <ErrorAlert error={error} title={t('common.fetchError')} />}
    </div>
  );
};

export const SPRINT_MITEM_TABLE_ACTIONS_FRAGMENT = gql`
  fragment SprintMitemTableActions_Mitem on Mitem {
    id
    teamId
    status
    owner2 {
      domainId {
        itemId
      }
    }
    ...CopySprintKeyActivityDrawer_Mitem
    ...EditSprintKeyActivityDrawer__Mitem
  }
`;
