import { DatePicker } from 'antd';

import { Dayjs } from 'dayjs';
import { useAccelerationDay } from '../../../../../hooks/useAccelerationDay';
import { friendlyDate } from '../../../../../services/dateFormats';
import { isEndOfPeriod } from '../../../../../services/accelerationDayHelpers';

type Props = {
  teamId: string;
  value?: Dayjs;
  onChange?: (value: Dayjs) => void;
  disabled?: boolean;
};

export const DeadlinePickerFormItem = ({
  teamId,
  value,
  onChange,
  disabled,
}: Props) => {
  const { data: accelerationDay, loading } = useAccelerationDay(teamId);
  return (
    <DatePicker
      key={teamId}
      disabled={loading || disabled}
      value={value}
      onChange={onChange}
      data-testid="sprint-datepicker"
      format={(date) => friendlyDate(date)}
      allowClear={false}
      disabledDate={(date) =>
        !(accelerationDay && isEndOfPeriod(date, accelerationDay))
      }
    />
  );
};
