import { useTranslation } from 'react-i18next';
import { getUnique } from '../../../../services/getUnique';
import { Select } from 'antd';

interface Props {}

type FilterMig = {
  id: string;
  name: string;
  domainId: {
    itemId: string;
  };
};

interface Props {
  migs: FilterMig[];
  value: string | null;
  onChange: (migId: string | null) => void;
}

export const MigFilter = ({ migs, value, onChange }: Props) => {
  const { t } = useTranslation();
  const uniqueMigs = getUnique(migs);

  return (
    <div className="flx--1">
      <h5>{t('common.mostImportantGoal')}</h5>
      <Select
        allowClear
        onClear={() => onChange(null)}
        popupMatchSelectWidth={false}
        value={value}
        onChange={onChange}
        className="d-block"
        placeholder={t('MigFilter.AllMigs')}
        options={uniqueMigs.map((o) => ({
          label: o.name,
          value: o.domainId.itemId,
        }))}
      />
    </div>
  );
};
