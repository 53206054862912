import { Auth0Client } from '@auth0/auth0-spa-js';
import { Typography } from 'antd';
import './VerifyEmail.less';
import customerSuccess from './images/CustomerSuccess.jpg';

interface Props {
  auth0Client: Auth0Client | null;
  email: string;
}

export const VerifyEmail = ({ email }: Props) => {
  return (
    <div className="VerifyEmail">
      <div className="VerifyEmail__topSection VerfyEmail__container">
        <div className="VerfyEmail__innerContainer">
          <Typography.Title
            style={{ color: 'white' }}
            className="text-c VerfyEmail__h1"
          >
            Welcome to Howwe
          </Typography.Title>

          <div className="VerfyEmail__infoText">
            Before we can begin, you need to verify your email
          </div>
          <div className="VerfyEmail__infoText" style={{ fontSize: 30 }}>
            {email}
          </div>
          <div className="mt--xl">
            <div className="VerfyEmail__infoText">
              Please check your inbox and follow the instructions in the email
              you will soon get from us. If you for any reasons haven't received
              an email from us within the hour, please feel free to contact
              customer support and we will help you.
            </div>
          </div>
        </div>
      </div>
      <div className="VerfyEmail__container">
        <div className="VerfyEmail__innerContainer flx">
          <div className="flx--1">
            <h1>Customer success</h1>
            <div className="VerfyEmail__infoText--blue">
              Meet our Customer Success team! We're here to support you every
              step of the way—during your onboarding journey and beyond.
            </div>
            <div className="mt--xl VerfyEmail__infoText--blue">
              You can easily reach our support team anytime via email at
              <a href="mailto: support@howwe.io"> support@howwe.io </a> or
              through the chat widget on our website. We're just a click away!
            </div>
          </div>
          <div className="flx--1">
            <figure className="flx flx--jc-center">
              <img
                className="VerfyEmail__image"
                src={customerSuccess}
                alt="customerSuccess"
              />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};
