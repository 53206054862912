import { Calendar, Popover } from 'antd';
import { HeaderRender } from 'antd/es/calendar/generateCalendar';
import dayjs, { Dayjs } from 'dayjs';
import { Btn } from '../../../components/Button';
import {
  CalendarOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { useRef, useState } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';

interface Props {
  btnProps?: React.ComponentProps<typeof Btn>;
  onConfirm: (date: Dayjs) => void;
}

export const MonthQuickPicker = ({ btnProps, onConfirm }: Props) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const elemRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(elemRef, () => setOpen(false));
  return (
    <Popover
      open={open}
      content={
        <div style={{ width: '300px' }} ref={elemRef}>
          <Calendar
            value={selectedDate}
            onChange={setSelectedDate}
            fullscreen={false}
            headerRender={headerRender}
            mode="year"
          />
          <div className="flx">
            <Btn
              type="link"
              onClick={() => {
                onConfirm(selectedDate);
                setOpen(false);
              }}
              className="ml--auto"
            >
              Confirm
            </Btn>
          </div>
        </div>
      }
    >
      <Btn
        onClick={() => setOpen(true)}
        icon={<CalendarOutlined />}
        size="small"
        type="link"
        {...btnProps}
      />
    </Popover>
  );
};

const headerRender: HeaderRender<Dayjs> = (config) => {
  return (
    <div className="flx flx--jc-space-between">
      <Btn
        onClick={() => config.onChange(config.value.subtract(1, 'year'))}
        size="small"
        icon={<LeftOutlined />}
      />
      {config.value.format('YYYY')}
      <Btn
        onClick={() => config.onChange(config.value.add(1, 'year'))}
        size="small"
        icon={<RightOutlined />}
      />
    </div>
  );
};
