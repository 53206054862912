import React, { createContext, useContext, useState, useCallback } from 'react';
import { CreateMitemDrawer } from './appPages/team/sprint/planning/components/CreateMitemDrawer';
import { EditSprintKeyActivityDrawer } from './appPages/team/sprint/planning/components/mitemListEntry/EditSprintKeyActivityDrawer';
import { EditSprintKeyActivityDrawer__MitemFragment } from './generated/graphql';
import { PureQueryOptions } from '@apollo/client';
import { CreateActivityModal } from './components/activities/modals/CreateActivityModal';
import { ActivityFullFormValues } from './components/activities/modals/forms/CreateActivityFullVersion';
import { SimpleActivityFormValues } from './components/activities/modals/forms/CreateActivitySimpleVersion';
import { EditActivityModal } from './components/activities/modals/EditActivityModal';

type ModalType =
  | {
      type: 'edit';
      prefilledValues?: any;
      activity: EditSprintKeyActivityDrawer__MitemFragment;
    }
  | {
      type: 'create';
      teamId: string;
      activityId?: string;
      prefilledValues?: any;
    }
  | {
      type: 'createActivity';
      formType?:
        | {
            type: 'simple';
            prefilledValues?: SimpleActivityFormValues;
          }
        | {
            type: 'full';
            prefilledValues?: ActivityFullFormValues;
          };
      teamId: string;
      activityId?: string;
      refetchQueries?: Array<string | PureQueryOptions>;
    }
  | {
      type: 'editActivity';
      teamId: string;
      activityId: string;
      refetchQueries?: Array<string | PureQueryOptions>;
    };

interface ModalContextType {
  openModal: (modal: ModalType) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [modalState, setModalState] = useState<ModalType | null>(null);

  const openModal = useCallback((modal: ModalType) => {
    setModalState(modal);
  }, []);

  const closeModal = useCallback(() => {
    setModalState(null);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {children}
      {modalState?.type === 'create' && (
        <CreateMitemDrawer
          teamId={modalState.teamId}
          showHelpLinks={false}
          showModal={true}
          onCancel={closeModal}
          prefilledValues={modalState.prefilledValues}
          onCompleted={closeModal}
        />
      )}
      {modalState?.type === 'createActivity' && (
        <CreateActivityModal
          teamId={modalState.teamId}
          open={true}
          onCancel={closeModal}
          formType={modalState.formType}
          refetchQueries={modalState.refetchQueries}
          onComplete={closeModal}
        />
      )}
      {modalState?.type === 'editActivity' && (
        <EditActivityModal
          teamId={modalState.teamId}
          activityId={modalState.activityId}
          open={true}
          onCancel={closeModal}
          refetchQueries={modalState.refetchQueries}
          onComplete={closeModal}
        />
      )}

      {modalState?.type === 'edit' && (
        <EditSprintKeyActivityDrawer
          mitem={modalState.activity}
          showModal={true}
          onCancel={closeModal}
          //prefilledValues={modalState.prefilledValues}
          onCompleted={closeModal}
        />
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
