import { Layout, Tabs, TabsProps } from 'antd';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { MigSetupPage } from '../../../components/setup/MigSetupPage';
import { KeyActivitySetupPage } from '../../../components/setup/KeyActivitySetupPage';
import { OverviewPage } from './overviewPage/OverviewPage';

enum tabRoutes {
  KEY_ACTIVITY = 'key_activity',
  MIG = 'mig',
  OVERVIEW = 'overview',
}
interface Props {
  teamId: string;
}

export const TeamSetupRoutes = ({ teamId }: Props) => {
  const match = useRouteMatch();
  const { t } = useTranslation();
  const history = useHistory();
  const tabMatch = useRouteMatch<{ tab?: string }>(`${match.path}/:tab`);
  const activeTab = tabMatch?.params.tab;

  const items: TabsProps['items'] = [
    {
      key: tabRoutes.OVERVIEW,
      label: (
        <span data-intercom-target="Overview Setup Navigation">
          {t('TeamSetupRoutes.tabs.overview')}
        </span>
      ),
    },
    {
      key: tabRoutes.MIG,
      label: (
        <span data-intercom-target="MIG Setup Navigation">
          {t('TeamSetupRoutes.tabs.mig')}
        </span>
      ),
    },
    {
      key: tabRoutes.KEY_ACTIVITY,
      label: (
        <span data-intercom-target="Key Activity Navigation">
          {t('TeamSetupRoutes.tabs.keyActivity')}
        </span>
      ),
    },
  ];

  return (
    <>
      <Helmet title="Setup" />
      <div className="flx flx--jc-space-between mb--l">
        <div style={{ minWidth: 350 }}>
          <Tabs
            data-intercom-target="Setup Tab Navigation"
            items={items}
            activeKey={activeTab}
            onTabClick={(key) => {
              history.push(`${match.url}/${key}`);
            }}
          />
        </div>
      </div>

      <Layout.Content className="mt--xl">
        <Switch>
          <Route path={`${match.path}/${tabRoutes.OVERVIEW}`}>
            <OverviewPage teamId={teamId} />
          </Route>

          <Route path={`${match.path}/${tabRoutes.MIG}`}>
            <MigSetupPage teamId={teamId} />
          </Route>
          <Route path={`${match.path}/${tabRoutes.KEY_ACTIVITY}`}>
            <KeyActivitySetupPage teamId={teamId} />
          </Route>
        </Switch>
      </Layout.Content>
    </>
  );
};
