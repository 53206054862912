import { Card, List } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { DisplayDate } from '../../../../components/DisplayDate';
import { useTeamOffPeriods } from '../../common/hooks/useTeamOffPeriods';
import { CreateOffWeek } from './offWeeks/CreateOffWeek';
import { ViewOffWeeks } from './offWeeks/ViewOffWeeks';
import { gql, useQuery } from '@apollo/client';
import { TeamTimezoneModalWithButton } from '../../../../adminPages/company/companyList/teamList/TeamDetailsPage/components/TeamTimezoneModalWithButton';
import { GetTeamInformationDocument } from '../../../../generated/graphql';

interface Props {
  teamId: string;
}
export const TeamInformationList = ({ teamId }: Props) => {
  const teamInformationData = useQuery(GetTeamInformationDocument, {
    variables: { teamId },
  });
  const { t } = useTranslation();

  const team = teamInformationData.data?.team2;

  return (
    <Card styles={{ body: { padding: 0 } }}>
      <List size="small">
        <List.Item data-intercom-target="Team overview Acceleration day">
          <List.Item.Meta
            data-hj-suppress
            title={t('TeamOverview.accelerationDay')}
            description={t(`AccelerationDay.${team?.accelerationDay}`)}
          />
        </List.Item>
        <List.Item
          actions={[
            <CreateOffWeek key="createOffWeek" teamId={teamId} />,
            <ViewOffWeeks key="viewOffWeek" teamId={teamId} />,
          ]}
          data-intercom-target="Team off week controller"
        >
          <List.Item.Meta
            data-hj-suppress
            title={t('TeamOverview.offWeek')}
            description={<OffWeekSubTitle teamId={teamId} />}
          />
        </List.Item>
        <List.Item
          actions={
            team
              ? [
                  <div className="mr" key="timezone">
                    <TeamTimezoneModalWithButton
                      teamId={teamId}
                      teamName={team.name}
                      rev={team.rev}
                      timezone={team.timezone}
                    />
                  </div>,
                ]
              : undefined
          }
        >
          <List.Item.Meta
            title={t('TeamInformationList.timezone')}
            description={team?.timezone}
          />
        </List.Item>
      </List>
    </Card>
  );
};

interface OffWeekSubTitleProps {
  teamId: string;
}

const OffWeekSubTitle = ({ teamId }: OffWeekSubTitleProps) => {
  const { t } = useTranslation();
  const { activePeriods, upcomingPeriods } = useTeamOffPeriods(teamId);

  if (activePeriods.length > 0) {
    const activePeriod = activePeriods[0];
    return (
      <Trans i18nKey="OffWeekSubTitle.active">
        The team is off until
        <DisplayDate date={activePeriod.endDate} />
      </Trans>
    );
  }
  if (upcomingPeriods.length > 0) {
    const nextPeriod = upcomingPeriods[0];
    return (
      <Trans i18nKey="OffWeekSubTitle.upcoming">
        Next off period
        <DisplayDate date={nextPeriod.startDate} />
      </Trans>
    );
  }
  return <div>{t('OffWeekSubTitle.nothingPlanned')}</div>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_INFORMATION = gql`
  query getTeamInformation($tenantId: ID, $teamId: ID!) {
    team2(tenantId: $tenantId, teamId: $teamId) {
      name
      id
      domainId {
        itemId
        tenantId
      }
      rev
      timezone
      accelerationDay
    }
  }
`;
