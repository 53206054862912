import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GetCompanyMigsForReportDocument } from '../../../../generated/graphql';
import { CompanyMigReport } from './CompanyMigReport';
import { Divider, Empty } from 'antd';
import { ReportSection } from '../ReportSection';

interface Props {
  isPreviewMode: boolean;
}

export const CompanyMigReportSection = ({ isPreviewMode }: Props) => {
  const { t } = useTranslation();
  const { data } = useQuery(GetCompanyMigsForReportDocument);

  const globalMigs = data?.globalMigs.globalMigs || [];

  return (
    <ReportSection
      isPreviewMode={isPreviewMode}
      pageBreak={true}
      title={t('CompanyMigReportSection.title')}
      subTitle={t('CompanyMigReportSection.subTitle')}
    >
      {globalMigs.map((mig, index) => [
        <div key={mig.id} className={'mb--l'}>
          <CompanyMigReport mig={mig} isPreviewMode={isPreviewMode} />
          {index < globalMigs.length - 1 && (
            <div className="exclude-from-pdf fullWidth">
              <Divider />
            </div>
          )}
        </div>,
      ])}
      {globalMigs.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ marginTop: '50px' }}
          description={t('CompanyMigReportSection.noData')}
        />
      )}
    </ReportSection>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_GLOBAL_MIGS = gql`
  query getCompanyMigsForReport {
    globalMigs {
      globalMigs {
        id
        ...CompanyMigReport_MigV2
      }
    }
  }
`;
