import { useRef, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import './NewVersionBanner.less';
import { envConf } from '../../envConf';
import * as sentry from '@sentry/react';

const POLL_INTERVAL = 2 * 60 * 1000;
const IS_LOCAL_DEVELOPMENT = import.meta.env.MODE !== 'production';
let hasReportedErrorToSentry = false;

export const NewVersionBanner = () => {
  const [newVersion, setNewVersion] = useState(false);
  const pollInterval = IS_LOCAL_DEVELOPMENT ? null : POLL_INTERVAL;

  useInterval(() => {
    if (getIsDocumentVisible()) {
      // only send requests when tab is visible to reduce server and logging load
      fetch('/version')
        .then((res) => res.json())
        .then((res) => {
          if (res.commitSHA !== envConf.COMMIT_SHA) {
            setNewVersion(true);
          }
        })
        .catch((e) => {
          if (!hasReportedErrorToSentry) {
            sentry.captureException(e, { level: 'warning' });
          }
          hasReportedErrorToSentry = true;
        });
    }
  }, pollInterval);

  if (!newVersion) return null;

  return (
    <div className="NewVersionBanner pa">
      <Trans i18nKey="NewVersionBanner.message">
        There's a new version of Howwe. Please
        <span
          role="button"
          className="NewVersionBanner__link"
          onClick={() => window.location.reload()}
        >
          refresh
        </span>
        the page to start using the latest version."
      </Trans>
    </div>
  );
};

function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback?.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof (document as any).msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof (document as any).webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
}

function getIsDocumentVisible() {
  const documentHiddenProp = getBrowserDocumentHiddenProp();
  if (!documentHiddenProp) {
    // if the browser doesn't support the Page Visibility API just assume that the page is always visible
    return true;
  }
  return !(document as any)[documentHiddenProp];
}
