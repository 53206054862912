import { gql, useQuery } from '@apollo/client';
import { GetSkaRelationshipDocument } from '../../../../../generated/graphql';

export const useMitemRelationshipQuery = (teamId: string) => {
  const relationshipDataReq = useQuery(GetSkaRelationshipDocument, {
    variables: { teamId },
  });

  const migs = relationshipDataReq.data?.teamMigs.migs ?? [];
  const accelerationDay = relationshipDataReq.data?.team2?.accelerationDay;

  return {
    data: {
      migs,
      accelerationDay,
    },
    loading: relationshipDataReq.loading,
    error: relationshipDataReq.error,
  };
};

export const GET_SKA_RELATIONSHIP_DATA = gql`
  query getSkaRelationship($teamId: ID!) {
    team2(teamId: $teamId) {
      id
      domainId {
        itemId
      }
      accelerationDay
    }
    teamMigs(teamId: $teamId, statusFilters: [ACTIVE]) {
      migs {
        id
        name
        domainId {
          itemId
          teamId
        }
      }
    }
  }
`;
