import { Alert, Divider } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import HowweLogo from '../Howwe_Logo.png';

interface Props {
  tenantName?: string;
}

export const ReportStartPage = ({ tenantName }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="pdf-center">
      <div className="center-content pdf-center">
        <h1>{t('ReportStartPage.title')}</h1>
        <h4 className="txt--secondary mb">{`${tenantName} | ${dayjs().format('YYYY-MM-DD')}`}</h4>
        <div className="AppLogo--poweredBy">
          {t('ReportStartPage.poweredBy')} <img src={HowweLogo} alt={'Howwe'} />
        </div>
      </div>
      <div className="exclude-from-pdf">
        <Alert
          type="info"
          showIcon
          className="mt--l"
          message={t('ReportStartPage.pdfAlert')}
        />
      </div>
      <div className="exclude-from-pdf fullWidth">
        <Divider />
      </div>
    </div>
  );
};
