import './BaseCard.less';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  ref?: React.Ref<HTMLDivElement>;
}

export const BaseCard = ({
  children,
  className = '',
  onClick,
  ref,
  ...elementAttr
}: Props) => {
  return (
    <div
      onClick={onClick}
      ref={ref}
      className={`card BaseCard ${className}`}
      {...elementAttr}
    >
      {children}
    </div>
  );
};
