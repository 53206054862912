import { useEffect } from 'react';
import { useState } from 'react';
import { RelatedMigs } from './RelatedMigs';
import './MigRelationExplorer.less';
interface Mig {
  id: string;
  name: string;
  team2: {
    id: string;
    domainId: {
      itemId: string;
    };
    name: string;
  };
  domainId: {
    itemId: string;
  };
}

interface Props {
  mig?: Mig | null;
  inspectedMig: Mig | null;
  setInspectedMig: (mig: Mig | null) => void;
}

export const MigRelationExplorer = ({
  mig,
  inspectedMig,
  setInspectedMig,
}: Props) => {
  const [expandedMigs, setExpandedMigs] = useState<Mig[]>(mig ? [mig] : []);

  useEffect(() => {
    setInspectedMig(null);
    setExpandedMigs(mig ? [mig] : []);
  }, [mig, setInspectedMig]);

  return (
    <div
      className="MigRelationExplorer"
      data-intercom-target="Selected MIG Relations List"
    >
      {expandedMigs.map((parentMig, index) => (
        <RelatedMigs
          key={parentMig.id}
          parentMigId={parentMig.domainId.itemId}
          teamId={parentMig.team2.domainId.itemId}
          onExpandMig={(mig: Mig) => {
            if (expandedMigs.some((m) => m.id === mig.id)) {
              setExpandedMigs(expandedMigs.slice(0, index + 1));
            } else {
              setExpandedMigs((expandedMigs) => [
                ...expandedMigs.slice(0, index + 1),
                mig,
              ]);
            }
          }}
          expandedMig={expandedMigs[index + 1]}
          onInspectChildMig={setInspectedMig}
          inspectedChildMig={inspectedMig}
        />
      ))}
    </div>
  );
};
