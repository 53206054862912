import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Spin, Result } from 'antd';
import { FullscreenModal } from '../../../../../components/FullscreenModal';
import { AccelerationMeetingContainer } from './AccelerationMeetingContainer';
import { useHistory } from 'react-router-dom';
import { usePreviousPeriodSummary } from './accelerationMeetingContainer/meetingSteps/PreviousPeriodSummary/hooks/usePreviousPeriodSummary';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { GetAccelerationMeetingDocument } from '../../../../../generated/graphql';
import { gql, useQuery } from '@apollo/client';

interface Props {
  team: Team;
}

interface Team {
  name: string;
  domainId: {
    itemId: string;
  };
}

export const AccelerationMeetingModal = ({ team }: Props) => {
  const [title, setTitle] = useState<ReactNode>('');
  const { t } = useTranslation();

  const { data, loading } = useQuery(GetAccelerationMeetingDocument, {
    variables: {
      teamId: team.domainId.itemId,
    },
    fetchPolicy: 'network-only',
    onError: () => {
      showNotification('error', {
        message: t('AccelerationMeetingView.loadFailure'),
      });
    },
  });

  const {
    hasFirstMeeting,
    hasSprintKeyActivities,
    hasWeeklyKeyActivities,
    loading: sinceLastMeetingDataLoading,
  } = usePreviousPeriodSummary({
    teamId: team.domainId.itemId,
  });
  const history = useHistory();

  const defaultOnClose = () =>
    history.push('/team/' + team.domainId.itemId + '/overview');

  const accelerationMeeting = data?.accelerationMeeting;
  const mitemSprints = data?.mitemAccelerationMeetingSprints ?? null;

  return (
    <FullscreenModal
      open={true}
      onCancel={defaultOnClose}
      keyboard={true}
      destroyOnClose
      title={
        <div className="flx flx--column">
          <Typography.Text>
            {t('AccelerationMeetingView.accelerationMeeting') + ' | '}
            {title}
          </Typography.Text>
        </div>
      }
      styles={{ body: { padding: 0 } }}
      fakeFooterHeight={80}
    >
      {loading && !accelerationMeeting && (
        <div className="center-content" style={{ width: '100%' }}>
          <Spin size="large" delay={250} />
        </div>
      )}
      {!loading && !accelerationMeeting && (
        <Result
          title={t('AccelerationMeetingView.missingAkpisNotice')}
          status="info"
        />
      )}
      {!loading && !sinceLastMeetingDataLoading && accelerationMeeting && (
        <AccelerationMeetingContainer
          accelerationMeeting={accelerationMeeting}
          mitemSprints={mitemSprints}
          key={team.domainId.itemId}
          team={team}
          setTitle={setTitle}
          defaultOnClose={defaultOnClose}
          previousPeriodConfig={{
            hasFirstMeeting,
            hasSprintKeyActivities,
            hasWeeklyKeyActivities,
          }}
        />
      )}
    </FullscreenModal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCELERATION_MEETING_FRAGMENT = gql`
  fragment AccelerationMeetingModal_TeamAccelerationMeeting on TeamAccelerationMeeting {
    id
    ...AccelerationMeetingContainer_TeamAccelerationMeeting
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCELERATION_MEETING_SPRINTS_FRAGMENT = gql`
  fragment AccelerationMeetingModal_MitemAccelerationMeetingSprints on MitemAccelerationMeetingSprints {
    currentSprint {
      ...AccelerationMeetingSprint
    }
    previousSprint {
      ...AccelerationMeetingSprint
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_FULL_ACCELERATION_MEETING = gql`
  query getAccelerationMeeting($teamId: ID!) {
    accelerationMeeting(teamId: $teamId) {
      ...AccelerationMeetingModal_TeamAccelerationMeeting
    }
    mitemAccelerationMeetingSprints(teamId: $teamId) {
      ...AccelerationMeetingContainer_MitemAccelerationMeetingSprints
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCELERATION_MEETING_COMMITMENTS_FRAGMENT = gql`
  fragment AccMeetingWeeklyKaCommitments on AccelerationKpiCommitments {
    accelerationKpiId
    commitments {
      quantity
      userId
      note
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCELERATION_MEETING_SPRINT_FRAGMENT = gql`
  fragment AccelerationMeetingSprint on MitemSprint {
    id
    locked
    startDate
    endDate
    sprintNumber
    finalized
    teamId
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACCELERATION_MEETING_MODAL_MITEM_SPRINT = gql`
  fragment AccelerationMeetingModal_MitemSprint on MitemSprint {
    id
    locked
    startDate
    endDate
    sprintNumber
    finalized
    teamId
  }
`;
