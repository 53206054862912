import { gql, useMutation } from '@apollo/client';
import {
  Action,
  MilestoneDeadline_MilestoneFragment,
  UpdateMilestoneDeadlineDocument,
} from '../generated/graphql';
import { useTenantPermissions } from '../usePermissions';
import { friendlyDate, utcTimeFormat } from '../services/dateFormats';
import { DeadlineDeluxe } from './DeadlineDeluxe';
import { Dayjs } from 'dayjs';
import { Spin } from 'antd';

interface Props {
  milestone: MilestoneDeadline_MilestoneFragment;
}

export const MilestoneDeadline = ({ milestone }: Props) => {
  const { permissionsTenant } = useTenantPermissions();

  const allowedToUpdateMilestone = permissionsTenant?.milestone.some(
    (permittedAction) => permittedAction === Action.UPDATE
  );

  const [editInitiative, { loading }] = useMutation(
    UpdateMilestoneDeadlineDocument
  );

  const handleChange = (newDeadline: Dayjs) => {
    editInitiative({
      variables: {
        milestoneId: milestone.domainId.itemId,
        milestone: {
          rev: milestone.rev,
          deadlineAt: utcTimeFormat(newDeadline),
        },
      },
    });
  };

  if (!allowedToUpdateMilestone) {
    return <>{friendlyDate(milestone.deadlineAt)}</>;
  }

  return (
    <Spin spinning={loading} size="small">
      <DeadlineDeluxe onChange={handleChange} deadline={milestone.deadlineAt} />
    </Spin>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_FRAGMENT = gql`
  fragment MilestoneDeadline_milestone on MilestoneWithLinks {
    id
    domainId {
      itemId
      tenantId
    }
    rev
    deadlineAt
    metadata {
      archived
      completedAt
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EDIT_MILESTONE = gql`
  mutation UpdateMilestoneDeadline(
    $milestoneId: ID!
    $milestone: MilestoneUpdateInput!
  ) {
    updateMilestoneWithLinks(milestoneId: $milestoneId, milestone: $milestone) {
      id
      deadlineAt
      domainId {
        itemId
      }
    }
  }
`;
