import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';
import HowweLogo from './Howwe_Logo.png';

/**
 * Replaces disallowed characters in a filename with underscores.
 */
const sanitizeFileName = (name: string): string =>
  name.replace(/[^a-zA-Z0-9-_åäöÅÄÖ]/g, '_');

/**
 * Generates a filename using the provided company name (or default "Report")
 * and the current date.
 */
const generatePdfFileName = (companyName?: string): string => {
  const date = dayjs().format('YYYY-MM-DD');
  const baseName = companyName || 'Report';
  return `${sanitizeFileName(baseName)}_${date}.pdf`;
};

/**
 * Recursively finds unbreakable elements inside a given parent.
 */
const findUnbreakableElements = (element: HTMLElement): HTMLElement[] => {
  const unbreakableElements: HTMLElement[] = [];
  for (const child of Array.from(element.children)) {
    if (child instanceof HTMLElement) {
      if (child.classList.contains('unbreakable')) {
        unbreakableElements.push(child);
      } else {
        unbreakableElements.push(...findUnbreakableElements(child));
      }
    }
  }
  return unbreakableElements;
};

/**
 * Adds a footer to the current page.
 */
const addFooter = (pdf: jsPDF, pageNumber: number): void => {
  if (pageNumber === 1) return;
  const footerText = `Page ${pageNumber - 1} | Strategic Plan Report`;
  pdf.setFontSize(8);
  pdf.text(footerText, 10, 205); // Bottom-left margin

  // Define dimensions for the logo (adjust these values as needed)
  const logoWidth = 10; // width in mm
  const logoHeight = 2.5; // height in mm

  // Get the width of the PDF page
  const pageWidth = pdf.internal.pageSize.getWidth();

  // Calculate x position so the logo appears in the right corner with a 10mm right margin
  const xPosition = pageWidth - logoWidth - 10;
  // Set y position so that the logo aligns with the footer text area
  const yPosition = 205 - logoHeight;

  // Add the logo image.
  // Make sure 'logoImage' holds your PNG image data (e.g., as a Base64 string)
  pdf.addImage(HowweLogo, 'PNG', xPosition, yPosition, logoWidth, logoHeight);
};

/**
 * Exports the report (inside #report-container) to a PDF.
 */
export const exportReportToPdf = async (tenantName: string): Promise<void> => {
  const reportContainer = document.getElementById('report-container');
  if (!reportContainer) return;

  // PDF configuration: A4 landscape with margins.
  const pdf = new jsPDF({
    orientation: 'landscape',
    unit: 'mm',
    format: 'a4',
  });
  pdf.setFontSize(10);

  const pageWidth = 297;
  const pageHeight = 210;
  const pageMargin = 10;
  const usableWidth = pageWidth - pageMargin * 2;
  const usableHeight = pageHeight - pageMargin * 2;
  const scaleFactor = 1;
  const imageSpacing = 5;

  let currentPageHeight = pageMargin;
  let pageNumber = 1;

  /**
   * Processes a given HTML element for PDF rendering.
   */
  const processElement = async (element: HTMLElement): Promise<void> => {
    if (element.classList.contains('page-break')) {
      addFooter(pdf, pageNumber);
      pdf.addPage();
      pageNumber++;
      currentPageHeight = pageMargin;
    }

    // Render the element as a canvas.
    const canvas = await html2canvas(element, {
      scale: 3,
      useCORS: true,
    });
    if (canvas.width === 0 || canvas.height === 0) return;

    const imgData = canvas.toDataURL('image/png');
    const imgWidth = usableWidth * scaleFactor;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const centerX = (pageWidth - imgWidth) / 2;

    // Handle "pdf-center" class: Center the element vertically within the remaining space.
    if (element.classList.contains('pdf-center')) {
      const remainingSpace = usableHeight - currentPageHeight;
      if (imgHeight < remainingSpace) {
        const centeredY = currentPageHeight + (remainingSpace - imgHeight) / 2;
        pdf.addImage(
          imgData,
          'JPEG',
          centerX,
          centeredY,
          imgWidth,
          imgHeight,
          undefined,
          'FAST'
        );
        currentPageHeight += imgHeight + imageSpacing;
        return;
      }
    }

    // If the entire element fits on the current page, add it normally.
    if (currentPageHeight + imgHeight + imageSpacing <= usableHeight) {
      pdf.addImage(
        imgData,
        'JPEG',
        centerX,
        currentPageHeight,
        imgWidth,
        imgHeight,
        undefined,
        'FAST'
      );
      currentPageHeight += imgHeight + imageSpacing;
      return;
    }

    // Find unbreakable sections within.
    const unbreakableSections = findUnbreakableElements(element);

    if (unbreakableSections.length > 0) {
      for (const unbreakable of unbreakableSections) {
        const subCanvas = await html2canvas(unbreakable, {
          scale: 3,
          useCORS: true,
        });

        const subImgData = subCanvas.toDataURL('image/png');
        const subImgWidth = usableWidth * scaleFactor;
        const subImgHeight = (subCanvas.height * subImgWidth) / subCanvas.width;

        if (subImgHeight + imageSpacing > usableHeight) {
          pdf.addImage(
            subImgData,
            'JPEG',
            centerX,
            pageMargin,
            subImgWidth,
            subImgHeight,
            undefined,
            'FAST'
          );
          addFooter(pdf, pageNumber);
          pdf.addPage();
          pageNumber++;
          currentPageHeight = pageMargin;
        } else {
          if (
            currentPageHeight !== pageMargin &&
            currentPageHeight + subImgHeight + imageSpacing > usableHeight
          ) {
            addFooter(pdf, pageNumber);
            pdf.addPage();
            pageNumber++;
            currentPageHeight = pageMargin;
          }
          pdf.addImage(
            subImgData,
            'JPEG',
            centerX,
            currentPageHeight,
            subImgWidth,
            subImgHeight,
            undefined,
            'FAST'
          );
          currentPageHeight += subImgHeight + imageSpacing;
        }
      }
    } else {
      let offsetY = 0;
      while (offsetY < canvas.height) {
        let sliceHeight = Math.min(
          canvas.height - offsetY,
          (usableHeight * canvas.width) / imgWidth
        );

        if (
          currentPageHeight !== pageMargin &&
          currentPageHeight + (sliceHeight * imgWidth) / canvas.width >
            usableHeight
        ) {
          addFooter(pdf, pageNumber);
          pdf.addPage();
          pageNumber++;
          currentPageHeight = pageMargin;
        }

        const sliceCanvas = document.createElement('canvas');
        sliceCanvas.width = canvas.width;
        sliceCanvas.height = sliceHeight;
        const ctx = sliceCanvas.getContext('2d');

        if (ctx) {
          ctx.drawImage(
            canvas,
            0,
            offsetY,
            canvas.width,
            sliceHeight,
            0,
            0,
            canvas.width,
            sliceHeight
          );
          const sliceImgData = sliceCanvas.toDataURL('image/png');
          pdf.addImage(
            sliceImgData,
            'JPEG',
            pageMargin,
            currentPageHeight,
            imgWidth,
            (sliceHeight * imgWidth) / canvas.width,
            undefined,
            'FAST'
          );
          currentPageHeight += (sliceHeight * imgWidth) / canvas.width;
        }
        offsetY += sliceHeight;
      }
    }
  };

  const sections = Array.from(reportContainer.children);
  for (const section of sections) {
    const sectionChildren = Array.from(section.children);
    for (const child of sectionChildren) {
      await processElement(child as HTMLElement);
    }
  }

  addFooter(pdf, pageNumber);
  pdf.save(generatePdfFileName(tenantName));
};
