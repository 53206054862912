import { gql, useQuery } from '@apollo/client';
import { ActivityMilestone } from '../activities/listPage/ActivityMilestone';
import {
  GetSprintKeyActivitiesForMilestoneColumnDocument,
  MilestoneColumn_MilestoneFragment,
} from '../../../generated/graphql';
import VertTimeline from '../../../components/VertTimeline';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Dot } from '../../../components/Dot';
import { Colors } from '../../componentLibrary/Colors';
import { friendlyDate } from '../../../services/dateFormats';
import { stringSort } from '../../../services/stringSort';
import './MilestoneColumn.less';
import { Alert } from 'antd';
import { KeyActivityStatusIcon } from '../../../components/KeyActivityStatusIcon';
import { getSimpleStatus } from '../../../services/mitemUtils';

interface Props {
  teamId: string;
  milestone: MilestoneColumn_MilestoneFragment;
}

export const MilestoneColumn = ({ teamId, milestone }: Props) => {
  const { data } = useQuery(GetSprintKeyActivitiesForMilestoneColumnDocument, {
    variables: {
      milestoneId: milestone.domainId.itemId,
    },
  });

  const timelineItems = useMemo(() => {
    const activitiesForMilestone =
      data?.sprintKeyActivitiesForMilestone?.sprintKeyActivities ?? [];
    // Group milestones by deadline date
    const groupedByDate = activitiesForMilestone.reduce(
      (groups, activity) => {
        const date = dayjs(activity.deadline).format('YYYY-MM-DD');
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(activity);
        return groups;
      },
      {} as Record<string, typeof activitiesForMilestone>
    );

    // Create timeline items from groups
    return Object.entries(groupedByDate)
      .sort(([dateA], [dateB]) => stringSort(dateA, dateB))
      .map(([date, activitiesForDate]) => {
        return {
          children: (
            <>
              <div className="txt--secondary mb--xs font-size--sm">
                {friendlyDate(dayjs(date))}
              </div>
              {activitiesForDate
                .sort((a, b) => stringSort(a.deadline, b.deadline))
                .map((activity) => (
                  <div
                    key={activity.id}
                    className="MilestoneColumn_activityCard font-size--sm"
                  >
                    <KeyActivityStatusIcon
                      status={getSimpleStatus(activity.status)}
                      size={16}
                    />
                    <div>{activity.name}</div>
                  </div>
                ))}
            </>
          ),
          dot: <Dot color={Colors.Grays.DISABLED_FONTS} size={8} />,
        };
      });
  }, [data?.sprintKeyActivitiesForMilestone?.sprintKeyActivities]);

  return (
    <div className="pr--l">
      <div style={{ width: '250px' }}>
        <div className="font-size--sm mb--xs bold">
          {friendlyDate(dayjs(milestone.deadlineAt))}
        </div>
        <ActivityMilestone milestone={milestone} teamId={teamId} coloredBg />
        <div className="mt">
          {timelineItems.length === 0 && (
            <Alert
              message="No activities
            are planned for this milestone"
            />
          )}

          <VertTimeline items={timelineItems} />
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINT_KEY_ACTIVITIES_FOR_MILESTONE_COLUMN = gql`
  query getSprintKeyActivitiesForMilestoneColumn($milestoneId: ID!) {
    sprintKeyActivitiesForMilestone(milestoneId: $milestoneId) {
      sprintKeyActivities {
        id
        name
        deadline
        status
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_COLUMN_MILESTONE_WITH_LINKS = gql`
  fragment MilestoneColumn_Milestone on MilestoneWithLinks {
    id
    domainId {
      itemId
    }
    deadlineAt
    ...ActivityMilestone_MilestoneWithLinksForTeam
  }
`;
