import { Typography } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { Btn } from '../../components/Button';
import { DisplayName } from '../../components/DisplayName';
import { useMe } from '../../hooks/useMe';
import './WhyPage.less';

export const GenericWhyPage = () => {
  const { data } = useMe();
  const user = data?.currentUser;
  const { t } = useTranslation();
  return (
    <div className="WhyPage">
      <div className="mb--xxl">
        <Typography.Title level={4}>
          <Trans i18nKey="GenericWhyPage.header">
            Hi {user && <DisplayName user={user} />}, welcome to Howwe!
          </Trans>
        </Typography.Title>
        <div>
          {t('GenericWhyPage.sectionOne')}
          <strong> {t('GenericWhyPage.sectionOneBold')}</strong>
        </div>

        <div>{t('GenericWhyPage.sectionTwo')}</div>
        <div className="mt--l">
          <Btn
            type="callToAction"
            href={
              user?.locale === 'sv'
                ? 'https://academy.howwe.io/sv/intro-to-howwe-sv/'
                : 'https://academy.howwe.io/en/intro-to-howwe-en/'
            }
            target="blank"
            className="mt--auto"
          >
            {t('GenericWhyPage.buttonText')}
          </Btn>
        </div>
      </div>
    </div>
  );
};
