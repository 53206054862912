import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useQueryParams() {
  const location = useLocation();
  const history = useHistory();

  function setParam(
    key: string,
    value: string | null | undefined,
    { deleteIfEmpty = true } = {}
  ) {
    const searchParams = new URLSearchParams(location.search);
    if (deleteIfEmpty && (value == null || value === '')) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value ?? '');
    }
    const newLocation = { ...location, search: searchParams.toString() };

    history.push(newLocation);
  }

  return {
    queryParams: useMemo(
      () => new URLSearchParams(location.search),
      [location.search]
    ),
    setParam,
  };
}
