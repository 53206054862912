import { CloseOutlined, CommentOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { match, P } from 'ts-pattern';
import { Btn } from '../../../../components/Button';
import cx from 'classnames';
interface Props {
  isPreviewMode: boolean;
  className?: string;
}

export const FreeTextComment = ({ isPreviewMode, className }: Props) => {
  const [showInputField, setShowInputField] = useState(false);
  const [freeText, setFreeText] = useState<string | undefined>();

  const content = match({ isPreviewMode, freeText, showInputField })
    .with({ isPreviewMode: true, freeText: undefined }, () => null)
    .with(
      { isPreviewMode: true, freeText: P.string.minLength(1) },
      () => freeText
    )
    .with(
      { isPreviewMode: false, freeText: P.string.minLength(1) },
      { isPreviewMode: false, showInputField: true },
      () => (
        <>
          <TextArea
            value={freeText}
            rows={3}
            onChange={(v) => setFreeText(v.target.value)}
          />
          <Btn
            className="ml"
            type="link"
            icon={<CloseOutlined />}
            onClick={() => {
              setShowInputField(false);
              setFreeText(undefined);
            }}
          />
        </>
      )
    )
    .otherwise(() => (
      <Btn type="link" onClick={() => setShowInputField(true)}>
        Add free text comment
      </Btn>
    ));

  if (content === null) return null;

  return (
    <div
      className={cx(
        'unbreakable',
        { 'flx flx--jc-center ': !showInputField },
        className
      )}
    >
      <Alert
        className="preserveLinebreaks"
        closable={false}
        message={
          <div className="flx font-size--sm flx--ai-center pa--s">
            <CommentOutlined className="ml--s mr--l txt--action-blue font-size--lg" />
            {content}
          </div>
        }
        onClose={() => setShowInputField(false)}
        type="info"
      />
    </div>
  );
};
