import React, { useMemo } from 'react';
import { Tree } from './Tree';
import './FakeRoot.less';
import { TeamCardUmbrella } from './TeamCardUmbrella';
import { gql, useQuery } from '@apollo/client';

import { GetMigTargetFulfillmentDocument } from '../../../../../generated/graphql';
import { toMap } from '../../../../../services/toMap';
import { TeamCardWithPerf } from './TeamCardWithPerf';
import { useTeamsTargetFulfillment } from '../../../../../hooks/useKeyActivityPerformanceQuery';

interface Props {
  teams: Team[];
  tenantName: string;
}

interface Team {
  id: string;
  domainId: {
    itemId: string;
  };
  name: string;
  parentTeam?: { id: string; domainId: { itemId: string } } | null;
  umbrellaTeam?: boolean | null;
}

interface TeamWithComponent extends Team {
  component: React.ReactNode;
}

export const TeamTree = ({ teams, tenantName }: Props) => {
  const { akpiTargetFulfillment } = useTeamsTargetFulfillment();

  const migTargetFulfillment = useQuery(GetMigTargetFulfillmentDocument);

  const akpiPerfLookup = useMemo(
    () =>
      akpiTargetFulfillment &&
      toMap(akpiTargetFulfillment, (item) => item.teamId),
    [akpiTargetFulfillment]
  );
  const migPerfLookup = useMemo(
    () =>
      migTargetFulfillment.data?.migPerformanceForTenantTeams &&
      toMap(
        migTargetFulfillment.data.migPerformanceForTenantTeams.teamsPerformance,
        (item) => item.domainId.itemId
      ),
    [migTargetFulfillment.data]
  );

  const teamsWithComponents: TeamWithComponent[] = useMemo(
    () =>
      teams.map((t) => {
        const teamId = t.domainId.itemId;
        if (t.umbrellaTeam) {
          return {
            ...t,
            component: <TeamCardUmbrella name={t.name} />,
          };
        }
        return {
          ...t,
          component: (
            <TeamCardWithPerf
              name={t.name}
              teamId={teamId}
              akpiPercent={akpiPerfLookup?.[teamId]?.teamTargetFulfillment}
              akpiDelta={akpiPerfLookup?.[teamId]?.teamTargetFulfillmentDelta}
              migPercent={migPerfLookup?.[teamId]?.goalFulfillment}
            />
          ),
        };
      }),
    [teams, migPerfLookup, akpiPerfLookup]
  );

  return <Tree teamData={teamsWithComponents} tenantName={tenantName} />;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MIG_TARGET_FULFILLMENT = gql`
  query getMigTargetFulfillment {
    migPerformanceForTenantTeams {
      teamsPerformance {
        domainId {
          itemId
        }
        id
        goalFulfillment
      }
    }
  }
`;
