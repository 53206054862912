import { Input, Form, Checkbox, Divider } from 'antd';
import './CreateActivity.less';
import { skaValidationRules } from '../../../../appPages/team/common/utils/useSkaValidationRules';
import { useTranslation } from 'react-i18next';
import { gql, PureQueryOptions, useMutation } from '@apollo/client';
import {
  ActivityFullForm_MitemFragment,
  CreateActivityFullVersionDocument,
  SprintKaInput,
} from '../../../../generated/graphql';
import { RecursiveOmit } from '../../../../services/typeHelpers';
import { TitleIcon } from '../../../initiatives/InitiativeForm/Icons/TitleIcon';
import { AlignmentIcon } from '../../../initiatives/InitiativeForm/Icons/AlignmentIcon';
import { useTenantDetails } from '../../../../hooks/useTenantDetails';
import {
  FlagOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { EndingPointIcon } from '../../../initiatives/InitiativeForm/Icons/EndingPointIcon';
import { PersonSearchIcon } from '../../../initiatives/InitiativeForm/Icons/PersonSearchIcon';
import { TextAreaIconIcon } from '../../../initiatives/InitiativeForm/Icons/TextAreaIcon';
import { Btn } from '../../../Button';
import { standardDateFormat } from '../../../../services/dateFormats';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';
import { InitiativeSelectFormItem } from './formItems/InitiativeSelectFormItem';
import { TargetIcon } from '../../../initiatives/InitiativeForm/Icons/TargetIcon';
import { TagsSelector } from '../../../../appPages/team/sprint/planning/components/MitemForm/TagsItem';
import { TeamTagIcon } from '../../../initiatives/InitiativeForm/Icons/TeamTagIcon';
import { z } from 'zod';
import { MemberSelectFormItem } from './formItems/MemberSelectFormItem';
import { DeadlinePickerFormItem } from './formItems/DeadlinePickerFormItem';
import { MigSelectFormItem } from './formItems/MigSelectFormItem';
import { MilestoneSelectFormItem } from './formItems/MilestoneSelectFormItem';

export interface ActivityAlignment {
  id: string;
  initiativeId?: string;
  milestoneId?: string;
}

interface Props {
  initialValues?: ActivityFullFormValues;
  refetchQueries?: Array<string | PureQueryOptions>;
  submitPending?: boolean;
  onSuccess?: () => void;
  teamId: string;
}

export const CreateActivityFullVersion = ({
  teamId,
  initialValues,
  onSuccess,
  refetchQueries,
}: Props) => {
  const [keepOpen, setKeepOpen] = useState(false);
  const initialFormValues = getInitiativeValues(initialValues);
  const [form] = Form.useForm();

  const [createActivityFullVersion, { loading: createPending }] = useMutation(
    CreateActivityFullVersionDocument,
    {
      update(cache, { data }) {
        if (!data?.createSprintKeyActivity) return;

        cache.modify({
          id: `TeamActivitiesResponse:${teamId}`, // The cache ID of the TeamActivitiesResponse
          fields: {
            sprintKeyActivities(existingActivities = []) {
              const newActivityRef = cache.writeFragment({
                data: data.createSprintKeyActivity,
                fragment: gql`
                  fragment NewActivityFull on Mitem {
                    id
                  }
                `,
              });

              return [...existingActivities, newActivityRef];
            },
          },
        });
      },
      onCompleted: () => {
        form.resetFields();
        if (!keepOpen) {
          onSuccess?.();
        }
      },
    }
  );

  const { t } = useTranslation();
  const { features } = useTenantDetails();

  const handleSubmit = (values: unknown) => {
    const result = activityFormSchema.safeParse(values);
    if (!result.success) {
      // Convert Zod errors to Ant Design form errors
      const formErrors = result.error.issues.map((issue) => ({
        name: issue.path,
        errors: [issue.message],
      }));
      form.setFields(formErrors);
      return;
    }

    const { alignments, ...rest } = result.data;

    const initiativeIds = alignments
      .filter((a) => !a.milestoneId)
      .map((a) => a.initiativeId)
      .filter((a) => typeof a === 'string');

    const milestoneIds = alignments
      .map((a) => a.milestoneId)
      .filter((a) => typeof a === 'string');

    const skaToSubmit: SprintKaInput = {
      name: rest.name,
      definitionOfDone: rest.definitionOfDone,
      ownerId: rest.ownerId,
      tags: rest.tags,
      deadline: standardDateFormat(rest.deadline),
      supportsInitiative2Ids: initiativeIds,
      supportsMilestoneIds: milestoneIds,
      supportsMigIds: rest.supportMigId ? [{ id: rest.supportMigId }] : [],
    };

    createActivityFullVersion({
      variables: {
        teamId,
        sprintKeyActivity: skaToSubmit,
      },
      refetchQueries,
    });
  };

  return (
    <div>
      <Form
        form={form}
        autoComplete="off"
        initialValues={initialFormValues}
        requiredMark={false}
        name="create_activity_form"
        onFinish={(values) => handleSubmit(values)}
        colon={false}
      >
        <Form.Item
          name="name"
          label={<TitleIcon className="font-size--lg txt--secondary" />}
          required
          rules={skaValidationRules.name}
          data-intercom-target="Sprint Key Activity Form Name Input"
        >
          <Input autoComplete="off" autoFocus placeholder={t('common.title')} />
        </Form.Item>
        {features.tenantInitiativesEnabled && (
          <Form.List name="alignments">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key} className="flx">
                    <Form.Item
                      key={`${field.key}-initiative`}
                      label={
                        <AlignmentIcon className="font-size--lg txt--secondary" />
                      }
                      name={[field.name, 'initiativeId']}
                      className="mr--l flx--1"
                    >
                      <InitiativeSelectFormItem
                        teamId={teamId}
                        onChange={() => {
                          const alignments = form.getFieldValue('alignments');
                          alignments[index].milestoneId = undefined;
                          form.setFieldsValue({ alignments });
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      key={`${field.key}-milestone`}
                      label={
                        <FlagOutlined className="font-size--lg txt--secondary" />
                      }
                      className="flx--1 mr"
                      name={[field.name, 'milestoneId']}
                    >
                      <MilestoneSelectFormItem
                        teamId={teamId}
                        selectedInitiativeId={form.getFieldValue([
                          'alignments',
                          index,
                          'initiativeId',
                        ])}
                      />
                    </Form.Item>
                    <Form.Item key={`${field.key}-remove`} label={null}>
                      {fields.length > 1 && (
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                          className="txt--error"
                        />
                      )}
                    </Form.Item>
                  </div>
                ))}

                <Form.Item>
                  <Btn
                    type="link"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add another initiative alignment
                  </Btn>
                </Form.Item>
              </>
            )}
          </Form.List>
        )}
        <div className="flx flx--gap">
          <Form.Item
            name="deadline"
            label={<EndingPointIcon className="font-size--lg txt--secondary" />}
            required
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DeadlinePickerFormItem teamId={teamId} />
          </Form.Item>

          <Form.Item
            name="ownerId"
            label={
              <PersonSearchIcon className="font-size--lg txt--secondary" />
            }
            className="flx--1"
            required
            rules={skaValidationRules.ownerId}
            data-intercom-target="Sprint Key Activity Form Owner Input"
          >
            <MemberSelectFormItem teamId={teamId} />
          </Form.Item>
        </div>

        <Form.Item
          name="definitionOfDone"
          label={<TextAreaIconIcon className="font-size--lg txt--secondary" />}
          rules={skaValidationRules.definitionOfDone}
          data-intercom-target="Sprint Key Activity Form Definition of Done Input"
        >
          <Input.TextArea
            placeholder={t('common.definitionOfDone')}
            autoSize={{ minRows: 2, maxRows: 10 }}
            maxLength={1024}
            showCount
          />
        </Form.Item>
        <Divider className="mb--l" />
        <div className="flx flx--gap">
          <Form.Item
            className="flx--1"
            label={<TargetIcon className="font-size--lg txt--secondary" />}
            name="supportMigId"
          >
            <MigSelectFormItem teamId={teamId} />
          </Form.Item>
          <Form.Item
            name="tags"
            className="flx--1"
            label={<TeamTagIcon className="font-size--lg txt--secondary" />}
          >
            <TagsSelector teamId={teamId} />
          </Form.Item>
        </div>
        <Form.Item label={null}>
          <div className="flx flx--jc-space-between borderTop pt--l flx--ai-center mt--xl">
            <Checkbox
              checked={keepOpen}
              onChange={(e) => setKeepOpen(e.target.checked)}
            >
              Keep open
            </Checkbox>
            <div>
              <Btn type="primary" htmlType="submit" loading={createPending}>
                Create Activity
              </Btn>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

const getInitiativeValues = (values?: ActivityFullFormValues) => {
  return {
    name: values?.name,
    definitionOfDone: values?.definitionOfDone,
    supportMigId: values?.supportMigId,
    deadline: values?.deadline && dayjs(values.deadline),
    ownerId: values?.ownerId,
    tags: values?.tags ?? [],
    alignments: values?.alignments ?? [{}],
  };
};

const dayjsSchema = z.custom<Dayjs>(
  (data) => data && typeof data.isValid === 'function' && data.isValid(),
  'Invalid date'
);

// Alignment schema
const alignmentSchema = z.object({
  initiativeId: z.string().optional(),
  milestoneId: z.string().optional(),
});

// Main form schema
export const activityFormSchema = z.object({
  name: z.string().min(1, 'Title is required'),
  alignments: z.array(alignmentSchema),
  deadline: dayjsSchema,
  ownerId: z.string().min(1, 'Owner is required'),
  definitionOfDone: z.string().max(1024).optional(),
  supportMigId: z.string().optional(),
  tags: z.array(z.string()).optional(),
});

export type ActivityFullFormValues = Partial<
  z.infer<typeof activityFormSchema>
>;

export type ActivityInitialValues = Partial<
  RecursiveOmit<ActivityFullForm_MitemFragment, '__typename'>
>;

export const CREATE_MITEM_V2 = gql`
  mutation createActivityFullVersion(
    $teamId: ID!
    $sprintKeyActivity: SprintKaInput!
  ) {
    createSprintKeyActivity(
      teamId: $teamId
      sprintKeyActivity: $sprintKeyActivity
    ) {
      ...ActivityFullForm_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_FORM_MITEM = gql`
  fragment ActivityFullForm_Mitem on Mitem {
    id
    name
    archived
    archivedAt
    completed
    completedAt
    deadline
    definitionOfDone
    noMigAssociation
    milestone
    supportsMilestoneLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        domainId {
          itemId
          tenantId
        }
        name
        deadlineAt
        metadata {
          completedAt
          archived
          supportsInitiatives {
            id
            domainId {
              itemId
            }
            data {
              id
              domainId {
                itemId
              }
              name
              tag {
                title
                iconId
                colorCode
              }
            }
          }
        }
      }
    }
    supportsInitiativeLinks {
      id
      domainId {
        itemId
      }
      data {
        id
        name
        description
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          status
          archived
        }
      }
    }
    status
    tags {
      id
      name
      teamId
      active
      backgroundColor
      createdAt
    }
    owner2 {
      id
      domainId {
        itemId
      }
      email
      initials
      name
      displayName
      archived
    }
    supportedMigs {
      id
      name
      domainId {
        itemId
        teamId
      }
    }
    teamId
  }
`;
