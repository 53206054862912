/**
 * Colors in this file should always be kept in-sync with corresponding
 * less variables in src/styleVars.less.
 *
 * Note: when possible write your own css classes and use the less variables instead
 * */

import { MitemStatus } from './generated/graphql';

export const lineColor = {
  goal: '#ff6306',
  actual: '#1890ff',
  currentPeriod: 'grey',
  historic: '#999',
  committed: '#528ec5 ',
};

// Mitem colors
export const mitemStatusColor: Record<MitemStatus, string> = {
  ACTIVE: '#ff6309',
  COMPLETED: '#027c34',
  NOT_COMPLETED: '#cc0000',
  OVERDUE: '#ffbb33',
  POSTPONED: '#ffbb33',
  PLANNED: '#9427ca',
  ARCHIVED: '#a0adb8',
};

export const mitemStatusColorLight: Record<MitemStatus, string> = {
  OVERDUE: '#fffbcc',
  POSTPONED: '#fffbcc',
  ACTIVE: '#ffefe3',
  PLANNED: '#f3e8fd',
  COMPLETED: '#e7f4ea',
  NOT_COMPLETED: '#f9e5e4',
  ARCHIVED: '#ddd',
};

export const mitemStatusColorLightContrast: Record<MitemStatus, string> = {
  OVERDUE: '#8C3D10',
  POSTPONED: '#8C3D10',
  ACTIVE: mitemStatusColor.ACTIVE,
  PLANNED: mitemStatusColor.PLANNED,
  COMPLETED: mitemStatusColor.COMPLETED,
  NOT_COMPLETED: mitemStatusColor.NOT_COMPLETED,
  ARCHIVED: mitemStatusColor.ARCHIVED,
};

export const mitemStatusColorPairs = getPairs();

function getPairs() {
  const statuses = [
    MitemStatus.OVERDUE,
    MitemStatus.ACTIVE,
    MitemStatus.PLANNED,
    MitemStatus.COMPLETED,
    MitemStatus.NOT_COMPLETED,
    MitemStatus.ARCHIVED,
  ];

  const pairs = {} as any;

  for (const status of statuses) {
    pairs[status] = {
      light: mitemStatusColorLight[status],
      dark: mitemStatusColorLightContrast[status],
    };
  }

  return pairs as Record<MitemStatus, { light: string; dark: string }>;
}
