import { gql } from '@apollo/client';
import { friendlyUsername } from '../services/friendlyUsername';

interface User {
  email: string;
  name?: string | null;
  displayName?: string | null;
}

interface Props {
  user: User;
}

export const DisplayName = ({ user }: Props) => (
  <span title={user.email} data-hj-suppress>
    {friendlyUsername(user)}
  </span>
);

export const USER_DISPLAY_NAME_FRAGMENT = gql`
  fragment DisplayName_User on User2 {
    email
    name
    displayName
  }
`;
