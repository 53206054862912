import { createIncludeListFeatureFlagHook } from './createFeatureFlagHook';

/* Remember to remove theses hooks and related usages
 * after the feature has reach GA and is confirmed stable.
 *
 * Also remember to remove env var keys specified in the FE server and envConf.ts when removing a hook below.
 */

export const useActiveTeamGraphFeatureFlag = createIncludeListFeatureFlagHook(
  {
    GaKey: 'ACTIVE_TEAMS_GRAPH_GA',
    TenantsKey: 'ACTIVE_TEAMS_GRAPH_TENANTS',
  },
  true // tenantSetting will override users tenant setting
);

export const useAiDemoFeatureFlag = createIncludeListFeatureFlagHook(
  {
    GaKey: 'AI_DEMO_GA',
    TenantsKey: 'AI_DEMO_TENANTS',
  },
  true // tenantSetting will override users tenant setting
);

export const usePureKeyActivities = createIncludeListFeatureFlagHook({
  GaKey: 'PURE_KEY_ACTIVITIES_GA',
  TenantsKey: 'PURE_KEY_ACTIVITIES_TENANTS',
});

export const usePureKeyActivitiesStep2 = createIncludeListFeatureFlagHook({
  GaKey: 'PURE_KEY_ACTIVITIES_STEP2_GA',
  TenantsKey: 'PURE_KEY_ACTIVITIES_STEP2_TENANTS',
});
