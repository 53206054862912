import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
} from 'recharts';
import { friendlyUsername } from '../../../services/friendlyUsername';
import { useTranslation } from 'react-i18next';
import { CustomizedAxisTick } from './teamCommitmentFullfilmentChart/CustomizedAxisTick';
import { ChartLegend, CustomTooltip } from '../../graphs/CustomTooltip';

interface UserCommitmentAndAchievement {
  user2: {
    id: string;
    domainId: { itemId: string };
    name?: string | null;
    displayName?: string | null;
    email?: string | null;
  };
  commitment?: number | null;
  achievement: number;
}

export interface AkpiCommitmentData {
  name: string;
  id: string;
  teamMembers: UserCommitmentAndAchievement[];
}

interface Props {
  data: AkpiCommitmentData[];
  graphHeight?: number;
}

export const progressColor = {
  achieved: '#1890ff',
  achievedBorder: '#0763b7',
  committed: 'rgba(180, 180, 180, 0.2)',
  committedBorder: 'rgba(180, 180, 180, 0.4)',
  overachieved: '#0D4880',
};

export const TeamCommitmentFulfillmentChart = ({
  data,
  graphHeight = 150,
}: Props) => {
  const { t } = useTranslation();
  const grouped = transform(data);

  return (
    <div>
      <ResponsiveContainer debounce={300} width="100%" height={graphHeight}>
        <BarChart data={grouped} barGap={-10} barSize={20}>
          <Tooltip
            cursor={{ fill: '#f6f6f6' }}
            content={({ label: _label, ...props }) => (
              <CustomTooltip {...props} />
            )}
          />
          <XAxis
            dataKey="friendlyName"
            textAnchor="end"
            axisLine={true}
            interval={0}
            height={56}
            tick={<CustomizedAxisTick />}
          />
          <YAxis />
          <Bar
            dataKey={'commitment'}
            name={t('common.commitment_one')}
            fill={progressColor.committed}
            stroke={progressColor.committedBorder}
            isAnimationActive={false}
          />
          <Bar
            dataKey={'achievement'}
            name={t('TeamCommitmentFulfillmentChart.achieved') as string}
            fill={progressColor.achieved}
            stroke={progressColor.achievedBorder}
            isAnimationActive={false}
          />
        </BarChart>
      </ResponsiveContainer>
      <div className="flx">
        <div className="ml--auto">
          <ChartLegend
            color={progressColor.committed}
            name={t('common.commitment_one')}
          />
          <ChartLegend
            color={progressColor.achieved}
            name={t('TeamCommitmentFulfillmentChart.achieved')}
          />
        </div>
      </div>
    </div>
  );
};

const transform = (data: AkpiCommitmentData[]) => {
  const memberCommitmentsAndAchievements = data.flatMap(({ teamMembers }) => {
    return teamMembers.map((u) => {
      const user = {
        ...u,
        friendlyName: friendlyUsername(u.user2),
        commitment: u.commitment ?? 0,
      };
      return user;
    });
  });

  const memberCommitmentsAndAchievementsGroupedByUser =
    memberCommitmentsAndAchievements.reduce(
      (acc, u) => ({
        ...acc,
        [u.user2.domainId.itemId]: {
          ...acc[u.user2.domainId.itemId],
          ...u,
          commitment:
            (acc[u.user2.domainId.itemId]?.commitment ?? 0) + u.commitment,
          achievement:
            (acc[u.user2.domainId.itemId]?.achievement ?? 0) + u.achievement,
        },
      }),
      {} as { [key: string]: UserCommitmentAndAchievement }
    );

  return Object.values(memberCommitmentsAndAchievementsGroupedByUser);
};
