import { Divider, Radio, Select } from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTags } from '../../../../../../../hooks/useGetTags';
import { useTenantDetails } from '../../../../../../../hooks/useTenantDetails';

import { TagImage } from '../../../../../setup/overviewPage/labels/TagImage';
import { SorterConjunction, tagsContext } from '../../../TeamSprintProvider';

import { gql, useLazyQuery } from '@apollo/client';
import { stringSort } from '../../../../../../../services/stringSort';
import { TagFilterTenantInitiativesQueryDocument } from '../../../../../../../generated/graphql';
import { InitiativeTag } from '../../../../../../../components/initiative/InitiativeTag';
import { Icons } from '../../../../../../../components/initiatives/InitiativeIcons';

interface Props {
  teamId: string;
}

export const TagFilter = ({ teamId }: Props) => {
  const [fetchTenantInitiatives, { data }] = useLazyQuery(
    TagFilterTenantInitiativesQueryDocument
  );

  const tenantInitiatives =
    data?.joinedTenantInitiativesForTeam.initiatives ?? [];

  const { Option, OptGroup } = Select;
  const { tags } = useGetTags(teamId);
  const { features } = useTenantDetails();

  useEffect(() => {
    if (features.tenantInitiativesEnabled)
      fetchTenantInitiatives({ variables: { teamId } });
  }, [fetchTenantInitiatives, teamId, features]);

  const activeInitiativeOptions = tenantInitiatives
    .filter((ti) => !ti.metadata.archived)
    .toSorted((a, b) => stringSort(a.tag.title, b.tag.title))
    .map((initiative) => ({
      key: initiative.id,
      value: initiative.id,
      label: (
        <InitiativeTag
          title={initiative.tag.title}
          borderColor={initiative.tag.colorCode}
          icon={Icons[initiative.tag.iconId as keyof typeof Icons]}
          completed={initiative.metadata.completedAt != null}
          archived={initiative.metadata.archived}
        />
      ),
    }));

  const {
    setTagsForFiltering,
    tagsSelectedForFiltering,
    andOrChoice: value,
    setAndOrChoiceForFiltering,
  } = useContext(tagsContext) as any;
  const { t } = useTranslation();

  const showOptionGroups = features.tenantInitiativesEnabled;

  return (
    <>
      <div
        className="MitemFilters__filterContainer mr--s"
        data-intercom-target="Sprint View Table Owner Filter"
      >
        <div className="flx">
          <h5>{t('TagFilter.title')}&nbsp;</h5>
        </div>

        <Select
          popupMatchSelectWidth={false}
          placeholder={t('TagFilter.addTag')}
          mode="multiple"
          defaultValue={[]}
          onChange={setTagsForFiltering}
          value={tagsSelectedForFiltering ?? []}
          className="MitemFilter__Select MitemFilter__TagFilter"
          showSearch
          filterOption={(input, option) => {
            if (!option) return false;
            if ('options' in option) return false;
            return (option.label as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          dropdownRender={(menu) => (
            <>
              <div
                // These preventDefaults are needed to avoid dropdown closing on every click in header or footer
                // - that's the only working solution I came up with
                onMouseDown={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onClick={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                <div
                  className="flx flx--jc-center"
                  hidden={
                    !tagsSelectedForFiltering ||
                    tagsSelectedForFiltering?.length < 2
                  }
                >
                  <div className="tag-filter--radio flx">
                    <div
                      onClick={() =>
                        setAndOrChoiceForFiltering(SorterConjunction.AND)
                      }
                    >
                      <Radio checked={value === SorterConjunction.AND}>
                        <span className="capitalize">{t('TagFilter.and')}</span>
                      </Radio>
                    </div>
                    <div
                      onClick={() =>
                        setAndOrChoiceForFiltering(SorterConjunction.OR)
                      }
                    >
                      <Radio checked={value === SorterConjunction.OR}>
                        <span className="capitalize">{t('TagFilter.or')}</span>
                      </Radio>
                    </div>
                  </div>
                </div>
              </div>
              <Divider
                className={`mb--s mt--xs ${
                  !tagsSelectedForFiltering ||
                  tagsSelectedForFiltering?.length < 2
                    ? 'd-none'
                    : ''
                }`}
              />
              {menu}
            </>
          )}
        >
          {showOptionGroups ? (
            <>
              <OptGroup label={t('TagFilter.initiatives')}>
                {activeInitiativeOptions.map((option) => (
                  <Option key={option.key} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </OptGroup>

              <OptGroup label={t('TagFilter.teamTag')}>
                {tags.map((tag) => (
                  <Option key={tag.id} value={tag.id} label={tag.name}>
                    <TagImage tag={tag} />
                  </Option>
                ))}
              </OptGroup>
            </>
          ) : (
            <>
              {tags.map((tag) => (
                <Option key={tag.id} value={tag.id} label={tag.name}>
                  <TagImage tag={tag} />
                </Option>
              ))}
            </>
          )}
        </Select>
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TAG_FILTER_TENANT_INITIATIVE_QUERY = gql`
  query tagFilterTenantInitiativesQuery($tenantId: ID, $teamId: ID!) {
    joinedTenantInitiativesForTeam(tenantId: $tenantId, teamId: $teamId) {
      initiatives {
        id
        tag {
          title
          colorCode
          iconId
        }
        metadata {
          completedAt
          archived
        }
      }
    }
  }
`;
