import { MitemStatus } from '../generated/graphql';

export type SimpleStatus = 'COMPLETED' | 'OVERDUE' | 'UPCOMING' | 'ARCHIVED';

const statusMap: Record<MitemStatus, SimpleStatus> = {
  COMPLETED: 'COMPLETED',
  OVERDUE: 'OVERDUE',
  PLANNED: 'UPCOMING',
  ACTIVE: 'UPCOMING',
  NOT_COMPLETED: 'OVERDUE',
  POSTPONED: 'OVERDUE',
  ARCHIVED: 'ARCHIVED',
};

export const getSimpleStatus = (mitemStatus: MitemStatus): SimpleStatus => {
  return statusMap[mitemStatus];
};
