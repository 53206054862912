import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { CurrentUserDocument } from '../generated/graphql';

export const useMe = () => {
  return useQuery(CurrentUserDocument);
};
export const useMeLazy = () => {
  return useLazyQuery(CurrentUserDocument);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      name
      initials
      id
      domainId {
        itemId
        tenantId
      }
      displayName
      email
      locale
      memberOf {
        roles
        team {
          id
          domainId {
            itemId
            tenantId
          }
          data {
            id
            domainId {
              itemId
              tenantId
            }
            name
            accelerationDay
          }
        }
      }
      metadata {
        createdAt
        signedUpAt
      }
    }
  }
`;
