import { Select } from 'antd';
import { stringSort } from '../../../../services/stringSort';
import { toMap } from '../../../../services/toMap';
import { gql } from '@apollo/client';
import { TeamSelector__Team2Fragment } from '../../../../generated/graphql';

interface Props {
  teams: TeamSelector__Team2Fragment[];
  value: TeamSelector__Team2Fragment | null;
  onChange: (team: TeamSelector__Team2Fragment) => void;
}

export const TeamSelector = ({ teams, value, onChange }: Props) => {
  teams = teams
    .filter((t) => !t.umbrellaTeam)
    .sort((teamA, teamB) => {
      return stringSort(teamA.name, teamB.name);
    });

  const teamLookup = toMap(teams, (t) => t.domainId.itemId);

  return (
    <Select
      data-intercom-target="MIG alignment select team"
      value={value?.domainId.itemId}
      onSelect={(_: any, option: any) => {
        // todo: propper typings for onSelect --> https://github.com/ant-design/ant-design/issues/34201
        const team = teamLookup[option.value];
        return onChange(team);
      }}
      showSearch
      style={{ width: 200 }}
      optionFilterProp="children"
      filterOption={(input, option) => {
        return option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      {teams?.map((t) => (
        <Select.Option
          key={t.domainId.itemId}
          value={t.domainId.itemId}
          title={t.name}
        >
          {t.name}
        </Select.Option>
      ))}
    </Select>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_SELECTOR__TEAM2_FRAGMENT = gql`
  fragment TeamSelector__Team2 on Team2 {
    id
    domainId {
      itemId
    }
    name
    umbrellaTeam
    parentTeam {
      id
      domainId {
        itemId
      }
    }
  }
`;
