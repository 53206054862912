import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GetTeamMembersForEditActivityFormDocument } from '../../../../../generated/graphql';
import { friendlyUsername } from '../../../../../services/friendlyUsername';
import { DisplayName } from '../../../../DisplayName';
import { UserAvatar } from '../../../../UserAvatar';

interface Props {
  teamId: string;
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  initialOwner?: {
    id: string;
    domainId: {
      itemId: string;
    };
    name?: string | null;
    email: string;
    displayName?: string | null;
    initials: string;
  };
}

export const MemberSelectFormItem = ({
  teamId,
  value,
  onChange,
  initialOwner,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(
    GetTeamMembersForEditActivityFormDocument,
    {
      variables: { teamId },
    }
  );

  const members = data?.team2?.members ?? [];

  // Check if aligned owner is still in team
  const isOwnerStillMember = initialOwner
    ? members.some(
        (m) => m.user.domainId.itemId === initialOwner.domainId.itemId
      )
    : true;

  // Create options array with both active and former members
  const options = [
    {
      label: 'Team members',
      options: members.map((m) => ({
        label: (
          <div>
            <span className="mr--s">
              <UserAvatar user={m.user.data} size="small" />
            </span>
            <DisplayName user={m.user.data} />
          </div>
        ),
        value: m.user.domainId.itemId,
        userName: friendlyUsername(m.user.data),
      })),
    },
    ...(initialOwner && !isOwnerStillMember
      ? [
          {
            label: 'No longer in team',
            options: [
              {
                label: (
                  <div className="txt--secondary">
                    <span className="mr--s">
                      <UserAvatar user={initialOwner} size="small" />
                    </span>
                    <DisplayName user={initialOwner} />
                  </div>
                ),
                value: initialOwner.domainId.itemId,
                userName: friendlyUsername(initialOwner),
              },
            ],
          },
        ]
      : []),
  ];

  return (
    <Select
      placeholder={t('common.owner')}
      value={value}
      onChange={onChange}
      loading={loading}
      showSearch
      options={options}
      disabled={disabled}
      optionFilterProp="userName"
      filterOption={(inputValue, option) =>
        option?.label
          .toLocaleLowerCase()
          .includes(inputValue.toLocaleLowerCase()) ?? false
      }
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KA_FORM__GET_MEMBERS = gql`
  query GetTeamMembersForEditActivityForm($teamId: ID!) {
    team2(teamId: $teamId) {
      archived
      name
      id
      domainId {
        tenantId
        itemId
      }
      rev
      members {
        roles
        user {
          id
          domainId {
            itemId
          }
          data {
            id
            domainId {
              itemId
            }
            name
            email
            displayName
            initials
          }
        }
      }
    }
  }
`;
