import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { useMutation, gql } from '@apollo/client';
import { FullscreenModal } from '../../FullscreenModal';
import {
  UpdateWeeklyKeyActivityDocument,
  GetTeamKeyActivitiesPreviewDocument,
} from '../../../generated/graphql';
import {
  deaccumulatePeriodData,
  toCumulativePeriods,
} from '../../../services/akpiUtils';
import { without } from '../../../services/without';
import { KeyActivityModalState } from '../KeyActivitySetupPage';
import {
  KeyActivityData,
  WeeklyKeyActivity,
} from './components/WeeklyKeyActivity';
import { useWeeklyKeyActivityLazy } from './hooks/useWeeklyKeyActivity';
import { useClosestPeriods } from '../../../hooks/useClosestPeriods';
import { howweErrorParser } from '../../../services/howweErrorParser';
import { showNotification } from '../../../services/fetchNotificationProperties';
import dayjs from 'dayjs';

interface Props {
  teamId: string;
  tenantId?: string;
  keyActivityModalState: KeyActivityModalState | null;
  onClose: () => void;
}

export interface PeriodWithMandatoryGoal {
  targetDate: string;
  label: string;
  goal: number;
  actual?: number;
  timePeriodIndex: number;
}

export const EditWeeklyModal = ({
  keyActivityModalState,
  onClose,
  teamId,
  tenantId,
}: Props) => {
  const { t } = useTranslation();
  const { currentPeriod } = useClosestPeriods(teamId, tenantId);

  const { fetchWeeklyKeyActivity, weeklyKeyActivity } =
    useWeeklyKeyActivityLazy();

  useEffect(() => {
    if (
      keyActivityModalState?.type === 'EditWeeklyKeyActivity' &&
      keyActivityModalState.id
    ) {
      fetchWeeklyKeyActivity({
        variables: {
          teamId,
          weeklyKeyActivityId: keyActivityModalState.id,
          tenantId,
        },
      });
    }
  }, [fetchWeeklyKeyActivity, keyActivityModalState, teamId, tenantId]);

  const [
    updateWeeklyKeyActivity,
    { error: updateError, loading: updateLoading },
  ] = useMutation(UpdateWeeklyKeyActivityDocument, {
    onCompleted: (response) => {
      showNotification('success', {
        message: <strong>{t('EditKeyActivityModal.saveSuccess')}</strong>,
        description: response.updateWeeklyKeyActivity.name,
      });

      onClose();
    },
    refetchQueries: () => [
      {
        query: GetTeamKeyActivitiesPreviewDocument,
        variables: { teamId, tenantId },
      },
    ],
  });

  const isOpen =
    keyActivityModalState?.type === 'EditWeeklyKeyActivity' &&
    keyActivityModalState.id != null;

  useEffect(() => {
    (window as any).Intercom?.('update', {
      hide_default_launcher: isOpen,
    });
  }, [isOpen]);

  const handleSave = (keyActivityData: KeyActivityData) => {
    const periodDataToSave = toCumulativePeriods(
      keyActivityData.periodData ?? []
    ) as PeriodWithMandatoryGoal[];
    const weeklyKeyActivityToSave = {
      ...without(
        keyActivityData,
        'startDate',
        'endDate',
        'supportsMigsDetails'
      ),
      periodData:
        periodDataToSave?.map((l) =>
          without(l, 'label', 'actual', 'timePeriodIndex')
        ) ?? [],
      supportsMigs: keyActivityData.supportsMigsDetails?.map((mig) => ({
        domainId: {
          ...without(mig.domainId, '__typename'),
          teamId: mig.team2.domainId.itemId,
        },
      })),
    };

    if (keyActivityModalState?.id == null) {
      throw new Error(
        'keyActivityId is null or undefined when trying to edit a weekly sprint activity'
      );
    }

    updateWeeklyKeyActivity({
      variables: {
        teamId,
        weeklyKeyActivityId: keyActivityModalState.id,
        weeklyKeyActivity: weeklyKeyActivityToSave,
        tenantId,
      },
    });
  };

  const startDateDisabled =
    weeklyKeyActivity?.startDate != null &&
    dayjs(weeklyKeyActivity.startDate).isSameOrBefore(
      currentPeriod?.timePeriodStartDate
    );

  return (
    <FullscreenModal
      open={isOpen}
      onCancel={onClose}
      keyboard={false}
      destroyOnClose
      title={
        <div className="flx flx--column">
          <Typography.Text>{t('EditKeyActivityModal.title')}</Typography.Text>
        </div>
      }
      styles={{ body: { padding: 0 } }}
      footer={null}
    >
      <WeeklyKeyActivity
        key={weeklyKeyActivity?.id}
        teamId={teamId}
        tenantId={tenantId}
        weeklyKeyActivityData={
          weeklyKeyActivity
            ? {
                ...weeklyKeyActivity,
                periodData: deaccumulatePeriodData(
                  weeklyKeyActivity.periodData
                ),
              }
            : undefined
        }
        onClose={onClose}
        handleSave={handleSave}
        saveErrors={howweErrorParser(updateError)}
        saveLoading={updateLoading}
        startDateDisabled={startDateDisabled}
      />
    </FullscreenModal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UPDATE_WEEKLY_KEY_ACTIVITY_2 = gql`
  mutation updateWeeklyKeyActivity(
    $teamId: ID!
    $weeklyKeyActivityId: ID!
    $weeklyKeyActivity: WeeklyKeyActivityInput!
    $tenantId: ID
  ) {
    updateWeeklyKeyActivity(
      teamId: $teamId
      weeklyKeyActivityId: $weeklyKeyActivityId
      weeklyKeyActivity: $weeklyKeyActivity
      tenantId: $tenantId
    ) {
      name
      id
      description
      acceptanceCriteria
      commitmentStrategyEnabled
      achievementDescriptionMandatory
      startDate
      endDate
      unit
      ignoreListedUsers
      periodData {
        targetDate
        goal
        actual
        historic
      }
      supportsMigsDetails {
        id
        name
        lastOutcomeDate
        domainId {
          itemId
          tenantId
        }
        team2 {
          id
          domainId {
            itemId
          }
          name
        }
      }
    }
  }
`;
