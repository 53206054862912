import { Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LearnMoreLink } from '../../../../components/LearnMoreLink';
import { gql } from '@apollo/client';
import { MigsMissingRelations__MigV2Fragment } from '../../../../generated/graphql';

interface Props {
  migsMissingRelations: MigsMissingRelations__MigV2Fragment[];
}

export const MigsMissingRelations = ({ migsMissingRelations }: Props) => {
  const { t } = useTranslation();

  const columns: ColumnProps<MigsMissingRelations__MigV2Fragment>[] = [
    {
      title: t('common.team'),
      dataIndex: ['team2', 'name'],
      render(name, mig) {
        return <Link to={`/team/${mig.team2.domainId.itemId}`}>{name}</Link>;
      },
    },
    { title: t('common.mostImportantGoal'), dataIndex: 'name' },
  ];

  return (
    <div>
      <div className="mb--xl">
        <Typography.Title
          level={5}
          className="mb--none mt--none mr"
          style={{ display: 'inline-block' }}
        >
          {t('MigsMissingRelations.shortDescription')}
        </Typography.Title>
        <LearnMoreLink urlTemplate="https://help.howwe.io/{{locale}}/articles/28903-how-to-create-and-edit-most-important-goals" />
      </div>
      <div className="txt--secondary mb">
        {t('MigsMissingRelations.description', {
          numberOfMissingMigs: migsMissingRelations.length,
        })}
      </div>
      <Table size="small" columns={columns} dataSource={migsMissingRelations} />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIGS_MISSING_RELATION__MIG_V2 = gql`
  fragment MigsMissingRelations__MigV2 on MigV2 {
    id
    name
    domainId {
      itemId
      teamId
      tenantId
    }
    team2 {
      id
      domainId {
        itemId
      }
      name
    }
  }
`;
