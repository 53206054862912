import { gql, useQuery } from '@apollo/client';
import { Select } from 'antd';
import { TeamSelector_TeamsDocument } from '../../../../generated/graphql';
import { stringSort } from '../../../../services/stringSort';

interface Props {
  value?: string | null;
  onChange?: (teamId: string) => void;
}

export const TeamSelect = ({ value, onChange }: Props) => {
  const { data } = useQuery(TeamSelector_TeamsDocument);

  const teams =
    data?.teams2.teams
      .filter((t) => !t.umbrellaTeam)
      .sort((teamA, teamB) => {
        return stringSort(teamA.name, teamB.name);
      }) ?? [];

  return (
    <Select
      value={value}
      onSelect={(_, option) => {
        return onChange?.(option.value);
      }}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => {
        return option
          ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          : false;
      }}
      options={teams.map((t) => ({ value: t.domainId.itemId, label: t.name }))}
    />
  );
};

export const TEAM_SELECTOR = gql`
  query teamSelector_teams($tenantId: ID) {
    teams2(tenantId: $tenantId) {
      teams {
        name
        id
        domainId {
          itemId
          tenantId
        }
        umbrellaTeam
        parentTeam {
          id
          domainId {
            itemId
            tenantId
          }
        }
      }
    }
  }
`;
