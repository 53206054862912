import Skeleton from 'react-loading-skeleton';
import { MigSectionSkeleton } from './components/migSection/MigSection.skeleton';
import './TeamOverview.page.less';

import { TeamOverviewSectionSkeleton } from './components/teamOverviewSection/TeamOverviewSection.skeleton';
import { Divider } from 'antd';

export const TeamOverviewSkeleton = () => {
  return (
    <div className="maxWidth">
      <div className="flx flx--jc-flx-end TeamOverviewPage__header"></div>
      <TeamOverviewSectionSkeleton />

      <Divider />
      <MigSectionSkeleton />
      <Divider />

      <h2 className="mb--l">
        <Skeleton width={'50%'} />
      </h2>
    </div>
  );
};
