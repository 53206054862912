import './labels.less';
import { LabelsTable } from './labels/LabelsTable';
import { TeamInformationList } from './TeamInformationList';
interface Props {
  teamId: string;
}

export const OverviewPage = ({ teamId }: Props) => {
  return (
    <div style={{ maxWidth: 1080 }}>
      <TeamInformationList teamId={teamId} />
      <div className="mt--l">
        <LabelsTable teamId={teamId} />
      </div>
    </div>
  );
};
