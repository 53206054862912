import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { ActivityCardsPage } from './cardPage/ActivityCards.page';
import { ActivityListPage } from './listPage/ActivityList.page';
import { Radio } from 'antd';
import dayjs from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import { GetActivitiesForTeamTableDocument } from '../../../generated/graphql';
import { useMemo, useState } from 'react';
import { ActivityFilters } from './ActivityFilter';
import { useActivityFiltersInParams } from './ActivityFilter/useTeamActivityFiltersInParams';
import './TeamActivitiesRoutes.less';

enum ActivityRoutes {
  List = 'list',
  Card = 'card',
}

interface Props {
  teamId: string;
}

export const TeamActivitiesRoutes = ({ teamId }: Props) => {
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const { applyFilterToActivities } = useActivityFiltersInParams();

  const { loading, data, fetchMore } = useQuery(
    GetActivitiesForTeamTableDocument,
    {
      variables: {
        teamId: teamId,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: startDate.add(4, 'month').format('YYYY-MM-DD'),
      },
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-only',
    }
  );

  const filteredActivities = applyFilterToActivities(
    data?.activitiesForTeam.sprintKeyActivities ?? []
  );

  const groupedActivities = useMemo(() => {
    return groupDeadlinesByMonth(filteredActivities);
  }, [filteredActivities]);

  // Get current view from path
  const currentView = location.pathname.endsWith('/card')
    ? ActivityRoutes.Card
    : ActivityRoutes.List;

  const options = [
    { label: 'List', value: ActivityRoutes.List },
    { label: 'Card', value: ActivityRoutes.Card },
  ];

  const handleViewChange = (newPath: ActivityRoutes) => {
    const params = new URLSearchParams(location.search);
    history.push(`${match.url}/${newPath}?${params.toString()}`);
  };

  return (
    <div className="TeamActivitiesRoutes">
      <div className="flx flx--jc-space-between maxWidth">
        <div className="TeamActivitiesRoutes__filters">
          <ActivityFilters
            mitems={data?.activitiesForTeam.sprintKeyActivities}
            teamId={teamId}
          />
        </div>
        <Radio.Group
          options={options}
          onChange={({ target: { value } }) => handleViewChange(value)}
          value={currentView}
          optionType="button"
          className="mt--l"
        />
      </div>

      <Switch>
        <Route exact path={match.path + '/list'}>
          <ActivityListPage
            loading={loading}
            fetchMoreLoading={fetchMoreLoading}
            teamId={teamId}
            startDate={startDate}
            onJumpToMonth={setStartDate}
            groupedActivities={groupedActivities}
            fetchMore={(startDate, endDate) => {
              setFetchMoreLoading(true);
              return fetchMore({
                variables: {
                  teamId: teamId,
                  startDate: startDate.format('YYYY-MM-DD'),
                  endDate: endDate.format('YYYY-MM-DD'),
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  setFetchMoreLoading(false);
                  if (!fetchMoreResult) return prev;

                  const existingActivities =
                    prev.activitiesForTeam.sprintKeyActivities ?? [];
                  const newActivities =
                    fetchMoreResult.activitiesForTeam.sprintKeyActivities ?? [];

                  const existingIds = new Set(
                    existingActivities.map((a) => a.id)
                  );
                  const uniqueNewActivities = newActivities.filter(
                    (a) => !existingIds.has(a.id)
                  );
                  return {
                    __typename: prev.__typename,
                    activitiesForTeam: {
                      id: teamId,
                      __typename: prev.activitiesForTeam.__typename,
                      sprintKeyActivities: [
                        ...existingActivities,
                        ...uniqueNewActivities,
                      ],
                    },
                  };
                },
              });
            }}
          />
        </Route>
        <Route exact path={match.path + '/card'}>
          <ActivityCardsPage
            teamId={teamId}
            startDate={startDate}
            groupedActivities={groupedActivities}
            onJumpToMonth={setStartDate}
            fetchMore={(startDate, endDate) => {
              setFetchMoreLoading(true);
              return fetchMore({
                variables: {
                  teamId: teamId,
                  startDate: startDate.format('YYYY-MM-DD'),
                  endDate: endDate.format('YYYY-MM-DD'),
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  setFetchMoreLoading(false);
                  if (!fetchMoreResult) return prev;

                  const existingActivities =
                    prev.activitiesForTeam.sprintKeyActivities ?? [];
                  const newActivities =
                    fetchMoreResult.activitiesForTeam.sprintKeyActivities ?? [];

                  const existingIds = new Set(
                    existingActivities.map((a) => a.id)
                  );
                  const uniqueNewActivities = newActivities.filter(
                    (a) => !existingIds.has(a.id)
                  );
                  return {
                    __typename: prev.__typename,
                    activitiesForTeam: {
                      id: teamId,
                      __typename: prev.activitiesForTeam.__typename,
                      sprintKeyActivities: [
                        ...existingActivities,
                        ...uniqueNewActivities,
                      ],
                    },
                  };
                },
              });
            }}
          />
        </Route>
        <Redirect exact from={match.url + '/'} to={match.url + '/list'} />
      </Switch>
    </div>
  );
};

export const getMonthsBetween = (
  start: dayjs.Dayjs,
  end: dayjs.Dayjs
): string[] => {
  const months: string[] = [];
  let current = start.startOf('month');

  while (current.isBefore(end) || current.isSame(end, 'month')) {
    months.push(current.format('YYYY-MM'));
    current = current.add(1, 'month');
  }

  return months;
};

type WithDeadline = { deadline: string };
type WithDeadlineAt = { deadlineAt: string };

export const groupDeadlinesByMonth = <T extends WithDeadline | WithDeadlineAt>(
  activities?: T[] | null
) => {
  const groupedActivitiesMap = new Map<string, T[]>();

  activities
    ?.toSorted((a, b) => {
      const dateA = 'deadline' in a ? a.deadline : a.deadlineAt;
      const dateB = 'deadline' in b ? b.deadline : b.deadlineAt;
      return dateA.localeCompare(dateB);
    })
    .forEach((activity) => {
      const date =
        'deadline' in activity ? activity.deadline : activity.deadlineAt;
      const monthKey = dayjs(date).format('YYYY-MM');

      if (!groupedActivitiesMap.has(monthKey)) {
        groupedActivitiesMap.set(monthKey, []);
      }

      groupedActivitiesMap.get(monthKey)?.push(activity);
    });

  return groupedActivitiesMap;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_ACTIVITIES_ROUTES__MITEM = gql`
  fragment TeamActivitiesRoutes_Mitem on Mitem {
    id
    name
    deadline
    status
    ...ActivityList_Mitem
    ...ActivityCardsPage_Mitem
    ...ActivityFilter_Mitem
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_MITEMS = gql`
  query getActivitiesForTeamTable(
    $teamId: ID!
    $startDate: String
    $endDate: String
    $archived: Boolean
  ) {
    activitiesForTeam(
      teamId: $teamId
      archived: $archived
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      sprintKeyActivities {
        id
        ...TeamActivitiesRoutes_Mitem
      }
    }
  }
`;
