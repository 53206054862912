import dayjs, { Dayjs } from 'dayjs';
import './ActivityList.page.less';
import { useEffect } from 'react';
import { Btn } from '../../../../components/Button';
import { ApolloQueryResult, gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  ActivityList_MitemFragment,
  ActivityListMilestoneQueryDocument,
  GetActivitiesForTeamTableQuery,
  GetSprintKeyActivitiesForMilestoneActivityListDocument,
} from '../../../../generated/graphql';
import { groupDeadlinesByMonth } from '../TeamActivitiesRoutes';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { MonthQuickPicker } from '../../common/MonthQuickPicker';
import { ActivityTable } from './ActivityTable/ActivityTable';
import { useActivityList } from '../hooks/useActivityList';
import { NoMilestoneActivities } from '../components/NoMilestoneActivities';
import { MilestoneSidebar } from './MilestoneSidebar';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../../../ModalProvider';

interface Props {
  teamId: string;
  loading: boolean;
  fetchMoreLoading: boolean;
  startDate: Dayjs;
  onJumpToMonth: (date: Dayjs) => void;
  groupedActivities: Map<string, ActivityList_MitemFragment[]>;
  fetchMore: (
    startDate: Dayjs,
    endDate: Dayjs
  ) => Promise<ApolloQueryResult<GetActivitiesForTeamTableQuery>>;
}

const dateToDefaultRange = (date: Dayjs) => ({
  start: date,
  end: date.add(2, 'months').endOf('month'),
});

export const ActivityListPage = ({
  teamId,
  loading,
  fetchMoreLoading,
  startDate,
  groupedActivities: _groupedActivities,
  fetchMore,
  onJumpToMonth,
}: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { data: milestoneData, loading: milestonesLoading } = useQuery(
    ActivityListMilestoneQueryDocument,
    {
      variables: {
        teamId: teamId,
      },
    }
  );

  const [
    fetchActivitiesForMilestone,
    {
      data: activitiesForMilestoneData,
      loading: activitiesForMilestoneLoading,
    },
  ] = useLazyQuery(GetSprintKeyActivitiesForMilestoneActivityListDocument);

  const {
    milestonesByMonth,
    selectedMilestone,
    selectMilestone,
    activitiesForMilestone,
    setMonthRange,
    monthRange,
    monthsToDisplay,
  } = useActivityList(
    startDate,
    activitiesForMilestoneData?.sprintKeyActivitiesForMilestone
      .sprintKeyActivitiesForTeam,
    milestoneData?.milestonesWithLinksForTeam.milestones
  );

  const groupedActivities = selectedMilestone
    ? groupDeadlinesByMonth(activitiesForMilestone)
    : _groupedActivities;

  useEffect(() => {
    setMonthRange(dateToDefaultRange(startDate));
  }, [startDate, setMonthRange]);

  return (
    <div>
      <div className="mt--xl flx maxWidth">
        <div className="flx--1 ActivityList__main">
          <h3>{t('common.activities')}</h3>
          {(activitiesForMilestoneLoading || loading) && (
            <>
              <ActivityTable.Skeleton showActions />
              <ActivityTable.Skeleton />
            </>
          )}
          {!activitiesForMilestoneLoading &&
            selectedMilestone &&
            monthsToDisplay.length === 0 && (
              <NoMilestoneActivities
                onOpenCreateActivityModal={() =>
                  openModal({
                    type: 'createActivity',
                    teamId: teamId,
                    formType: {
                      type: 'simple',
                      prefilledValues: selectedMilestone && {
                        supportsMilestoneId: selectedMilestone.id,
                        supportsInitiativeId:
                          selectedMilestone.metadata.supportsInitiatives[0].id,
                      },
                    },
                    refetchQueries: selectedMilestone && [
                      {
                        query:
                          GetSprintKeyActivitiesForMilestoneActivityListDocument,
                        variables: {
                          milestoneId: selectedMilestone.domainId.itemId,
                          teamId: teamId,
                        },
                      },
                    ],
                  })
                }
              />
            )}
          {!activitiesForMilestoneLoading &&
            !loading &&
            monthsToDisplay.map((monthKey, index) => (
              <ActivityTable
                teamId={teamId}
                key={monthKey}
                className="mt--xl"
                activities={groupedActivities.get(monthKey) ?? []}
                header={
                  <ActivityTable.Header
                    startDate={dayjs(monthKey)}
                    actions={
                      <div className="flx">
                        {index === 0 && (
                          <>
                            <MonthQuickPicker
                              btnProps={{
                                className: 'ml',
                                disabled: selectedMilestone != null,
                              }}
                              onConfirm={(date) =>
                                onJumpToMonth(date.startOf('month'))
                              }
                            />
                            <Btn
                              loading={fetchMoreLoading}
                              disabled={selectedMilestone != null}
                              icon={<ArrowUpOutlined />}
                              size="small"
                              type="link"
                              onClick={() => {
                                const previousMonth = monthRange.start.subtract(
                                  1,
                                  'months'
                                );
                                fetchMore(
                                  previousMonth.startOf('month'),
                                  previousMonth.endOf('month')
                                ).then(() => {
                                  setMonthRange({
                                    ...monthRange,
                                    start: previousMonth.startOf('month'),
                                  });
                                });
                              }}
                            ></Btn>
                          </>
                        )}
                        <Btn
                          size="small"
                          type="link"
                          className="ml--auto"
                          onClick={() =>
                            openModal({
                              type: 'createActivity',
                              teamId: teamId,
                              formType: {
                                type: 'simple',
                                prefilledValues: selectedMilestone && {
                                  supportsMilestoneId: selectedMilestone.id,
                                  supportsInitiativeId:
                                    selectedMilestone.metadata
                                      .supportsInitiatives[0].id,
                                },
                              },
                              refetchQueries: selectedMilestone && [
                                {
                                  query:
                                    GetSprintKeyActivitiesForMilestoneActivityListDocument,
                                  variables: {
                                    milestoneId:
                                      selectedMilestone.domainId.itemId,
                                    teamId: teamId,
                                  },
                                },
                              ],
                            })
                          }
                        >
                          {t('ActivityListPage.addActivity')}
                        </Btn>
                      </div>
                    }
                  />
                }
              />
            ))}
          <Btn
            loading={fetchMoreLoading}
            disabled={selectedMilestone != null}
            icon={<ArrowDownOutlined />}
            size="small"
            className="mt"
            onClick={() => {
              const nextMonth = monthRange.end.add(1, 'months');
              fetchMore(
                nextMonth.startOf('month'),
                nextMonth.endOf('month')
              ).then(() => {
                setMonthRange({
                  ...monthRange,
                  end: nextMonth.endOf('month'),
                });
              });
            }}
          >
            {t('ActivityListPage.loadMore')}
          </Btn>
        </div>
        <div className="ActivityList__sidebar">
          <MilestoneSidebar
            teamId={teamId}
            milestonesLoading={milestonesLoading}
            milestonesByMonth={milestonesByMonth}
            selectedMilestone={selectedMilestone}
            onSelectMilestone={(milestoneId) => {
              selectMilestone(milestoneId);
              if (milestoneId && teamId) {
                fetchActivitiesForMilestone({
                  variables: { milestoneId, teamId: teamId },
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ACTIVITY_LIST__MITEM = gql`
  fragment ActivityList_Mitem on Mitem {
    id
    name
    deadline
    owner2 {
      id
      name
      email
      initials
      displayName
      domainId {
        itemId
      }
    }
    ...ActivityTable_Mitem
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ActivityList_Milestones = gql`
  query activityListMilestoneQuery($teamId: ID!) {
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        domainId {
          itemId
          tenantId
        }
        name
        assignedTo {
          id
          data {
            id
            domainId {
              itemId
            }
            name
            email
            displayName
          }
        }
        description
        deadlineAt
        metadata {
          completedAt
          status
          supportsInitiatives {
            id
            domainId {
              itemId
              tenantId
            }
          }
        }
        ...MilestoneSidebar_MilestoneWithLinks
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINT_KEY_ACTIVITIES_FOR_ACTIVITY_LIST = gql`
  query getSprintKeyActivitiesForMilestoneActivityList(
    $milestoneId: ID!
    $teamId: ID!
  ) {
    sprintKeyActivitiesForMilestone(milestoneId: $milestoneId) {
      sprintKeyActivitiesForTeam(teamId: $teamId) {
        id
        ...TeamActivitiesRoutes_Mitem
      }
    }
  }
`;
