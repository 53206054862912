import { Modal } from 'antd';
import { CreateActivitySimpleVersion } from './forms/CreateActivitySimpleVersion';
import { PureQueryOptions } from '@apollo/client';
import {
  ActivityFullFormValues,
  CreateActivityFullVersion,
} from './forms/CreateActivityFullVersion';

interface Props {
  open: boolean;
  teamId: string;
  refetchQueries?: Array<string | PureQueryOptions>;
  formType?:
    | {
        type: 'simple';
        prefilledValues?: any;
      }
    | {
        type: 'full';
        prefilledValues?: ActivityFullFormValues;
      };
  onCancel: () => void;
  onComplete?: () => void;
}

export const CreateActivityModal = ({
  open,
  teamId,
  refetchQueries,
  formType,
  onCancel,
  onComplete,
}: Props) => {
  return (
    <Modal
      open={open}
      title={<h3 className="mb--l">Create Activity</h3>}
      width={formType?.type === 'full' ? 650 : 500}
      footer={null}
      onCancel={onCancel}
    >
      {formType?.type === 'full' ? (
        <CreateActivityFullVersion
          teamId={teamId}
          onSuccess={onComplete}
          initialValues={formType.prefilledValues}
          refetchQueries={refetchQueries}
        />
      ) : (
        <CreateActivitySimpleVersion
          teamId={teamId}
          onSuccess={onComplete}
          initialValues={formType?.prefilledValues}
          refetchQueries={refetchQueries}
        />
      )}
    </Modal>
  );
};
