import { Dayjs } from 'dayjs';
import { useQueryParams } from '../../common/hooks/useQueryParams';
import { MitemStatus } from '../../../../generated/graphql';
import { getSimpleStatus, SimpleStatus } from '../../../../services/mitemUtils';

export const useActivityFiltersInParams = () => {
  const { queryParams, setParam } = useQueryParams();

  const currentFilters = {
    status: new Set(
      queryParams.get('status')?.split(',') ?? [
        'COMPLETED',
        'OVERDUE',
        'UPCOMING',
      ]
    ),
    migId: queryParams.get('migId'),
    ownerId: queryParams.get('ownerId'),
    milestoneId: queryParams.get('milestoneId'),
    initiativeId: queryParams.get('initiativeId'),
    tags: queryParams.get('tags')?.split(','),
  };

  const setMitemStatusFilter = (status: SimpleStatus | null | undefined) => {
    const statusFilters = new Set(currentFilters.status);
    if (status) {
      if (statusFilters.has(status)) {
        statusFilters.delete(status);
      } else {
        statusFilters.add(status);
      }
    } else {
      statusFilters.clear();
    }

    setParam('status', Array.from(statusFilters).join(','), {
      deleteIfEmpty: false,
    });
  };

  const setTagFilter = (tags: string[] | null | undefined) => {
    setParam('tags', tags?.join(','));
  };

  const setMigIdFilter = (migId: string | null | undefined) => {
    setParam('migId', migId);
  };

  const setMitemOwnerIdFilter = (ownerId: string | null | undefined) => {
    setParam('ownerId', ownerId);
  };

  const setMilestoneIdFilter = (milestoneId: string | null | undefined) => {
    setParam('milestoneId', milestoneId);
  };

  const setInitiativeIdFilter = (initiativeId: string | null | undefined) => {
    setParam('initiativeId', initiativeId);
  };

  const setFromDate = (fromDate: Dayjs) => {
    queryParams.set('fromDate', fromDate.format('YYYY-MM'));
  };

  const applyFilterToActivities = <T extends FilterableActivity>(
    activities: T[]
  ) => {
    const filteredActivities = activities
      ?.filter((m) =>
        ownerFilterCompare(m.owner2.domainId.itemId, currentFilters.ownerId)
      )
      .filter((m) => migFilterCompare(m.supportedMigs, currentFilters.migId))
      .filter((m) => statusFilterCompare(m.status, currentFilters.status))
      .filter((m) =>
        milestoneFilterCompare(
          m.supportsMilestoneLinks,
          currentFilters?.milestoneId
        )
      )
      .filter((m) => initiativeFilterCompare(m, currentFilters?.initiativeId))
      .filter((m) => sprintKeyActivityHasTagMatch(m, currentFilters.tags));

    return filteredActivities;
  };

  return {
    currentFilters,
    setFilter: {
      fromDate: setFromDate,
      mitemStatus: setMitemStatusFilter,
      milestoneId: setMilestoneIdFilter,
      initiativeId: setInitiativeIdFilter,
      mitemOwnerId: setMitemOwnerIdFilter,
      migId: setMigIdFilter,
      tags: setTagFilter,
    },
    applyFilterToActivities,
  };
};

const migFilterCompare = (
  supportedMigs: { domainId: { itemId: string } }[] | null | undefined,
  migIdToMatch?: string | null
) => {
  return (
    migIdToMatch == null ||
    supportedMigs?.some((m) => m.domainId.itemId === migIdToMatch)
  );
};

const ownerFilterCompare = (
  ownerId: string,
  ownerIdToMatch?: string | null
) => {
  return ownerIdToMatch == null || ownerId === ownerIdToMatch;
};

const milestoneFilterCompare = (
  supportMilestones: { domainId: { itemId: string } }[] | null | undefined,
  milestoneIdToMatch?: string | null
) => {
  return (
    milestoneIdToMatch == null ||
    supportMilestones?.some((m) => m.domainId.itemId === milestoneIdToMatch)
  );
};

const initiativeFilterCompare = (
  activity: FilterableActivity,
  initiativeIdToMatch?: string | null
) => {
  return (
    initiativeIdToMatch == null ||
    activity.supportsInitiativeLinks?.some(
      (m) => m.domainId.itemId === initiativeIdToMatch
    ) ||
    activity.supportsMilestoneLinks?.some((m) =>
      m.data.metadata.supportsInitiatives?.some(
        (inner) => inner.domainId.itemId === initiativeIdToMatch
      )
    )
  );
};

const statusFilterCompare = (
  mitemStatus: MitemStatus,
  statusToMatch?: Set<string> | null
) => {
  if (statusToMatch == null || statusToMatch.size === 0) return true;

  const statusToCheck = getSimpleStatus(mitemStatus);

  return statusToMatch.has(statusToCheck);
};

export const sprintKeyActivityHasTagMatch = (
  activity: FilterableActivity,
  tagsToMatch?: string[]
): boolean => {
  const allTagsIds = activity?.tags.map((t) => t.id) ?? [];

  if (!tagsToMatch || tagsToMatch.length < 1) return true;

  return tagsToMatch.every((tid) => allTagsIds?.includes(tid));
};

type FilterableActivity = {
  owner2: {
    domainId: {
      itemId: string;
    };
  };
  status: MitemStatus;
  supportsMilestoneLinks:
    | {
        id: string;
        domainId: {
          itemId: string;
        };
        data: {
          metadata: {
            supportsInitiatives: {
              domainId: {
                itemId: string;
              };
            }[];
          };
        };
      }[]
    | null
    | undefined;
  supportsInitiativeLinks:
    | {
        id: string;
        domainId: {
          itemId: string;
        };
      }[]
    | null
    | undefined;
  supportedMigs:
    | {
        domainId: {
          itemId: string;
        };
      }[]
    | null
    | undefined;
  tags: {
    id: string;
  }[];
};
