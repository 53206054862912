import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Btn } from '../../../../../components/Button';
import { DisplayDateRange } from '../../../../../components/DisplayDateRange';
import { Week } from '../ActivityCards.page';
import { ActivityCard } from './ActivityCard';
import { MilestoneCard2 } from './MilestoneCard2';
import { useAccelerationDay } from '../../../../../hooks/useAccelerationDay';
import { isEndOfPeriod } from '../../../../../services/accelerationDayHelpers';
import dayjs from 'dayjs';
import { useDndMonitor, useDroppable } from '@dnd-kit/core';
import { useMemo, useState } from 'react';
import { Draggable } from './Draggable';
import cx from 'classnames';
import './WeekView.less';

interface Props {
  week: Week;
  teamId: string;
  onCreateActivity: (opts: { deadline: string }) => void;
}

export const WeekView = ({ week, teamId, onCreateActivity }: Props) => {
  const { data: accelerationDay } = useAccelerationDay(teamId);

  const [draggedActivityDeadline, setDraggedActivityDeadline] = useState<
    string | null
  >(null);

  // Compare the activity deadline with the week's end date
  const isDraggingToSameWeek = draggedActivityDeadline === week.to;

  useDndMonitor({
    onDragStart(event) {
      if (event.active.data.current?.activity) {
        setDraggedActivityDeadline(event.active.data.current.activity.deadline);
      }
    },
    onDragEnd() {
      setDraggedActivityDeadline(null);
    },
  });

  const isActivityAdditionAllowed = useMemo(() => {
    if (!accelerationDay) return false;

    const lastDayOfWeek = dayjs(week.to);
    if (lastDayOfWeek.isBefore(dayjs(), 'day')) return false;

    return isEndOfPeriod(lastDayOfWeek, accelerationDay);
  }, [accelerationDay, week.to]);

  const { isOver, setNodeRef } = useDroppable({
    id: `droppable-${week.from}`,
    data: { week },
    disabled: !isActivityAdditionAllowed,
  });

  const canDrop = isOver && isActivityAdditionAllowed && !isDraggingToSameWeek;

  return (
    <div ref={setNodeRef}>
      <div
        className={cx('mb--xs WeekView__title', {
          'WeekView__title--dropReady ': canDrop,
        })}
      >
        <DisplayDateRange
          startDate={week.from}
          endDate={week.to}
          className="font-size--sm"
        />
      </div>
      {week.activitiesAndMilestones.map((activityOrMilestone) =>
        activityOrMilestone.__typename === 'Mitem' ? (
          <Draggable
            key={activityOrMilestone.id}
            activity={activityOrMilestone}
          >
            <ActivityCard activity={activityOrMilestone} />
          </Draggable>
        ) : (
          <MilestoneCard2
            key={activityOrMilestone.id}
            milestone={activityOrMilestone}
          />
        )
      )}
      {week.activitiesAndMilestones.length > 0 && isActivityAdditionAllowed && (
        <div>
          <AddActivityBtn week={week} onCreateActivity={onCreateActivity}>
            Add Activity
          </AddActivityBtn>
        </div>
      )}
      {week.activitiesAndMilestones.length === 0 && (
        <div className="ActivityCardsPage__no-activities txt--secondary">
          <InfoCircleOutlined />
          <h5>No activities</h5>
          {isActivityAdditionAllowed && (
            <AddActivityBtn week={week} onCreateActivity={onCreateActivity} />
          )}
        </div>
      )}
    </div>
  );
};

interface AddActivityBtnProps {
  children?: React.ReactNode;
  week: Week;
  onCreateActivity: (opts: { deadline: string }) => void;
}
const AddActivityBtn = ({
  week,
  onCreateActivity,
  children,
}: AddActivityBtnProps) => (
  <Btn
    className="font-size--sm bold"
    icon={<PlusOutlined />}
    type="link"
    onClick={() =>
      onCreateActivity({
        deadline: week.to,
      })
    }
  >
    {children}
  </Btn>
);
