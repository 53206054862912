import { Timeline } from 'antd';
import { ActivityMilestone } from './ActivityMilestone';
import { friendlyDate } from '../../../../services/dateFormats';
import dayjs from 'dayjs';
import { gql } from '@apollo/client';
import { SelectedMilestone_MilestoneWithLinksFragment } from '../../../../generated/graphql';
import { DisplayName } from '../../../../components/DisplayName';
import { Btn } from '../../../../components/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  teamId: string;
  selectedMilestone: SelectedMilestone_MilestoneWithLinksFragment;
  onClear: () => void;
}

export const SelectedMilestone = ({
  teamId,
  selectedMilestone,
  onClear,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Timeline
        items={[
          {
            children: (
              <>
                <div className="txt--secondary">
                  {friendlyDate(dayjs(selectedMilestone.deadlineAt))}
                </div>

                <ActivityMilestone
                  milestone={selectedMilestone}
                  teamId={teamId}
                />

                <div>
                  <h5 className="bold">{t('common.description')}</h5>
                  <div className="font-size--sm">
                    {selectedMilestone.description}
                  </div>
                </div>
                <div className="mt">
                  <h5 className="bold">Owner</h5>
                  <div className="font-size--sm">
                    <DisplayName
                      user={selectedMilestone.assignedTo?.[0].data}
                    />
                  </div>
                </div>
                <div className="mt">
                  <h5 className="bold">
                    {t('SelectedMilestone.contributingTeams')}
                  </h5>
                  <div className="font-size--sm">
                    {selectedMilestone.linkedTeams
                      .map((team) => team.name)
                      .join(', ')}
                  </div>
                </div>
                <Btn size="small" className="mt" onClick={onClear}>
                  {t('SelectedMilestone.clearMilestone')}
                </Btn>
              </>
            ),
            color: 'grey',
          },
          {
            children: <></>,
            dot: <></>,
          },
        ]}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SELECTED_MILESTONE_MILESTONE_WITH_LINKS = gql`
  fragment SelectedMilestone_MilestoneWithLinks on MilestoneWithLinks {
    id
    domainId {
      itemId
      tenantId
    }
    description
    deadlineAt
    assignedTo {
      id
      data {
        id
        domainId {
          itemId
        }
        name
        email
        displayName
      }
    }
    linkedTeams {
      id
      domainId {
        itemId
      }
      name
    }
    ...ActivityMilestone_MilestoneWithLinksForTeam
  }
`;
