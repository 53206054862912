import { useTranslation } from 'react-i18next';

import Table, { ColumnProps } from 'antd/es/table';
import { EnrollTeamToInitiativeSkeleton } from './EnrollTeamToInitiativeSkeleton';
import { Skeleton } from 'antd';

export const ManageInitiativeTeamsModalContentSkeleton = () => {
  const { t } = useTranslation();

  const columns: ColumnProps<any>[] = [
    {
      title: t('common.name'),
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      key: 'name',
      render() {
        return <Skeleton paragraph={false} />;
      },
    },
    {
      defaultSortOrder: 'ascend',
      key: 'actions',
      width: 150,
      render() {
        return <Skeleton paragraph={false} />;
      },
    },
  ];

  return (
    <div className="flx flx--column">
      <h2 className="mb--xl">{t('ManageInitiativeTeamsModalContent.title')}</h2>
      <div className="mb--xl">
        <h3 className="mb">
          {t('ManageInitiativeTeamsModalContent.assignTeam')}
        </h3>
        <EnrollTeamToInitiativeSkeleton />
      </div>
      <h3 className="mb">
        {t('ManageInitiativeTeamsModalContent.alreadyAssignedTeams')}
      </h3>

      <Table
        size="middle"
        className="showScrollBar"
        rowKey={(record) => record.id}
        dataSource={[{ id: 1 }, { id: 2 }]}
        columns={columns}
        loading={false}
        pagination={false}
      />
    </div>
  );
};
