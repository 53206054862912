import * as sentry from '@sentry/react';
import { getParsedErrors } from './error-parser';
import { ApolloError } from '@apollo/client';

export const howweErrorParser = (error?: ApolloError | unknown) => {
  if (!error) return;
  const errors = getParsedErrors(
    error,
    (missingKey, error) => {
      let errorString = error;
      try {
        errorString = JSON.stringify(error, null, 2);
      } catch {
        /* empty */
      }
      return sentry.captureException(
        new Error(
          `Missing error translation key: '${missingKey}'\n${errorString}`,
          {
            cause: error,
          }
        ),
        { level: 'warning' }
      );
    },
    function (error) {
      let errorString = error;
      try {
        errorString = JSON.stringify(error, null, 2);
      } catch {
        /* empty */
      }
      sentry.captureException(
        new Error(`Unhandled error: ${errorString}`, {
          cause: error,
        }),
        { level: 'error' }
      );
    }
  );
  return errors;
};
