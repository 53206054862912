import { gql, useQuery } from '@apollo/client';
import cx from 'classnames';
import './MilestoneSelectFormItem.less';
import { GetMsForTagSelectFormItemDocument } from '../../../../../generated/graphql';

interface Props {
  teamId: string;
  selectedInitiativeId?: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const MilestoneTagSelectFormItem = ({
  teamId,
  selectedInitiativeId,
  value,
  onChange,
}: Props) => {
  const { data } = useQuery(GetMsForTagSelectFormItemDocument, {
    variables: { teamId },
  });

  if (!selectedInitiativeId)
    return (
      <div className="txt--secondary">
        Select initiative above to see connected Milestones{' '}
      </div>
    );

  const milestones =
    data?.milestonesWithLinksForTeam.milestones?.filter((ms) =>
      ms.metadata.supportsInitiatives.some(
        (si) => si.id === selectedInitiativeId
      )
    ) ?? [];

  if (milestones.length === 0)
    return (
      <div className="txt--secondary">
        Your team has no milestones connected to this initiative
      </div>
    );

  return (
    <div className="flx flx--gap--s flx--wrap">
      {milestones.map((ms) => (
        <div
          className={cx('MilestoneSelectFormItem__milestone', {
            'MilestoneSelectFormItem__milestone--selected': ms.id === value,
          })}
          key={ms.id}
          title={ms.name}
          onClick={() => onChange?.(ms.id)}
        >
          {ms.name}
        </div>
      ))}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_FRAGMENT = gql`
  fragment MilestoneTagSelectFormItem_milestone on MilestoneWithLinks {
    id
    domainId {
      itemId
      tenantId
    }
    deadlineAt
    name
    metadata {
      archived
      completedAt
      status
      supportsInitiatives {
        id
        domainId {
          itemId
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MITEM_FORM_MILESTONES_FOR_TEAM = gql`
  query GetMSForTagSelectFormItem($teamId: ID!) {
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        ...MilestoneTagSelectFormItem_milestone
      }
    }
  }
`;
