import React from 'react';
import './VertTimeline.less';

interface TimelineItem {
  children: React.ReactNode;
  dot: React.ReactNode;
  tailColor?: string;
}

interface VertTimelineProps {
  items: TimelineItem[];
}

const VertTimeline: React.FC<VertTimelineProps> = ({ items }) => {
  return (
    <div className="VertTimeline">
      {items.map((item, index) => (
        <div className="VertTimeline__item" key={index}>
          <div className="flx flx--column flx--ai-center">
            {item.dot}
            <div
              className="VertTimeline__tail"
              style={{ borderColor: item.tailColor }}
            />
          </div>
          <div className="VertTimeline__content">{item.children}</div>
        </div>
      ))}
    </div>
  );
};

export default VertTimeline;
