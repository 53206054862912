import { TeamOverviewSection } from './components/teamOverviewSection/TeamOverviewSection';
import './TeamOverview.page.less';
import { MigSection } from './components/migSection/MigSection';
import { AccelerationMeetingModal } from './components/accelerationMeetingModal/accelerationMeetingModal';
import { AccMeetingButton } from './components/AccMeetingButton';
import { PermissionChecker } from '../../../PermissionChecker';
import { useRouteMatch } from 'react-router-dom';
import { getMigWarnings } from '../../../services/getMigWarnings';
import dayjs from 'dayjs';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  Action,
  GetMigsForTeamOverviewDocument,
  MigStatus,
} from '../../../generated/graphql';
import { Suspense, useEffect } from 'react';
import { TeamOverviewSkeleton } from './TeamOverview.skeleton';
import { Divider } from 'antd';
import { KeyActivitySection } from './components/KeyActivitySection/KeyActivitySection';

interface Props {
  team: {
    id: string;
    domainId: {
      itemId: string;
    };
    name: string;
  };
}

export const TeamOverviewPage = ({ team }: Props) => {
  const match = useRouteMatch();
  const isAccMeeting = !!useRouteMatch(match.path + '/acceleration-meeting');

  useEffect(() => {
    (window as any).Intercom?.('update', {
      hide_default_launcher: isAccMeeting,
    });
  }, [isAccMeeting]);

  const { data: migData } = useSuspenseQuery(GetMigsForTeamOverviewDocument, {
    variables: { teamId: team.domainId.itemId },
  });

  const teamMigs = migData?.teamMigs.migs ?? [];
  const goalFulfillment = migData?.teamMigs.migFulfillment?.goalFulfillment;

  const teamMigsWithoutArchived = teamMigs.filter(
    (mig) => mig.status !== MigStatus.ARCHIVED
  );

  const teamMigsWithoutMirrored = teamMigsWithoutArchived.filter(
    (mig) => mig.domainId.teamId === team.domainId.itemId
  );

  const migsThatNeedsToBeUpdated = teamMigsWithoutMirrored
    .map((mig) => {
      return getMigWarnings(mig.periodData, mig.timePeriodInterval, dayjs());
    })
    .filter((warnings) => warnings.length > 0);

  return (
    <div className="maxWidth">
      <div className="flx flx--jc-flx-end TeamOverviewPage__header">
        {team && isAccMeeting && <AccelerationMeetingModal team={team} />}
        <PermissionChecker
          resourceOwner={{
            type: 'TeamResource',
            requestedAction: {
              resource: 'accelerationMeeting',
              action: [Action.CREATE, Action.UPDATE],
            },
            teamId: team.domainId.itemId,
          }}
        >
          <AccMeetingButton teamId={team.domainId.itemId} />
        </PermissionChecker>
      </div>
      <Suspense fallback={<TeamOverviewSection.Skeleton />}>
        <TeamOverviewSection
          teamId={team.domainId.itemId}
          migTotalPerformance={goalFulfillment}
          totalMigsThatNeedsToBeUpdated={migsThatNeedsToBeUpdated.length}
        />
      </Suspense>

      <Divider />
      <Suspense fallback={<MigSection.Skeleton />}>
        <MigSection teamInfo={team} teamMigs={teamMigsWithoutArchived} />
      </Suspense>

      <Divider />
      <Suspense fallback={<KeyActivitySection.Skeleton />}>
        <KeyActivitySection teamId={team.domainId.itemId} />
      </Suspense>
    </div>
  );
};

TeamOverviewPage.Skeleton = TeamOverviewSkeleton;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_OVERVIEW_MIG_QUERY = gql`
  query getMigsForTeamOverview($teamId: ID!, $tenantId: ID) {
    teamMigs(teamId: $teamId, tenantId: $tenantId) {
      migFulfillment {
        goalFulfillment
        id
        domainId {
          itemId
          tenantId
        }
      }
      migs {
        id
        ...MigCollapsibleCard_MigV2
        ...MigSection_MigV2
      }
    }
  }
`;
