import { useTranslation } from 'react-i18next';

import './MilestoneStatusTag.less';
import { MilestoneStatus } from '../generated/graphql';
import { match } from 'ts-pattern';
import cx from 'classnames';
import { KeyActivityStatusIcon } from './KeyActivityStatusIcon';

interface Props {
  status: MilestoneStatus;
}

export const MilestoneStatusTag = ({ status }: Props) => {
  const { t } = useTranslation();

  const statusTag = match(status)
    .with(MilestoneStatus.COMPLETED, () => ({
      className: 'txt--success',
      text: t('common.simpleStatus.COMPLETED'),
    }))
    .with(MilestoneStatus.OVERDUE, () => ({
      className: 'txt--warning',
      text: t('common.simpleStatus.OVERDUE'),
    }))
    .with(MilestoneStatus.UPCOMING, () => ({
      className: 'txt--planned',
      text: t('common.simpleStatus.UPCOMING'),
    }))
    .exhaustive();

  return (
    <div
      className={cx('flx MilestoneStatus font-size--sm', statusTag.className)}
    >
      <KeyActivityStatusIcon status={status} />
      <span className="ml--s">{statusTag.text}</span>
    </div>
  );
};
