import React from 'react';
import { Row, Col, Avatar, Spin } from 'antd';
import { useQuery, gql } from '@apollo/client';
import {
  CheckCircleFilled,
  ClockCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Btn } from '../../../../../../../../components/Button';
import { ActivityTable } from '../../../../../../activities/listPage/ActivityTable/ActivityTable';
import { GetAchievementsSinceLastMeetingDocument } from '../../../../../../../../generated/graphql';
import './AchievementsSinceLastMeeting.less';
import { ErrorAlert } from '../../../../../../../../components/ErrorAlert';
import { DisplayDateRange } from '../../../../../../../../components/DisplayDateRange';

interface AchievementsSinceLastMeetingProps {
  teamId: string;
}

export const AchievementsSinceLastMeeting: React.FC<
  AchievementsSinceLastMeetingProps
> = ({ teamId }) => {
  const { data, loading, error } = useQuery(
    GetAchievementsSinceLastMeetingDocument,
    {
      variables: { teamId },
    }
  );

  if (error) {
    console.error('Error fetching achievements:', error);
    return <ErrorAlert error={error} />;
  }

  const completedKeyActivities =
    data?.accelerationMeetingPeriodSummary?.completedKeyActivities ?? [];

  return (
    <div>
      <h1>Achievements</h1>
      <div className="txt--secondary pb--l borderBottom">
        Data for the period:
        <span className="ml bold txt--primary">
          <DisplayDateRange
            startDate={data?.accelerationMeetingPeriodSummary.startDate}
            endDate={data?.accelerationMeetingPeriodSummary.endDate}
          />
        </span>
      </div>
      <div className="flx flx--ai-center flx--gap--s mt--l mb--xl pb--l borderBottom">
        <span>Show:</span>
        <Btn type="primary" shape="round">
          Our team achievements
        </Btn>
        <Btn shape="round" disabled title="Not yet implemented">
          Company achievements
        </Btn>
      </div>
      <Row gutter={[24, 24]}>
        {/* Left Column */}
        <Col xs={24} lg={16}>
          <div className="flx flx--column flx--gap--l fullWidth">
            <div>
              <Spin spinning={loading} tip="Loading Activities...">
                <ActivityTable
                  teamId={teamId}
                  activities={completedKeyActivities}
                  header={
                    <>
                      <h3>Activities</h3>
                      <h4 className="mb">Completed since last meeting</h4>
                    </>
                  }
                  className="activities-table"
                />
              </Spin>
            </div>
            {/* Weekly Key Activities Section */}
            <div>
              <h3>Weekly Key Activities</h3>
              <h4>Achieved set goals</h4>
              <Spin spinning={loading} tip="Loading Weekly Activities...">
                <div className="mt--l flx flx--column flx--gap--s">
                  {
                    data?.accelerationMeetingPeriodSummary?.weeklyKeyActivityTeamContributions?.map(
                      (wka) => (
                        <div className="card pa--l" key={wka.id}>
                          <h4 className="flx flx--jc-space-between flx--ai-center">
                            <span>{wka.name}</span>
                            <span className="txt--success">
                              + {wka.totalAchieved}
                            </span>
                          </h4>
                        </div>
                      )
                    ) ??
                      (!loading && <div>No weekly key activities found.</div>) // Handle case where data exists but array is empty/null
                  }
                </div>
              </Spin>
            </div>
          </div>
        </Col>

        {/* Right Column */}
        <Col xs={24} lg={8}>
          <Spin spinning={loading} tip="Loading Details...">
            <div className="flx flx--column flx--gap--l fullWidth AchievementsSinceLastMeeting__initiative_placeholder">
              {/* Initiative Section */}
              {/* TODO: Replace with data from query - Need to determine which initiative/milestone data to show */}
              <div>
                <h5>Initiative</h5>
                <div className="txt--secondary">
                  Completed since last meeting
                </div>
                <div className="card pa--l mt--l">
                  <div className="flx flx--ai-center flx--gap--s">
                    <Avatar
                      shape="square"
                      size="large"
                      icon={<UserOutlined />}
                    />
                    <span className="bold txt--success">
                      Operational Efficiency Program (Placeholder)
                    </span>
                  </div>
                  <p className="mt--s">
                    Fantastic job, team! 🎉 We've wrapped up this initiative...
                    (Placeholder Text)
                  </p>
                </div>
              </div>
              {/* Milestones Section */}
              {/* TODO: Replace with data from query - Need to determine which initiative/milestone data to show */}
              <div>
                {/* Using card and padding utils */}
                <h5>Milestones</h5>
                {/* Completed Milestones */}
                <div className="mt--l">
                  {/* Using margin util */}
                  <div className="txt--secondary">Completed</div>
                  {/* Using text util */}
                  <div className="flx flx--ai-center mt">
                    {/* Using flex and margin utils */}
                    <ClockCircleOutlined
                      className="mr txt--secondary" /* Using margin and text utils */
                    />
                    <span className="txt--secondary">
                      14 Feb 2024 (Placeholder)
                    </span>
                    {/* Using text util */}
                  </div>
                  <div className="card pa--l mt">
                    {/* Using card, padding, margin utils */}
                    <span className="bold txt--success">
                      {/* Using text utils */}
                      Database restore - Arango and MySQL base (Placeholder)
                    </span>
                    <div className="txt--secondary">
                      Sales Process Automation (Placeholder)
                    </div>
                    {/* Using text util */}
                    <p className="mt">
                      {/* Using margin util */}
                      "Congrats, team! 🎉 We've hit a major milestone..."
                      (Placeholder Text)
                    </p>
                  </div>
                </div>
                {/* With Progress Milestones */}
                <div className="mt--xl">
                  <div className="txt--secondary">With progress</div>
                  <div className="flx flx--ai-center mt">
                    <ClockCircleOutlined
                      className="mr txt--secondary" /* Using margin and text utils */
                    />
                    <span className="txt--secondary">
                      12 Mar 2024 (Placeholder)
                    </span>
                  </div>
                  <div className="card pa--l mt">
                    <div className="flx flx--ai-center flx--gap--s">
                      {/* Placeholder Icon */}
                      <Avatar shape="square" icon={<UserOutlined />} />
                      <span>Optimization of Supply (Placeholder)</span>
                    </div>
                  </div>
                  <div className="card pal-l mt--s">
                    <div className="flx flx--ai-center flx--gap--s">
                      {/* Placeholder Icon */}
                      <Avatar shape="square" icon={<CheckCircleFilled />} />
                      <span>
                        Activity completed since last acceleration meeting
                        (Placeholder)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Closing Milestones Section Card div */}
            </div>
            {/* Closing Right Column flex div */}
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_ACHIEVEMENTS_SINCE_LAST_MEETING = gql`
  query GetAchievementsSinceLastMeeting($teamId: ID!) {
    accelerationMeetingPeriodSummary(teamId: $teamId) {
      teamId
      startDate
      endDate
      previousMeetingDate
      completedKeyActivities {
        ...ActivityTable_Mitem
      }
      weeklyKeyActivityTeamContributions {
        id
        name
        totalAchieved
      }
    }
  }
`;
