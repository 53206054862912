import { gql } from '@apollo/client';
import { Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult, SortOrder } from 'antd/lib/table/interface';
import { Suspense, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayDate } from '../../../../../components/DisplayDate';
import { DisplayName } from '../../../../../components/DisplayName';
import { ExpandArrow } from '../../../../../components/ExpandArrow';
import {
  MitemActionType,
  SprintMitemTable_MilestoneFragment,
  SprintMitemTable_SkaTimeStatusFragment,
} from '../../../../../generated/graphql';
import { dateCompare } from '../../../../../services/dateHelpers';
import { friendlyUsername } from '../../../../../services/friendlyUsername';
import { recordDefaultSortOrder } from '../../../../../services/sprintKeyActivityUtils';
import { stringSort } from '../../../../../services/stringSort';
import { mitemStatusColor } from '../../../../../styleVars';
import { InitiativeTag } from '../../../../../components/initiative/InitiativeTag';
import { InitiativeIcon } from '../../../../../components/initiative/InitiativeIcon';
import { TagImage } from '../../../setup/overviewPage/labels/TagImage';
import { tagsContext } from '../../common/TeamSprintProvider';
import { MitemFilterStatus } from '../../common/components/mitemFilters/StatusFilter';
import {
  migFilterCompare,
  ownerFilterCompare,
  statusFilterCompare,
  sprintKeyActivityHasTagMatch,
  milestoneFilterCompare,
} from '../../common/hooks/useMitemFilters';
import './SprintMitemTable.less';
import { CommitColumn } from './sprintMitemTable/CommitColumn';
import { StatusTag } from '../../../../../components/StatusTag';
import { useTenantDetails } from '../../../../../hooks/useTenantDetails';
import { ErrorBoundary } from 'react-error-boundary';
import { ProgressStats } from '../../../../../components/initiatives/ProgressStats';
import { MilestoneCardActivityStats } from '../../planning/components/mitemPlanningBoard/milestoneCard/MilestoneCardActivityStats';
import { TableMitemDetails } from '../../../../../components/akpi/TableMitemDetails';

export type MitemRescheduleAction = {
  action: MitemActionType.PLANNED;
  date: string;
};

export type MitemCompleteAction = {
  action: MitemActionType.COMPLETED;
};

export type MitemDeleteAction = {
  action: MitemActionType.ARCHIVED;
};

export type MitemAction =
  | MitemRescheduleAction
  | MitemDeleteAction
  | MitemCompleteAction;

interface Props {
  timeStatuses: SprintMitemTable_SkaTimeStatusFragment[] | null;
  milestones?: SprintMitemTable_MilestoneFragment[] | null;
  loading?: boolean;
  filters?: {
    ownerId?: string;
    status?: MitemFilterStatus;
    migId?: string;
    milestoneId?: string;
  };
  actionColumn?: ColumnProps<
    SprintMitemTable_SkaTimeStatusFragment | SprintMitemTable_MilestoneFragment
  >;
  pagination?: boolean;
  includeStatus?: boolean;
  committable?: boolean;
  teamId?: string;
  mitemActions?: {
    [mitemId: string]: MitemAction | null;
  };
}

interface ColumnPropsWithExclude<T> extends ColumnProps<T> {
  excluded?: boolean;
}

const sortTeamTags = (timeStatus: SprintMitemTable_SkaTimeStatusFragment) => {
  const teamTags = timeStatus.sprintKeyActivity?.tags ?? [];

  const sortedTeamTags = [...teamTags].sort((a, b) => {
    return stringSort(a.name, b.name);
  });
  return sortedTeamTags;
};

const sortInitiativeTags = (
  features: {
    tenantInitiativesEnabled: boolean;
  },
  timeStatus: SprintMitemTable_SkaTimeStatusFragment
) => {
  const supportedInitiatives =
    timeStatus.sprintKeyActivity?.supportsInitiativeLinks?.map((i) => i.data) ??
    [];

  const sortedInitiativeTags = [...supportedInitiatives].sort((a, b) => {
    return stringSort(a.tag.title, b.tag.title);
  });
  return sortedInitiativeTags;
};

type SprintMitemTableColumnType =
  | SprintMitemTable_SkaTimeStatusFragment
  | SprintMitemTable_MilestoneFragment;

export const SprintMitemTable = ({
  timeStatuses,
  milestones,
  loading,
  filters,
  actionColumn,
  pagination = false,
  includeStatus = true,
  committable = false,
  mitemActions,
  teamId,
}: Props) => {
  const { t } = useTranslation();
  const { features } = useTenantDetails();
  const { tagsSelectedForFiltering, andOrChoice } = useContext(
    tagsContext
  ) as any;

  const [sortInfo, setSortedInfo] = useState<
    SorterResult<
      | SprintMitemTable_SkaTimeStatusFragment
      | SprintMitemTable_MilestoneFragment
    >[]
  >([]);

  const handleChange = (
    sorter:
      | SorterResult<
          | SprintMitemTable_SkaTimeStatusFragment
          | SprintMitemTable_MilestoneFragment
        >
      | SorterResult<
          | SprintMitemTable_SkaTimeStatusFragment
          | SprintMitemTable_MilestoneFragment
        >[]
  ) => {
    setSortedInfo(Array.isArray(sorter) ? sorter : [sorter]);
  };

  useEffect(() => {
    setSortedInfo([{ columnKey: 'deadline', order: 'ascend' }]);
  }, [tagsSelectedForFiltering]);

  const tableDataToBeFiltered = [
    ...(timeStatuses ?? []),
    ...(milestones?.filter((m) => m.metadata.archived == false) ?? []),
  ];

  const filteredTableData = tableDataToBeFiltered
    ?.filter((m) =>
      ownerFilterCompare(
        m.__typename === 'SkaTimeStatus'
          ? m.owner2.domainId.itemId
          : m.assignedTo[0].data.domainId.itemId,
        filters?.ownerId
      )
    )
    .filter((m) =>
      statusFilterCompare(
        m.__typename === 'SkaTimeStatus' ? m.status : m.metadata.status,
        filters?.status
      )
    )
    .filter((m) =>
      milestoneFilterCompare(
        m.__typename === 'SkaTimeStatus'
          ? m.sprintKeyActivity?.supportsMilestoneLinks
          : [{ id: m.id }],
        filters?.milestoneId
      )
    )
    .filter((m) =>
      m.__typename === 'SkaTimeStatus'
        ? migFilterCompare(
            m.sprintKeyActivity?.supportedMigs.map(
              (sm) => sm.domainId.itemId
            ) ?? [],
            filters?.migId
          )
        : true
    );

  const sprintMitemColumns: ColumnPropsWithExclude<SprintMitemTableColumnType>[] =
    [
      {
        title: t('common.sprintKeyActivity.status'),
        excluded: !includeStatus,
        width: 90,
        key: 'status',
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          const statusA =
            a.__typename === 'MilestoneWithLinks'
              ? a.metadata.status
              : a.status;
          const statusB =
            b.__typename === 'MilestoneWithLinks'
              ? b.metadata.status
              : b.status;
          return (
            t ||
            recordDefaultSortOrder.indexOf(statusA) -
              recordDefaultSortOrder.indexOf(statusB)
          );
        },
        sortOrder: sortInfo.find((i) => i.columnKey === 'status')?.order,
        render: (_, record) => {
          return (
            <div className="flx flx--column dimmable">
              {record.__typename === 'MilestoneWithLinks' ? (
                <StatusTag status={record.metadata.status} />
              ) : (
                <StatusTag status={record.status} />
              )}
            </div>
          );
        },
      },
      {
        title: t('common.title'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          return t || stringSort(a.name, b.name);
        },
        sortOrder: sortInfo.find((i) => i.columnKey === 'name')?.order,
        render: (_, record) => {
          return (
            <div className="dimmable">
              <Typography.Text>{record.name}</Typography.Text>
              {record.__typename === 'MilestoneWithLinks' && (
                <ErrorBoundary
                  FallbackComponent={MilestoneCardActivityStats.Error}
                >
                  <Suspense fallback={<ProgressStats.Skeleton />}>
                    {teamId && (
                      <div className="flx txt--secondary flx--ai-center">
                        <div className="mr">
                          {t('SprintMitemTable.activityProgress')}
                        </div>
                        <MilestoneCardActivityStats
                          milestoneId={record.domainId.itemId}
                          teamId={teamId}
                        />
                      </div>
                    )}
                  </Suspense>
                </ErrorBoundary>
              )}
            </div>
          );
        },
      },
      {
        title: t('common.sprintKeyActivity.owner'),
        dataIndex: ['owner', 'id'],
        key: 'owner',
        sortOrder: sortInfo.find((i) => i.columnKey === 'owner')?.order,
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          const ownerA =
            a.__typename === 'MilestoneWithLinks'
              ? a.assignedTo[0].data
              : a.owner2;
          const ownerB =
            b.__typename === 'MilestoneWithLinks'
              ? b.assignedTo[0].data
              : b.owner2;

          const ownerSort = stringSort(
            friendlyUsername(ownerA),
            friendlyUsername(ownerB)
          );
          const deadlineA =
            a.__typename === 'MilestoneWithLinks' ? a.deadlineAt : a.deadline;
          const deadlineB =
            b.__typename === 'MilestoneWithLinks' ? b.deadlineAt : b.deadline;
          const deadlineSort = dateCompare(deadlineA, deadlineB);
          const deadlineDirection = sortOrder === 'descend' ? -1 : 1;

          return t || ownerSort || deadlineSort * deadlineDirection;
        },
        render: (_, record) => {
          if (record.__typename === 'MilestoneWithLinks') {
            return (
              <div className="dimmable">
                <DisplayName user={record.assignedTo[0].data} />
              </div>
            );
          }
          return (
            <div className="dimmable">
              <DisplayName user={record.owner2} />
            </div>
          );
        },
      },
      {
        title: t('common.sprintKeyActivity.deadline'),
        sorter: (a, b, sortOrder) => {
          const t = compareTags(a, b, sortOrder);
          const deadlineA =
            a.__typename === 'MilestoneWithLinks' ? a.deadlineAt : a.deadline;
          const deadlineB =
            b.__typename === 'MilestoneWithLinks' ? b.deadlineAt : b.deadline;
          const deadlineSort = dateCompare(deadlineA, deadlineB);
          const ownerA =
            a.__typename === 'MilestoneWithLinks'
              ? a.assignedTo[0].data
              : a.owner2;
          const ownerB =
            b.__typename === 'MilestoneWithLinks'
              ? b.assignedTo[0].data
              : b.owner2;
          const ownerSort = stringSort(
            friendlyUsername(ownerA),
            friendlyUsername(ownerB)
          );
          return t || deadlineSort || ownerSort;
        },
        defaultSortOrder: 'ascend',
        dataIndex: 'deadline',
        key: 'deadline',
        sortOrder: sortInfo.find((i) => i.columnKey === 'deadline')?.order,
        render: (_, record) => {
          const formattedDeadline = (
            <div className="dimmable">
              {record.__typename === 'MilestoneWithLinks' ? (
                <DisplayDate date={record.deadlineAt} />
              ) : (
                <DisplayDate date={record.deadline} />
              )}
            </div>
          );
          if (record.__typename === 'SkaTimeStatus') {
            const handledMitem = mitemActions?.[record.id];
            if (handledMitem?.action === MitemActionType.PLANNED) {
              return (
                <div className={'flx flx--column'}>
                  <Typography.Text type="secondary" className="lineThrough">
                    {formattedDeadline}
                  </Typography.Text>
                  <div style={{ color: mitemStatusColor.PLANNED }}>
                    <DisplayDate date={handledMitem.date} />
                  </div>
                </div>
              );
            }
          }
          return formattedDeadline;
        },
      },
      {
        title: t('common.sprintKeyActivity.tags'),
        render: (_, record) => {
          let tags = [] as React.ReactNode[];
          if (record.__typename === 'SkaTimeStatus') {
            const sortedTeamTags =
              sortTeamTags(record).map((t) => (
                <TagImage key={t.id} tag={t} />
              )) ?? [];

            const sortedInitiativeTags =
              sortInitiativeTags(features, record).map(
                ({ id, tag, metadata }) => (
                  <InitiativeTag
                    key={id}
                    title={tag.title}
                    borderColor={tag.colorCode}
                    completed={metadata.completedAt !== null}
                    icon={<InitiativeIcon iconId={tag.iconId} />}
                    archived={metadata.archived}
                  />
                )
              ) ?? [];

            tags = [...sortedInitiativeTags, ...sortedTeamTags];
          }
          if (record.__typename === 'MilestoneWithLinks') {
            tags =
              record.metadata.supportsInitiatives.map((si) => (
                <InitiativeTag
                  key={si.data.id}
                  title={si.data.tag.title}
                  borderColor={si.data.tag.colorCode}
                  completed={si.data.metadata.completedAt !== null}
                  icon={<InitiativeIcon iconId={si.data.tag.iconId} />}
                />
              )) ?? [];
          }
          return (
            <div className="flx flx--gap--xs flx--wrap dimmable">
              {tags.length > 0 ? tags : ''}
            </div>
          );
        },
      },
      ...(actionColumn ? [actionColumn] : []),
      {
        title: t('common.commitment', { count: filteredTableData?.length }),
        defaultSortOrder: 'ascend',
        excluded: !committable,
        dataIndex: 'commitment',
        render: (_, record) => {
          return (
            record.__typename === 'SkaTimeStatus' && (
              <div className="dimmable">
                <CommitColumn
                  sprintKaId={record.id}
                  ownerId={record.owner2.domainId.itemId}
                />
              </div>
            )
          );
        },
      },
      Table.EXPAND_COLUMN,
    ];

  const compareTags = (
    a:
      | SprintMitemTable_SkaTimeStatusFragment
      | SprintMitemTable_MilestoneFragment,
    b:
      | SprintMitemTable_SkaTimeStatusFragment
      | SprintMitemTable_MilestoneFragment,
    sortDirection: SortOrder | undefined
  ) => {
    if (!sortDirection) return 0;
    const aHasMatch = sprintKeyActivityHasTagMatch(
      a,
      andOrChoice,
      tagsSelectedForFiltering
    );
    const bHasMatch = sprintKeyActivityHasTagMatch(
      b,
      andOrChoice,
      tagsSelectedForFiltering
    );
    if (aHasMatch === bHasMatch) {
      return 0;
    } else if (sortDirection === 'ascend') {
      return aHasMatch ? -1 : 1;
    } else {
      return bHasMatch ? 1 : -1;
    }
  };

  return (
    <Table
      className="SprintMitemTableCard__mitemTable"
      rowHoverable={false}
      columns={sprintMitemColumns.filter((c) => !c.excluded)}
      dataSource={filteredTableData}
      onChange={(_pagination, _filter, sorter) => handleChange(sorter)}
      expandable={{
        rowExpandable(record) {
          return record.__typename === 'SkaTimeStatus';
        },
        expandRowByClick: true,
        expandedRowRender: (record) => {
          if (record.__typename === 'MilestoneWithLinks') {
            return null;
          }

          return (
            record.sprintKeyActivity && (
              <TableMitemDetails mitem={record.sprintKeyActivity} />
            )
          );
        },
        expandIcon: ({ expanded, onExpand, record }) => {
          if (record.__typename === 'MilestoneWithLinks') return null;
          return (
            <ExpandArrow
              onClick={(e) => onExpand(record, e)}
              expanded={expanded}
            />
          );
        },
      }}
      loading={loading}
      pagination={!pagination ? false : { size: 'small' }}
      rowKey={(timeStatus) => timeStatus.id}
      rowClassName={(record) => {
        if (record.__typename === 'MilestoneWithLinks') {
          // Add status class to milestone rows
          return `SprintMitemTable__row__milestone SprintMitemTable__row__milestone--${record.metadata.status}`;
        }
        return !sprintKeyActivityHasTagMatch(
          record,
          andOrChoice,
          tagsSelectedForFiltering
        )
          ? 'MitemListEntry--dimmed'
          : '';
      }}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SPRINT_MITEM_TABLE__MILESTONE = gql`
  fragment SprintMitemTable_Milestone on MilestoneWithLinks {
    id
    domainId {
      itemId
      tenantId
    }
    deadlineAt
    description
    name
    assignedTo {
      id
      data {
        id
        domainId {
          itemId
        }
        email
        name
        displayName
      }
    }
    metadata {
      completedAt
      status
      archived
      supportsInitiatives {
        id
        data {
          id
          domainId {
            itemId
          }
          name
          tag {
            colorCode
            iconId
            title
          }
          metadata {
            completedAt
            archived
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_MITEM_TABLE_TIME_STATUS = gql`
  fragment SprintMitemTable_SkaTimeStatus on SkaTimeStatus {
    id
    teamId
    tenantId
    name
    deadline
    status
    owner2 {
      id
      domainId {
        itemId
      }
      email
      name
      displayName
    }
    sprintKeyActivity {
      ...SprintMitemTable_Mitem
      ...SprintMitemTableActions_Mitem
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_MITEM_TABLE_MITEM = gql`
  fragment SprintMitemTable_Mitem on Mitem {
    id
    teamId
    completed
    milestone
    status
    tags {
      id
      name
      backgroundColor
    }
    supportsMilestoneLinks {
      id
    }
    supportsInitiativeLinks {
      id
      data {
        id
        tag {
          title
          iconId
          colorCode
        }
        tag {
          title
          iconId
          colorCode
        }
        metadata {
          completedAt
          archived
        }
      }
    }
    ...TableMitemDetails_Mitem
  }
`;
