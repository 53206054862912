import { ActivityTable } from '../../../../../../activities/listPage/ActivityTable/ActivityTable';

import { MonthlyMilestones } from '../../../../../../activities/listPage/MonthlyMilestones';

export const ActivityCommitmentStepSkeleton = () => {
  return (
    <div>
      <div className="mt--xl flx maxWidth">
        <div className="flx--1 ActivityList__main">
          <h3>Activities</h3>

          <>
            <ActivityTable.Skeleton showActions />
            <ActivityTable.Skeleton />
          </>
        </div>
        <div className="ActivityList__sidebar">
          <h3 className="mb">Milestones</h3>
          <MonthlyMilestones.Skeleton />
        </div>
      </div>
    </div>
  );
};
