import { CheckIcon } from '../../../../../../../icons/overviewIcons/CheckIcon';
import { UpcomingEventIcon } from '../../../../../../../icons/overviewIcons/UpcomingEventIcon';
import { WarningIcon } from '../../../../../../../icons/performanceIcons/WarningIcon';
import { Colors } from '../../../../../../componentLibrary/Colors';
import './TeamGantishMilestoneStats.less';
import Skeleton from 'react-loading-skeleton';

export const TeamGantishMilestoneStatsSkeleton = () => {
  return (
    <div className="TeamGantishMilestoneStats">
      <div className="TeamGantishMilestoneStats__stat">{<Skeleton />}</div>
      <div className="TeamGantishMilestoneStats__stat">{<Skeleton />}</div>
      <div className="TeamGantishMilestoneStats__stat">{<Skeleton />}</div>
    </div>
  );
};

const Header = () => {
  return (
    <div className="TeamGantishMilestoneStats">
      <div className="TeamGantishMilestoneStats__stat">
        <CheckIcon className="txt--success" style={{ fontSize: 15 }} />
      </div>
      <div className="TeamGantishMilestoneStats__stat">
        <WarningIcon
          style={{ fontSize: 15, color: Colors.Status.OVERDUE }}
          className="space--r"
        />
      </div>
      <div className="TeamGantishMilestoneStats__stat">
        <UpcomingEventIcon
          style={{ fontSize: 15, color: Colors.Status.FUTURE_PURPLE }}
          className="space--r"
        />
      </div>
    </div>
  );
};

TeamGantishMilestoneStatsSkeleton.Header = Header;
