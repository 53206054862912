import { gql } from '@apollo/client';
import { Drawer } from 'antd';
import { DrawerTitle } from '../../../../../../components/DrawerTitle';
import { useState } from 'react';
import {
  Action,
  MigCollapsibleCardWithActions_MigV2Fragment,
  MigStatus,
} from '../../../../../../generated/graphql';
import { UpdateMigs } from '../../../../../../components/mig/UpdateMigs';
import { MigCollapsibleCard } from './MigCollapsibleCard';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { PermissionChecker } from '../../../../../../PermissionChecker';
import { Btn } from '../../../../../../components/Button';
import { getMigWarnings } from '../../../../../../services/getMigWarnings';
import { match } from 'ts-pattern';
import { StatusMigModal } from '../../../../../../components/setup/migSetupPage/StatusMigModal';
import { ArchiveIcon } from '../../../../../../icons/initiativeIcons/ArchiveIcon';

interface Props {
  mig: MigCollapsibleCardWithActions_MigV2Fragment;
  teamInfo?: {
    id: string;
    domainId: {
      itemId: string;
    };
    name: string;
  };
  isExpanded: boolean;
  onToggleExpand: () => void;
  loading?: boolean;
}

export const MigCollapsibleCardWithActions = ({
  mig,
  onToggleExpand,
  teamInfo,
  isExpanded,
  loading,
}: Props) => {
  const { t } = useTranslation();
  const [showUpdateDrawer, setShowUpdateDrawer] = useState(false);
  const [showArchiveMigModal, setShowArchiveMigModal] = useState(false);
  const isMirrored = mig.domainId.teamId !== teamInfo?.domainId.itemId;

  const updateButtonState =
    getMigWarnings(mig.periodData, mig.timePeriodInterval, dayjs()).length > 0
      ? 'primary'
      : 'default';

  const actions = match({ status: mig.status, isMirrored })
    .with({ status: MigStatus.ACTIVE, isMirrored: false }, () => (
      <PermissionChecker
        resourceOwner={{
          type: 'TeamResource',
          requestedAction: {
            resource: 'mig',
            action: [Action.UPDATE],
          },
          teamId: mig.domainId.teamId,
        }}
      >
        <div className="flx flx--jc-space-between flx--jc-center flx--ai-center">
          <Btn
            type={updateButtonState}
            onClick={(event) => {
              setShowUpdateDrawer(true);
              event.stopPropagation();
            }}
            data-intercom-target="Update MIG Button"
            className="mr"
          >
            {t('common.update')}
          </Btn>
        </div>
      </PermissionChecker>
    ))
    .with({ status: MigStatus.OVERDUE, isMirrored: false }, () => (
      <PermissionChecker
        resourceOwner={{
          type: 'TeamResource',
          requestedAction: {
            resource: 'mig',
            action: [Action.UPDATE],
          },
          teamId: mig.domainId.teamId,
        }}
      >
        <div className="flx flx--jc-space-between flx--jc-center flx--ai-center">
          <Btn
            type={updateButtonState}
            onClick={(event) => {
              setShowUpdateDrawer(true);
              event.stopPropagation();
            }}
            data-intercom-target="Update MIG Button"
            className="mr"
          >
            {t('common.update')}
          </Btn>
        </div>
      </PermissionChecker>
    ))
    .with({ status: MigStatus.ENDED, isMirrored: false }, () => (
      <PermissionChecker
        resourceOwner={{
          type: 'TeamResource',
          requestedAction: {
            resource: 'mig',
            action: [Action.UPDATE],
          },
          teamId: mig.domainId.teamId,
        }}
      >
        <Btn
          onClick={(event) => {
            setShowArchiveMigModal(true);
            event.stopPropagation();
          }}
          size="small"
          icon={<ArchiveIcon />}
        >
          {t('common.archive')}
        </Btn>
      </PermissionChecker>
    ))
    .otherwise(() => null);
  return (
    <>
      <StatusMigModal
        teamId={mig.domainId.teamId}
        onClose={() => setShowArchiveMigModal(false)}
        migModalState={
          showArchiveMigModal
            ? {
                id: mig.domainId.itemId,
                type: 'UpdateMigStatus',
                status: mig.status,
              }
            : null
        }
      />
      <Drawer
        title={
          <DrawerTitle>
            {t('MigCollapsibleCardWithActions.updateMigHeader')}
          </DrawerTitle>
        }
        placement="right"
        closable={true}
        width={520}
        onClose={() => setShowUpdateDrawer(false)}
        open={showUpdateDrawer}
        destroyOnClose
      >
        <UpdateMigs teamId={mig.team2.domainId.itemId} migId={mig.id} />
      </Drawer>
      <MigCollapsibleCard
        key={mig.id}
        mig={mig}
        teamInfo={teamInfo}
        onToggleExpand={onToggleExpand}
        actions={actions}
        isExpanded={isExpanded}
        loading={loading}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_COLLAPSIBLE_CARD_WITH_UPDATE_DRAWER_MIG_V2 = gql`
  fragment MigCollapsibleCardWithActions_MigV2 on MigV2 {
    id
    ...MigCollapsibleCard_MigV2
  }
`;
