import {
  FullscreenOutlined,
  NodeCollapseOutlined,
  NodeExpandOutlined,
} from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { MigOutOfDateWarning } from '../../../../../../components/mig/MigOutOfDateWarning';
import { useMigPerformance } from '../../../../../../hooks/useMigPerformanceQuery';
import './MigListEntry.less';
import { Btn } from '../../../../../../components/Button';

import { PerformanceTag } from '../../../../../../components/PerformanceTag';
import { useTeamMigV2 } from '../../../../../../components/setup/migSetupPage/hooks/useTeamMigV2';
import { gql } from '@apollo/client';
import {
  MigListEntry_MigLightFragment,
  MigListEntry_MigV2Fragment,
} from '../../../../../../generated/graphql';

type Mig = MigListEntry_MigV2Fragment | MigListEntry_MigLightFragment;
interface Props {
  mig: Mig;
  isExpanded: boolean;
  onExpandRelations: () => void;
  onInspectChildMig: (mig: Mig | null) => void;
  faded?: boolean;
}

export const MigListEntry = ({
  mig,
  isExpanded,
  faded,
  onExpandRelations,
  onInspectChildMig,
}: Props) => {
  const { t } = useTranslation();
  const { migPerformance, loading } = useMigPerformance(
    mig.team2.domainId.itemId,
    mig.domainId.itemId
  );

  const { teamMig, loading: teamMigLoading } = useTeamMigV2(
    mig.team2.domainId.itemId,
    mig.domainId.itemId
  );

  const supportingMigsCounter = teamMig?.supportedByMigs?.length ?? 0;

  return (
    <div
      className={cx({
        MigListEntry: true,
        'MigListEntry--hasSupporters': supportingMigsCounter > 0,
        'MigListEntry--showingSupporters': isExpanded,
        'MigListEntry--faded': faded,
      })}
      onClick={() => {
        onExpandRelations();
      }}
    >
      <div className="flx--1">
        <div className="MigListEntry__header">
          <PerformanceTag loading={loading} value={migPerformance} />
          {teamMig && (
            <MigOutOfDateWarning
              mig={teamMig}
              iconClassName="MigListEntry__warningIcon font-size--default ml"
            />
          )}

          <div className="MigListEntry__actions">
            <Tooltip title={t('MigListEntry.showMigDetails')}>
              <Btn
                className="mr--s"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onInspectChildMig(mig);
                }}
                icon={<FullscreenOutlined />}
              />
            </Tooltip>
            <Tooltip title={t('MigListEntry.showRelatedMigs')}>
              <Spin spinning={teamMigLoading} size="small">
                <Btn
                  type={isExpanded ? 'primary' : 'default'}
                  size="small"
                  icon={
                    isExpanded ? (
                      <NodeCollapseOutlined />
                    ) : (
                      <NodeExpandOutlined />
                    )
                  }
                  disabled={supportingMigsCounter === 0}
                >
                  <span className="ml--xs">
                    {teamMig?.supportedByMigs?.length ?? '-'}
                  </span>
                </Btn>
              </Spin>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="MigListEntry__migName">{mig.name}</div>
      <div className="MigListEntry__team">
        {t('MigListEntry.team', { teamName: mig.team2.name })}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_LIST__MIG_V2 = gql`
  fragment MigListEntry_MigV2 on MigV2 {
    id
    name
    domainId {
      itemId
      tenantId
    }
    team2 {
      id
      domainId {
        itemId
      }
      name
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_LIST__MIG_LIGHT = gql`
  fragment MigListEntry_MigLight on MigLight {
    id
    name
    domainId {
      itemId
      tenantId
    }
    team2 {
      id
      domainId {
        itemId
      }
      name
    }
  }
`;
