import { gql, useMutation } from '@apollo/client';
import { PermittedTeamSelectorForAction } from '../../../../../appPages/company/strategicPlan/components/PermittedTeamSelectorForAction';
import {
  Action,
  AssignTeamToInitiativeDocument,
  EnrollTeamToInitiative__TeamIdFragment,
  GetTeamsLinkedToInitiativeDocument,
  TeamLinkTypes,
  TeamResourceType,
} from '../../../../../generated/graphql';
import { Btn } from '../../../../Button';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useTenantOverride } from '../../../../../TenantOverrideProvider';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { howweErrorParser } from '../../../../../services/howweErrorParser';

interface Props {
  initiativeId: string;
  linkedTeams: EnrollTeamToInitiative__TeamIdFragment[];
}

export const EnrollTeamToInitiative = ({
  initiativeId,
  linkedTeams,
}: Props) => {
  const { t } = useTranslation();
  const { tenantId } = useTenantOverride();

  const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>();
  const [enrollTeamToInitiative, { loading }] = useMutation(
    AssignTeamToInitiativeDocument
  );

  const handleAssignToInitiative = () => {
    if (!selectedTeamId || !tenantId) return;
    enrollTeamToInitiative({
      variables: {
        teamId: selectedTeamId,
        domainId: {
          itemId: initiativeId,
          tenantId: tenantId,
        },
        type: TeamLinkTypes.INITIATIVE,
      },
      onCompleted: () => {
        setSelectedTeamId(undefined);
        showNotification('success', {
          message: (
            <span className="bold">
              {t('EnrollTeamToInitiative.joinedSuccessfully')}
            </span>
          ),
        });
      },
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
      refetchQueries: [
        {
          query: GetTeamsLinkedToInitiativeDocument,
          variables: {
            initiativeId,
            requestPermission: {
              resourceType: TeamResourceType.TEAM_LINKS,
              action: Action.DELETE,
            },
          },
        },
      ],
    });
  };

  return (
    <div className="flx">
      <PermittedTeamSelectorForAction
        value={selectedTeamId}
        alreadySelectedTeams={linkedTeams.map((team) => team.domainId.itemId)}
        teamResourceType={TeamResourceType.TEAM_LINKS}
        action={Action.CREATE}
        className="flx--1 mr"
        onChange={setSelectedTeamId}
        alreadySelectedText={t('EnrollTeamToInitiative.alreadySelected')}
      />

      <Btn
        icon={<PlusOutlined />}
        disabled={!selectedTeamId}
        loading={loading}
        type="primary"
        onClick={handleAssignToInitiative}
      >
        {t('EnrollTeamToInitiative.enrollTeam')}
      </Btn>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ENROLL_TEAM_TO_INITIATIVE__TEAM_ID = gql`
  fragment EnrollTeamToInitiative__TeamId on Team2 {
    id
    domainId {
      itemId
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ASSIGN_TEAM_TO_INITIATIVE = gql`
  mutation assignTeamToInitiative(
    $tenantId: ID
    $teamId: ID!
    $domainId: TeamLinkDomainIdInput!
    $type: TeamLinkTypes!
  ) {
    createLinkToTeam(
      tenantId: $tenantId
      teamId: $teamId
      domainId: $domainId
      type: $type
    ) {
      id
    }
  }
`;
