import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next'; // Added import
import { CompanyMigReport_MigV2Fragment } from '../../../../generated/graphql';
import MigReportGraph from './MigReportGraph';
import { useState } from 'react';
import { Btn } from '../../../../components/Button';
import './CompanyMigReport.less';
import { friendlyDate } from '../../../../services/dateFormats';
import { Colors } from '../../../componentLibrary/Colors';
import { Dot } from '../../../../components/Dot';
import { FreeTextComment } from '../components/FreeTextComment';

interface Props {
  mig: CompanyMigReport_MigV2Fragment;
  isPreviewMode: boolean;
}

export const CompanyMigReport = ({ mig, isPreviewMode }: Props) => {
  const { t } = useTranslation(); // Added translation hook
  const [excludeSection, setExcludeSection] = useState(false);

  return (
    <>
      <div
        className={
          excludeSection
            ? 'CompanyMigReport--excludeSection exclude-from-pdf unbreakable'
            : 'unbreakable'
        }
      >
        <h3 className="mb--l">{mig.name}</h3>
        <div className="flx mb--l">
          <div className="flx--6 mr">
            <div className="mb--l">
              <h4>{t('CompanyMigReport.descriptionTitle')}</h4>
              {mig.description ? (
                <div className="font-size--xs"> {mig.description}</div>
              ) : (
                <div className="txt--disabled font-size--sm">
                  {t('CompanyMigReport.noDescription')}
                </div>
              )}
            </div>
          </div>
          <div className="flx--4">
            <div>
              <h4>{t('CompanyMigReport.progressOverTimeTitle')}</h4>
              <MigReportGraph mig={mig} />
              <div
                style={{
                  display: 'flex',
                  marginBottom: '10px',
                }}
              >
                <div className="flx flx--column flx--1 ">
                  <h4 className="flx">
                    {t('CompanyMigReport.currentGoalFulfillment')}
                    <div className="ml--auto">
                      {mig.mostRecentReport?.goalFulfillment}%
                    </div>
                  </h4>
                  <IndicatorRow
                    color={Colors.Action.BLUE}
                    label={t('CompanyMigReport.migOutcomeForDate', {
                      date: friendlyDate(mig.mostRecentReport.targetDate),
                    })}
                    value={mig.mostRecentReport.actual}
                  />
                  <IndicatorRow
                    color={Colors.Howwe.MAIN_ORANGE}
                    label={t('CompanyMigReport.migGoalForDate', {
                      date: friendlyDate(mig.mostRecentReport.targetDate),
                    })}
                    value={mig.mostRecentReport.goal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FreeTextComment isPreviewMode={isPreviewMode} className="mt--xxl " />
      {!isPreviewMode && (
        <div className="flx exclude-from-pdf">
          <Btn
            className="ml--auto mr--auto mt "
            type="link"
            onClick={() => setExcludeSection(!excludeSection)}
          >
            {excludeSection
              ? t('CompanyMigReport.includeSectionButton')
              : t('CompanyMigReport.excludeSectionButton')}
          </Btn>
        </div>
      )}
    </>
  );
};

interface IndicatorRowProps {
  color: string;
  label: string;
  value: string | number | null;
}

const IndicatorRow: React.FC<IndicatorRowProps> = ({ color, label, value }) => (
  <div className="flx flx--ai-baseline font-size--sm">
    <Dot size={6} color={color} className="mr--s" />
    <div>{label}</div>
    <div className="ml--auto">{value}</div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const COMPANY_MIG_REPORT__QUERY = gql`
  fragment CompanyMigReport_MigV2 on MigV2 {
    id
    name
    status
    timePeriodInterval
    description
    domainId {
      itemId
      teamId
      tenantId
    }
    mostRecentReport {
      targetDate
      actual
      goal
      goalFulfillment
    }

    ...MigGraph_MigV2
  }
`;
