import {
  gql,
  PureQueryOptions,
  RefetchQueriesFunction,
  useMutation,
} from '@apollo/client';
import { Form, Modal } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { DisplayName } from '../../../components/DisplayName';
import {
  AssignTeamMemberRoleModal__User2Fragment,
  ChangeMemberRoleDocument,
  TeamMemberRoles,
} from '../../../generated/graphql';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { TeamRolesFormItem } from './TeamRolesFormItem';
import { ErrorAlert } from '../../../components/ErrorAlert';

interface Props {
  roles: TeamMemberRoles[];
  teamId: string;
  user: AssignTeamMemberRoleModal__User2Fragment;
  tenantId?: string;
  visible: boolean;
  rev: string;
  hideModal: () => void;
  refetchQueries?: Array<string | PureQueryOptions> | RefetchQueriesFunction;
}

export const AssignTeamMemberRoleModal = ({
  roles,
  teamId,
  user,
  visible,
  hideModal,
  tenantId,
  refetchQueries,
  rev,
}: Props) => {
  const { t } = useTranslation();

  const [assignTeamRole, assignTeamRoleRequest] = useMutation(
    ChangeMemberRoleDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: (
            <Trans i18nKey="TeamSettingsPage.assignTeamRole.success">
              The team role for user:
              <DisplayName user={user}></DisplayName>
              has successfully been updated
            </Trans>
          ),
        });
        hideModal();
      },
      refetchQueries,
    }
  );
  const [form] = Form.useForm();
  const handleSubmit = () => {
    form.validateFields().then((values) => {
      assignTeamRole({
        variables: {
          teamId,
          tenantId,
          userId: user.domainId.itemId,
          roleInputs: {
            rev: rev,
            roles: values.teamRole ? [values.teamRole] : [],
          },
        },
      });
    });
  };

  return (
    <Modal
      title={
        <span>
          {t('AssignTeamMemberRoleModal.modalTitle')}
          <DisplayName user={user} />
        </span>
      }
      open={visible}
      onOk={handleSubmit}
      onCancel={hideModal}
      okText={t('common.confirm')}
      cancelText={t('common.cancel')}
      cancelButtonProps={{ disabled: assignTeamRoleRequest.loading }}
      confirmLoading={assignTeamRoleRequest.loading}
    >
      <Form
        form={form}
        initialValues={{
          teamRole: roles[0] || null,
        }}
        data-testid="assign-member-role-form"
        layout="vertical"
      >
        <TeamRolesFormItem />
      </Form>

      <ErrorAlert
        error={assignTeamRoleRequest.error}
        title={t('AssignTeamMemberRoleModal.errorTitle')}
      />
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ASSIGN_TEAM_MEMBER_ROLE_MODAL__USER2 = gql`
  fragment AssignTeamMemberRoleModal__User2 on User2 {
    id
    domainId {
      itemId
    }
    name
    displayName
    initials
    email
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ASSIGN_TEAM_MEMBER_ROLE_MODAL = gql`
  mutation changeMemberRole(
    $tenantId: ID
    $teamId: ID!
    $userId: ID!
    $roleInputs: TeamRolesInput!
  ) {
    addOrUpdateTeamMember(
      tenantId: $tenantId
      teamId: $teamId
      userId: $userId
      roleInputs: $roleInputs
    ) {
      name
      id
      rev
      members {
        roles
        user {
          id
          data {
            name
            email
          }
        }
      }
    }
  }
`;
