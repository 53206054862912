import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';

import './MilestoneSelectFormItem.less';
import {
  GetMsForSelectFormItemDocument,
  MilestoneSelectFormItem_MilestoneFragment,
} from '../../../../../generated/graphql';

interface Props {
  teamId: string;
  selectedInitiativeId?: string;
  value?: string;
  onChange?: (value: string) => void;
  alignedMilestones?: MilestoneSelectFormItem_MilestoneFragment[];
}

export const MilestoneSelectFormItem = ({
  teamId,
  selectedInitiativeId,
  value,
  onChange,
  alignedMilestones,
}: Props) => {
  const { data, loading } = useQuery(GetMsForSelectFormItemDocument, {
    variables: { teamId },
  });

  if (!selectedInitiativeId) {
    return (
      <Select
        placeholder={
          <span className="font-size--sm">
            Select initiative above to see connected Milestones
          </span>
        }
        disabled
        style={{ minWidth: 200 }}
      />
    );
  }

  // Get currently connected milestones
  const activeMilestones =
    data?.milestonesWithLinksForTeam.milestones?.filter((ms) =>
      ms.metadata.supportsInitiatives.some(
        (si) => si.id === selectedInitiativeId
      )
    ) ?? [];

  // Find disconnected milestones
  const disconnectedMilestones =
    alignedMilestones?.filter(
      (alignedMs) =>
        !activeMilestones.some((ms) => ms.id === alignedMs.id) &&
        alignedMs.metadata.supportsInitiatives.some(
          (si) => si.id === selectedInitiativeId
        )
    ) ?? [];

  if (activeMilestones.length === 0 && disconnectedMilestones.length === 0) {
    return (
      <Select
        placeholder={
          <span className="font-size--sm">
            No milestones connected to this initiative
          </span>
        }
        disabled
        style={{ minWidth: 200 }}
      />
    );
  }

  // Create options array with both active and disconnected milestones
  const options = [
    ...activeMilestones.map((ms) => ({
      label: <span className="font-size--sm">{ms.name}</span>,
      value: ms.id,
      disabled: ms.metadata.archived || ms.metadata.completedAt !== null,
    })),

    ...(disconnectedMilestones.length > 0
      ? [
          {
            label: 'No longer tracked',
            options: disconnectedMilestones.map((ms) => ({
              label: (
                <span className="font-size--sm txt--secondary">{ms.name}</span>
              ),
              value: ms.id,
              disabled:
                ms.metadata.archived || ms.metadata.completedAt !== null,
            })),
          },
        ]
      : []),
  ];

  return (
    <Select
      placeholder={<span className="font-size--sm">Select milestone</span>}
      value={value}
      onChange={onChange}
      loading={loading}
      style={{ minWidth: 200 }}
      allowClear
      options={options}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_FRAGMENT = gql`
  fragment MilestoneSelectFormItem_milestone on MilestoneWithLinks {
    id
    deadlineAt
    name
    metadata {
      archived
      completedAt
      supportsInitiatives {
        id
        domainId {
          itemId
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MITEM_FORM_MILESTONES_FOR_TEAM = gql`
  query GetMSForSelectFormItem($teamId: ID!) {
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        ...MilestoneSelectFormItem_milestone
      }
    }
  }
`;
