import { gql } from '@apollo/client';
import {
  BaseMitemSprintFragment,
  FilterHeader_SkaTimeStatusFragment,
} from '../../../../../generated/graphql';
import { isDefined } from '../../../../../services/isDefined';

import { MigFilter } from '../../common/components/mitemFilters/MigFilter';
import { OwnerFilter } from '../../common/components/mitemFilters/OwnerFilter';
import { SprintFilter } from '../../common/components/mitemFilters/SprintFilter';
import { StatusFilter } from '../../common/components/mitemFilters/StatusFilter';
import { TagFilter } from '../../common/components/mitemFilters/TagFilter/TagFilter';
import { MitemFilters } from '../../common/hooks/useMitemFilters';
import { MilestoneFilter } from '../../common/components/mitemFilters/MilestoneFilter';
import { useTenantDetails } from '../../../../../hooks/useTenantDetails';
import { Suspense } from 'react';

interface SprintInfo {
  sprints: BaseMitemSprintFragment[];
  selectedSprintIndex: number;
  currentSprintIndex: number;
  setSelectedSprintIndex: (index: number) => void;
}

interface Props {
  timeStatuses: FilterHeader_SkaTimeStatusFragment[];
  currentFilters: MitemFilters['currentFilters'];
  setFilter: MitemFilters['setFilter'];
  sprintInfo: SprintInfo;
  teamId: string;
}

export const FilterHeader = ({
  timeStatuses,
  currentFilters,
  setFilter,
  sprintInfo,
  teamId,
}: Props) => {
  const { features } = useTenantDetails();

  return (
    <div
      className="flx flx--ai-center"
      data-intercom-target="Sprint View Table Filter Area"
    >
      <MigFilter
        migs={timeStatuses
          .flatMap((m) => m.sprintKeyActivity?.supportedMigs)
          .filter(isDefined)}
        value={currentFilters.migId}
        onChange={setFilter.migId}
      />

      <StatusFilter
        value={currentFilters.status}
        onChange={setFilter.mitemStatus}
      />

      <OwnerFilter
        owners={timeStatuses.map((m) => m.owner2)}
        value={currentFilters.ownerId}
        onChange={setFilter.mitemOwnerId}
      />
      {features.tenantInitiativesEnabled && (
        <Suspense fallback={null}>
          <MilestoneFilter
            teamId={teamId}
            value={currentFilters.milestoneId}
            onChange={setFilter.milestoneId}
          />
        </Suspense>
      )}

      <TagFilter teamId={teamId} />

      <SprintFilter
        currentSprintIndex={sprintInfo.currentSprintIndex}
        sprints={sprintInfo.sprints}
        selectedSprintIndex={sprintInfo.selectedSprintIndex}
        setSelectedSprintIndex={sprintInfo.setSelectedSprintIndex}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FILTER_HEADER_MITEM_FRAGMENT = gql`
  fragment FilterHeader_SkaTimeStatus on SkaTimeStatus {
    id
    owner2 {
      id
      domainId {
        itemId
      }
      email
      displayName
      name
    }
    sprintKeyActivity {
      supportedMigs {
        id
        name
        domainId {
          itemId
        }
      }
    }
  }
`;
