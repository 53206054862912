import { Btn } from '../../../../components/Button';
import placeholderUrl from '../../../../images/placeholder_noActivities.svg';

interface Props {
  onOpenCreateActivityModal: () => void;
}

export const NoMilestoneActivities = ({ onOpenCreateActivityModal }: Props) => {
  return (
    <div className="flx flx--jc-center flx--ai-center mt--xl">
      <div>
        <img src={placeholderUrl} height="340px" />
      </div>
      <div className="ml--xl">
        <h3>
          There are no activities in your team connected to this milestone
        </h3>
        <div className="mt--s txt--secondary">
          Get started by creating a new activity!
        </div>
        <Btn
          size="large"
          type="primary"
          className="mt"
          onClick={() => onOpenCreateActivityModal()}
        >
          Create Activity
        </Btn>
      </div>
    </div>
  );
};
