import { Collapse } from 'antd';
import { Suspense } from 'react';
import { MilestoneCollapseItem } from './MilestoneCollapseItem';
import { MilestoneCollapseItemSkeleton } from './MilestoneCollapseItem.skeleton';
import { StatusTag } from '../../../../StatusTag';
import { DisplayDate } from '../../../../DisplayDate';
import { gql } from '@apollo/client';
import {
  MilestoneCollapse_MilestoneFragment,
  MilestoneStatus,
} from '../../../../../generated/graphql';
import { AnimatePresence, motion } from 'motion/react';

interface Props {
  milestone: MilestoneCollapse_MilestoneFragment;
}

export const MilestoneCollapse = ({ milestone }: Props) => {
  return (
    <Collapse
      className="mb"
      expandIconPosition="end"
      items={[
        {
          key: milestone.id,
          label: (
            <div>
              <div className="mb--s flx flx--ai-center relative">
                <div className="">
                  <AnimatePresence propagate>
                    <motion.div
                      style={{ position: 'absolute', top: 0, left: 0 }}
                      key={milestone.id}
                      exit={{
                        scale: 0,
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <StatusTag status={milestone.metadata.status} compact />
                    </motion.div>
                    <motion.div
                      style={{ position: 'absolute', top: 0, left: 0 }}
                      key={milestone.id + '-completed'}
                      initial={{ opacity: 0, scale: 0.5 }}
                      exit={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.3, delay: 0.2 }}
                    >
                      <StatusTag status={MilestoneStatus.COMPLETED} compact />
                    </motion.div>
                  </AnimatePresence>
                </div>
                <DisplayDate
                  className="txt--secondary font-size--sm ml--auto"
                  date={milestone.deadlineAt}
                />
              </div>
              <h4>{milestone.name}</h4>
            </div>
          ),
          children: (
            <div>
              <Suspense fallback={<MilestoneCollapseItemSkeleton />}>
                <MilestoneCollapseItem
                  milestoneId={milestone.domainId.itemId}
                />
              </Suspense>
            </div>
          ),
        },
      ]}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_FRAGMENT = gql`
  fragment MilestoneCollapse_milestone on MilestoneWithLinks {
    id
    domainId {
      itemId
    }
    name
    deadlineAt
    metadata {
      status
    }
  }
`;
