import { Card, Table, Tooltip, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table/Column';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DisplayName } from '../../../../../../../components/DisplayName';
import { NumberInput } from '../../../../../../../components/NumberInput';
import { friendlyUsername } from '../../../../../../../services/friendlyUsername';
import { stringToNumber } from '../../../../../../../services/stringToNumber';
import {
  AkpiCommitments,
  AkpiMemberCommitment,
  useAccelerationMeeting,
} from '../../AccelerationMeetingProvider';
import { CommitmentQuota } from '../../components/CommitmentQuota';
import { AccelerationMeetingInsights } from '../insightsCards/AccelerationMeetingInsights';
import { gql } from '@apollo/client';
import { MeetingSummary_AkpiFragment } from '../../../../../../../generated/graphql';

interface Member {
  email: string;
  name?: string | null;
  displayName?: string | null;
  id: string;
  domainId: { itemId: string };
}

interface Props {
  weeklyKeyActivities: MeetingSummary_AkpiFragment[];
  teamId: string;
}

const rowIds = {
  strategy: 'Strategy',
  total: 'Total',
};

export const MeetingSummary = ({ weeklyKeyActivities, teamId }: Props) => {
  const { t } = useTranslation();
  const { weeklyKeyActivityContext, members } = useAccelerationMeeting();
  const columns: ColumnProps<TableData>[] = [
    {
      title: t('MeetingSummary.akpiLong'),
      dataIndex: 'userName',
      key: 'userId',
      fixed: 'left',
      width: 250,
    },
    ...weeklyKeyActivities.map((weeklyKeyActivity) => {
      const akpiCommitment =
        weeklyKeyActivityContext.commitments[weeklyKeyActivity.id] ??
        ({} as AkpiMemberCommitment);
      const totalCommitted = Object.values(
        akpiCommitment.memberCommitments
      ).reduce(
        (sum, userCommitment) => sum + stringToNumber(userCommitment.value),
        0
      );
      const selectedStrategy =
        weeklyKeyActivityContext.commitments[weeklyKeyActivity.id]
          .selectedStrategy;
      const recommendedCommitment = akpiCommitment.strategies[selectedStrategy];
      const underCommitted = totalCommitted < recommendedCommitment;
      return {
        title: (
          <Typography.Paragraph
            className="mb--none"
            ellipsis={{ rows: 2, expandable: false }}
          >
            {weeklyKeyActivity.name}
          </Typography.Paragraph>
        ),
        dataIndex: ['akpiCommitments', weeklyKeyActivity.id],
        key: weeklyKeyActivity.id,
        render: (
          committed: { value: string; note: string },
          item: TableData
        ) => {
          if (item.userId === rowIds.strategy) {
            return (
              <div style={{ height: 70 }}>
                <Typography.Text type="secondary" strong>
                  {t('MeetingSummary.' + selectedStrategy)}
                </Typography.Text>
                <div>
                  <Typography.Text type="secondary">
                    {t('MeetingSummary.suggestedCommitmentPerMember')}{' '}
                  </Typography.Text>
                  <Typography.Text type="secondary">
                    {Math.ceil(recommendedCommitment / members.length)}{' '}
                    {weeklyKeyActivity.unit}
                  </Typography.Text>
                </div>
              </div>
            );
          }
          if (item.userId === rowIds.total) {
            return (
              <div>
                <Tooltip
                  placement="top"
                  overlayStyle={!underCommitted ? { display: 'none' } : {}}
                  title={
                    <div>{t('AccelerationMeetingView.underCommittedInfo')}</div>
                  }
                >
                  <span>
                    <Typography.Text type="secondary" strong>
                      <span
                        className={
                          'AkpiCommitment__total-value ' +
                          (underCommitted ? 'too-little' : '')
                        }
                      >
                        <CommitmentQuota
                          totalCommitted={totalCommitted}
                          recommendedCommitment={recommendedCommitment}
                        />
                      </span>
                      {weeklyKeyActivity.unit}
                    </Typography.Text>
                  </span>
                </Tooltip>
              </div>
            );
          }

          return (
            <NumberInput
              data-testid={`meetingSummaryCommitmentInput-${weeklyKeyActivity.id}-${item.userId}`}
              value={committed.value}
              onChange={(value) => {
                value =
                  typeof value === 'string' ? stringToNumber(value) : value;
                if (value && value < 0) return;
                weeklyKeyActivityContext.setUserCommitment(
                  weeklyKeyActivity.id,
                  item.userId,
                  {
                    value: value?.toString() ?? '',
                    note: committed.note,
                  }
                );
              }}
              min={0}
            />
          );
        },
      };
    }),
  ];

  const rowClassName = (record: TableData) => {
    if (record.userId === rowIds.strategy || record.userId === rowIds.total) {
      return 'accelerationMeeting__fakeHeader';
    }
    return '';
  };

  const dataSource: TableData[] = [
    {
      userId: rowIds.strategy,
      userName: (
        <div style={{ fontWeight: 500, height: 70 }}>
          {t('MeetingSummary.planForward')}
        </div>
      ),
      akpiCommitments: {},
    },
    {
      userId: rowIds.total,
      userName: (
        <div style={{ fontWeight: 500 }}>{t('MeetingSummary.total')}</div>
      ),
      akpiCommitments: {},
    },
  ] as TableData[];

  return (
    <>
      {weeklyKeyActivities.length > 0 && (
        <Card
          data-testid="MeetingSummaryCard"
          title={t('MeetingSummary.summaryCardTitle')}
          styles={{ body: { padding: 0 } }}
        >
          <Table
            className="showScrollBar"
            dataSource={dataSource}
            columns={columns}
            bordered
            rowClassName={rowClassName}
            pagination={false}
            rowKey={(item) => item.userId}
            scroll={{ x: 250 * weeklyKeyActivities.length }}
          />
        </Card>
      )}
      <div className="pt--xl pb--xl">
        <AccelerationMeetingInsights teamId={teamId} />
      </div>
    </>
  );
};

export interface TableData {
  userId: string;
  userName: string | React.ReactNode;
  plainTextUserName: string;
  akpiCommitments: { [akpiId: string]: { value: string; note: string } };
}

export const toTableData = (
  members: Member[],
  akpis: { id: string }[],
  akpiCommitments?: AkpiCommitments
): TableData[] =>
  members.map((m) => {
    const userCommitments = {} as {
      [akpiId: string]: { value: string; note: string };
    };
    for (const akpi of akpis) {
      const userCommitment =
        akpiCommitments?.[akpi.id]?.memberCommitments?.[m.domainId.itemId];
      userCommitments[akpi.id] = {
        value: userCommitment?.value ?? '',
        note: userCommitment?.note ?? '',
      };
    }
    return {
      userId: m.domainId.itemId,
      plainTextUserName: friendlyUsername(m),
      userName: <DisplayName user={m} />,
      akpiCommitments: userCommitments,
    };
  });

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MEETING_SUMMARY_FRAGMENT = gql`
  fragment MeetingSummary_Akpi on Akpi {
    id
    name
    unit
  }
`;
