import { LockFilled, PlusOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../../../../../../../components/SectionHeader';
import {
  Action,
  CurrentSprintMilestoneQueryDocument,
  CurrentSprintProgress_MitemSprintFragment,
  GetAccelerationMeetingDocument,
  GetSprintKeyActivitiesForMilestoneDocument,
  MilestoneStatus,
  MitemStatus,
} from '../../../../../../../../generated/graphql';
import { useClosestPeriods } from '../../../../../../../../hooks/useClosestPeriods';
import { mitemStatusColorPairs } from '../../../../../../../../styleVars';
import { SprintMitemTableActions } from '../../../../../../sprint/summary/components/SprintMitemTableActions';
import { AddExistingMitemDrawer } from './AddExistingMitemDrawer';
import { CreateMitemDrawer } from '../../../../../../sprint/planning/components/CreateMitemDrawer';
import { useAccelerationMeeting } from '../../../AccelerationMeetingProvider';
import { MitemCard } from './components/MitemCard';
import './CurrentSprintProgress.less';
import { SprintDetailsCard } from './currentSprintProgress/SprintDetailsCard';
import { SprintProgressCard } from './currentSprintProgress/SprintProgressCard';
import { SkaTable } from '../../../components/SkaTable';
import { isPossibleToChangeArchiveState } from '../../../../../../sprint/common/services/useMitemArchivation';
import { Btn } from '../../../../../../../../components/Button';
import { useTeamPermissions } from '../../../../../../../../usePermissions';
import { TourLink } from '../../../../../../../../components/TourLink';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useTenantDetails } from '../../../../../../../../hooks/useTenantDetails';
import { useCreateMitemModal } from '../../../../../../sprint/common/hooks/useCreateMitemModal';

interface Props {
  currentSprint?: CurrentSprintProgress_MitemSprintFragment;
  teamId: string;
}

export const CurrentSprintProgress = ({ currentSprint, teamId }: Props) => {
  const { t } = useTranslation();
  const { features } = useTenantDetails();
  const sprintKaCreationModal = useCreateMitemModal();

  const { currentPeriod } = useClosestPeriods(teamId);
  const { data: milestoneData } = useQuery(
    CurrentSprintMilestoneQueryDocument,
    { variables: { teamId } }
  );
  const { sprintKeyActivityContext } = useAccelerationMeeting();
  const { isAllowed: isAllowedToEditSka } = useTeamPermissions({
    requestedAction: {
      resource: 'sprintKA',
      action: Action.UPDATE,
    },
    teamId: teamId,
  });

  const [showAddExistingMitemModal, setShowAddExistingMitemModal] =
    useState(false);

  if (!currentSprint) return null;

  const sprintKeyActivities = currentSprint.mitems ?? [];

  return (
    <>
      <div className="flx mb--xl">
        <SprintProgressCard
          total={sprintKeyActivities.length}
          completed={
            sprintKeyActivities.filter((m) => m.status === 'COMPLETED').length
          }
        />
        <SprintDetailsCard sprint={currentSprint} />
      </div>
      <div className="mb--xl">
        <SectionHeader
          title={
            <div className="flx flx--ai-baseline">
              <div className="mr--l CurrentSprintProgress__lockIcon">
                <LockFilled />
              </div>

              <Typography.Title level={3} className="mb--none">
                {t('CurrentSprintProgress.title')}
              </Typography.Title>
              <TourLink
                intercomTarget="Acceleration meeting - Sprint key activity commitment guide modal"
                engTourId={3561}
                sweTourId={3417}
              />
            </div>
          }
          extra={
            <div>
              <Btn
                className="mr--s"
                onClick={() => {
                  setShowAddExistingMitemModal(true);
                }}
                icon={<PlusOutlined />}
              >
                {t('CurrentSprintProgress.addExisting')}
              </Btn>
              <Btn
                onClick={() => {
                  sprintKaCreationModal.openModal({
                    deadline: currentPeriod?.timePeriodEndDate,
                    refetchQueries: [
                      {
                        query: GetAccelerationMeetingDocument,
                        variables: { teamId: currentSprint.teamId },
                      },
                      {
                        query: CurrentSprintMilestoneQueryDocument,
                        variables: { teamId },
                      },
                    ],
                  });
                }}
                icon={<PlusOutlined />}
              >
                {t('common.create')}
              </Btn>
            </div>
          }
        >
          <SkaTable
            mitems={sprintKeyActivities.filter(
              (t) => t.status !== MitemStatus.COMPLETED
            )}
            pagination={false}
            teamId={teamId}
            committable={true}
            milestones={
              milestoneData?.milestonesWithLinksForTeam.milestones.filter(
                (m) =>
                  dayjs(m.deadlineAt).isSameOrBefore(currentSprint?.endDate) &&
                  m.metadata.status !== MilestoneStatus.COMPLETED
              ) ?? []
            }
            actionColumn={
              isAllowedToEditSka
                ? {
                    title: t('common.actions') as string,
                    dataIndex: 'actions',
                    key: 'actions',
                    render: (_, record) => {
                      if (record.__typename === 'Mitem') {
                        return (
                          <SprintMitemTableActions
                            teamId={teamId}
                            mitem={record}
                            isLockedSprint={currentSprint.locked}
                            isAllowedToArchive={
                              currentSprint
                                ? isPossibleToChangeArchiveState(
                                    record,
                                    currentSprint
                                  )
                                : false
                            }
                            editable={
                              !(
                                record.status === MitemStatus.NOT_COMPLETED ||
                                record.status === MitemStatus.ARCHIVED
                              )
                            }
                            onEdited={(sprintKaId, ownerId) => {
                              sprintKeyActivityContext.setUserCommitment(
                                sprintKaId,
                                {
                                  userId: ownerId,
                                }
                              );
                            }}
                            onCopied={(sprintKaId, ownerId) => {
                              sprintKeyActivityContext.addSprintKeyActivity(
                                sprintKaId,
                                ownerId
                              );
                            }}
                            refetchQueries={[
                              {
                                query: GetAccelerationMeetingDocument,
                                variables: { teamId: currentSprint.teamId },
                              },
                            ]}
                          />
                        );
                      }
                      if (record.__typename === 'MilestoneWithLinks') {
                        return (
                          <Btn
                            icon={<PlusOutlined style={{ fontSize: 20 }} />}
                            type="text"
                            className="txt--secondary"
                            onClick={(e) => {
                              e.stopPropagation();
                              return sprintKaCreationModal.openModal({
                                deadline: currentPeriod?.timePeriodEndDate,
                                preselectedMilestone: record,
                                refetchQueries: [
                                  {
                                    query: GetAccelerationMeetingDocument,
                                    variables: { teamId: currentSprint.teamId },
                                  },
                                  {
                                    query: CurrentSprintMilestoneQueryDocument,
                                    variables: { teamId },
                                  },
                                  {
                                    query:
                                      GetSprintKeyActivitiesForMilestoneDocument,

                                    variables: {
                                      milestoneId: record.domainId.itemId,
                                    },
                                  },
                                ],
                              });
                            }}
                          />
                        );
                      }
                    },
                  }
                : null
            }
          />
        </SectionHeader>
      </div>
      {features.tenantInitiativesEnabled && (
        <div className="mt--xl mb--xl">
          <SectionHeader title={t('CurrentSprintProgress.milestoneHeader')}>
            <SkaTable
              teamId={currentSprint.teamId}
              milestones={
                milestoneData?.milestonesWithLinksForTeam.milestones.filter(
                  (m) =>
                    dayjs(m.deadlineAt).isAfter(currentSprint?.endDate) &&
                    m.metadata.status !== MilestoneStatus.COMPLETED
                ) ?? []
              }
              pagination={false}
              actionColumn={
                isAllowedToEditSka
                  ? {
                      title: t('common.actions') as string,
                      dataIndex: 'actions',
                      key: 'actions',
                      render: (_, record) => {
                        if (record.__typename === 'MilestoneWithLinks') {
                          return (
                            <Btn
                              icon={<PlusOutlined style={{ fontSize: 20 }} />}
                              type="text"
                              className="txt--secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                return sprintKaCreationModal.openModal({
                                  deadline: currentPeriod?.timePeriodEndDate,
                                  preselectedMilestone: record,
                                  refetchQueries: [
                                    {
                                      query: GetAccelerationMeetingDocument,
                                      variables: {
                                        teamId: currentSprint.teamId,
                                      },
                                    },
                                    {
                                      query:
                                        CurrentSprintMilestoneQueryDocument,
                                      variables: { teamId },
                                    },
                                    {
                                      query:
                                        GetSprintKeyActivitiesForMilestoneDocument,

                                      variables: {
                                        milestoneId: record.domainId.itemId,
                                      },
                                    },
                                  ],
                                });
                              }}
                            />
                          );
                        }
                      },
                    }
                  : null
              }
            />
          </SectionHeader>
        </div>
      )}
      <div className="mt--xl">
        <MitemCard
          title={t('common.sprintKeyActivity.statuses.completed')}
          titleColors={mitemStatusColorPairs.COMPLETED}
        >
          <SkaTable
            teamId={currentSprint.teamId}
            mitems={sprintKeyActivities.filter(
              (t) => t.status === MitemStatus.COMPLETED
            )}
            milestones={
              milestoneData?.milestonesWithLinksForTeam.milestones.filter(
                (m) =>
                  m.metadata.status === MilestoneStatus.COMPLETED &&
                  dayjs(m.deadlineAt).isAfter(currentSprint?.startDate)
              ) ?? []
            }
            pagination={false}
          />
        </MitemCard>
      </div>
      <AddExistingMitemDrawer
        teamId={currentSprint.teamId}
        showModal={showAddExistingMitemModal}
        refetchQueries={[
          {
            query: GetAccelerationMeetingDocument,
            variables: { teamId: currentSprint.teamId },
          },
        ]}
        onCancel={() => {
          setShowAddExistingMitemModal(false);
        }}
      />
      <CreateMitemDrawer
        teamId={currentSprint.teamId}
        showHelpLinks={false}
        showModal={sprintKaCreationModal.isOpen}
        onCancel={() => {
          sprintKaCreationModal.closeModal();
          setShowAddExistingMitemModal(false);
        }}
        onCompleted={(sprintKeyActivity) => {
          sprintKeyActivityContext.addSprintKeyActivity(
            sprintKeyActivity.id,
            sprintKeyActivity.owner2.domainId.itemId
          );
          sprintKaCreationModal.closeModal();
          setShowAddExistingMitemModal(false);
        }}
        prefilledValues={sprintKaCreationModal.prefilledValues}
        refetchQueries={sprintKaCreationModal.refetchQueries}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_SUMMARY = gql`
  fragment CurrentSprintProgress_MitemSprint on MitemSprint {
    id
    teamId
    locked
    finalized
    mitems {
      status
      ...SkaTable_Mitem
      ...SprintMitemTableActions_Mitem
    }
    ...SprintDetailsCard_MitemSprint
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CURRENT_SPRINT_PROGRESS_MILESTONES = gql`
  query currentSprintMilestoneQuery($teamId: ID!) {
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        domainId {
          itemId
          tenantId
        }
        deadlineAt
        name
        metadata {
          completedAt
          archived
          status
        }
        ...SkaTable_Milestone
      }
    }
  }
`;
