import { useMutation } from '@apollo/client';
import { Form, Input, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../../../../components/Button';
import { ErrorAlert } from '../../../../../components/ErrorAlert';
import {
  CreateTeamTagDocument,
  GetTeamTagsDocument,
  UpdateTeamTagDocument,
} from '../../../../../generated/graphql';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { SelectionIndicator } from '../../../../company/strategicPlan/components/SelectionIndicator';
import { modalType } from './AddLabelModal';

interface Props {
  teamId: string;
  tagId?: string;
  visible: boolean;
  onCancel?: () => void;
  onCompleted?: (data: {
    id: string;
    name: string;
    backgroundColor: string;
  }) => void;
  title: string;
  currentName?: string;
  currentBackgroundColor?: string;
  modalActionType: modalType;
}

enum colorBox {
  blue = '#CEE7FF',
  violet = '#D9D9FF',
  purple = '#EECAFF',
  pink = '#FFD0E7',
  red = '#FFB9B9',
  yellow = '#F4E3B6',
  green = '#C6F4B6',
  teal = '#A4F4E1',
}

export const AddOrUpdateTagModal = ({
  teamId,
  tagId,
  visible,
  title,
  onCancel,
  onCompleted,
  currentBackgroundColor,
  currentName,
  modalActionType,
}: Props) => {
  const [tagColor, setTagColor] = useState<string>(colorBox.blue);
  const [tagName, setTagName] = useState<string>();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      tagName: currentName,
    });
  }, [currentName, form]);

  useEffect(() => {
    currentBackgroundColor
      ? setTagColor(currentBackgroundColor)
      : setTagColor(colorBox.blue);
  }, [currentBackgroundColor, visible]);

  const [addTag, { error }] = useMutation(CreateTeamTagDocument, {
    refetchQueries: () => {
      return [
        {
          query: GetTeamTagsDocument,
          variables: { teamId },
        },
      ];
    },
    onCompleted: (data) => {
      showNotification('success', {
        message: <strong>{t('Tags.tagAdded')}</strong>,
      });
      onCompleted?.(data.createTeamTag);
    },
    onError: () => {
      showNotification('error', {
        message: <ErrorAlert error={error} />,
      });
    },
  });

  const [updateTag, { error: updateError }] = useMutation(
    UpdateTeamTagDocument,
    {
      onCompleted: () => {
        showNotification('success', {
          message: <strong>{t('Tags.tagUpdated')}</strong>,
        });
        onCancel?.();
      },
      onError: () => {
        showNotification('error', {
          message: <ErrorAlert error={updateError} />,
        });
        onCancel?.();
      },
      refetchQueries: () => [
        {
          query: GetTeamTagsDocument,
          variables: { teamId },
        },
      ],
    }
  );

  const addNewTag = () => {
    form.validateFields().then((values) => {
      addTag({
        variables: {
          teamId: teamId,
          teamTagInput: {
            name: values.tagName,
            backgroundColor: tagColor,
          },
        },
      });
    });
  };

  const modifyTag = () => {
    form.validateFields().then((values) => {
      updateTag({
        variables: {
          teamId: teamId,
          tagId: tagId as string,
          updateTagInput: {
            name: values.tagName !== currentName ? values.tagName : undefined,
            backgroundColor:
              tagColor !== currentBackgroundColor ? tagColor : undefined,
          },
        },
      });
    });
  };

  const generateColorBoxes = () => {
    return (
      <div className="flx flx--row flx--jc-space-between  mb--l">
        {Object.values(colorBox).map((v, i) => {
          return (
            <SelectionIndicator active={tagColor === v} key={i}>
              <div
                className={'colorBox'}
                tabIndex={0}
                style={{ backgroundColor: v }}
                onClick={() => {
                  setTagColor(v);
                }}
              ></div>
            </SelectionIndicator>
          );
        })}
      </div>
    );
  };

  const isCreatingTag = modalActionType === modalType.createTag;

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      onOk={isCreatingTag ? addNewTag : modifyTag}
      destroyOnClose
      title={title}
      width={350}
      footer={
        <Btn
          className="CtaButton"
          onClick={isCreatingTag ? addNewTag : modifyTag}
        >
          {isCreatingTag ? `${t('Tags.create')}` : `${t('Tags.update')}`}
        </Btn>
      }
    >
      <Form
        data-testid="add-tag-form"
        form={form}
        layout="vertical"
        preserve={false}
      >
        <Form.Item
          name="tagName"
          label={t('Tags.tagName')}
          rules={[
            {
              required: true,
            },
            {
              min: 2,
              max: 30,
            },
          ]}
        >
          <Input
            autoComplete="off"
            type="text"
            onChange={(v) => setTagName(v.target.value)}
            value={tagName}
            autoFocus
          />
        </Form.Item>
        <p>{t('Tags.selectColor')}</p>
        {generateColorBoxes()}
      </Form>
    </Modal>
  );
};
