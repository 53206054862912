export const skaValidationRules = {
  name: [
    {
      required: true,
    },
    {
      max: 120,
    },
  ],

  definitionOfDone: [
    {
      required: false,
    },
  ],

  deadline: [
    {
      required: true,
    },
  ],

  ownerId: [
    {
      required: true,
    },
  ],

  supportsMigIds: (options: { migRequired: boolean }) => [
    {
      required: options.migRequired,
    },
  ],

  noMigAssociation: (options: { NoMigAssociationRequired: boolean }) => [
    {
      required: options.NoMigAssociationRequired,
    },
  ],

  supportsInitiativeIds: (options: { initiativeRequired: boolean }) => [
    {
      required: options.initiativeRequired,
    },
  ],
};
