import { Modal } from 'antd';
import { PureQueryOptions } from '@apollo/client';
import { Suspense } from 'react';
import { ActivityFormSkeleton } from '../ActivityFormSkeleton';
import { EditActivity } from './forms/EditActivity';

interface Props {
  open: boolean;
  teamId: string;
  activityId: string;
  refetchQueries?: Array<string | PureQueryOptions>;
  onCancel: () => void;
  onComplete?: () => void;
}

export const EditActivityModal = ({
  open,
  teamId,
  activityId,
  refetchQueries,
  onCancel,
}: Props) => {
  return (
    <Modal
      open={open}
      title={<h3 className="mb--l">Edit Activity</h3>}
      width={650}
      footer={null}
      onCancel={onCancel}
    >
      <Suspense fallback={<ActivityFormSkeleton />}>
        <EditActivity
          teamId={teamId}
          activityId={activityId}
          refetchQueries={refetchQueries}
          onSuccess={onCancel}
        />
      </Suspense>
    </Modal>
  );
};
