import { Modal } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ActivityDetails,
  SprintKeyActivityDetailsWrapper,
} from '../activities/ActivityDetails';

type ActiveModalType = {
  activityId: string;
  teamId: string;
};

export const KeyActivityDetailsAndReportModal = () => {
  const location = useLocation();
  const history = useHistory();

  const [activeModal, setActiveModal] = useState<ActiveModalType | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const openActivityDetails = params.get('openActivityDetails');
    const activityId = params.get('activityId');
    const teamId = params.get('teamId');

    if (openActivityDetails === 'true' && activityId && teamId) {
      setActiveModal({
        activityId,
        teamId,
      });
    }
  }, [location.search]);

  const handleCloseModal = () => {
    setActiveModal(null);

    // Preserve all other query parameters except those related to the modal
    const params = new URLSearchParams(location.search);
    params.delete('openActivityDetails');
    params.delete('activityId');
    params.delete('teamId');
    params.delete('activeTab');

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <div>
      <Modal
        open={!!activeModal}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        footer={null}
        modalRender={() => (
          <Suspense
            fallback={<ActivityDetails.skeleton onClose={handleCloseModal} />}
          >
            {activeModal && (
              <SprintKeyActivityDetailsWrapper
                skaId={activeModal.activityId}
                teamId={activeModal.teamId}
                onClose={handleCloseModal}
              />
            )}
          </Suspense>
        )}
      />
    </div>
  );
};
