import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../../Auth0Provider';
import HowweLogo from '../../images/howwe-logo.svg';
import FrostLogo from '../../images/frost-logo.svg';
import { useEffect, useState } from 'react';
import './AppLogo.less';

export const AppLogo = () => {
  const { t } = useTranslation();
  const { user } = useAuth0();

  const [frostThemeEnabled, setFrostThemeEnabled] = useState(
    document.documentElement.getAttribute('data-theme') === 'frost' ||
      user?.email.includes('frost.com')
  );

  // todo: remove this when frost has its own hosting
  useEffect(() => {
    if (user?.email.includes('frost.com')) {
      document.documentElement.setAttribute('data-theme', 'frost');
    }
    const handleThemeChange = () => {
      const frosty =
        document.documentElement.getAttribute('data-theme') === 'frost';
      setFrostThemeEnabled(frosty);
    };

    const observer = new MutationObserver(handleThemeChange);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  if (frostThemeEnabled) {
    return (
      <div>
        <img src={FrostLogo} alt="" className="src" />
        <div className="AppLogo--poweredBy txt--white font-size--xs">
          Powered by:
          <img src={HowweLogo} alt={t('Logo.alt')} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <img className="AppLogo" src={HowweLogo} alt={t('Logo.alt')} />
    </div>
  );
};
