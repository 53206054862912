import { gql } from '@apollo/client';
import { ActivityFilter_MitemFragment } from '../../../generated/graphql';
import { useTenantDetails } from '../../../hooks/useTenantDetails';
import { isDefined } from '../../../services/isDefined';
import { TagFilter } from './ActivityFilter/TagFilter';
import { useActivityFiltersInParams } from './ActivityFilter/useTeamActivityFiltersInParams';
import { OwnerFilter } from './ActivityFilter/OwnerFilter';
import { MilestoneFilter } from './ActivityFilter/MilestoneFilter';
import { InitiativeFilter } from './ActivityFilter/InitiativeFilter';
import { ProgessStatusFilter } from '../../company/initiatives/initiativeDetails/progressSection/components/ProgessStatusFilter';
import { MigFilter } from './ActivityFilter/MigFilter';

interface Props {
  mitems?: ActivityFilter_MitemFragment[] | null;
  teamId: string;
}

export const ActivityFilters = ({ mitems, teamId }: Props) => {
  const { features } = useTenantDetails();
  const { currentFilters, setFilter } = useActivityFiltersInParams();

  return (
    <div className="maxWidth">
      <div className="flx flx--gap mb">
        <MigFilter
          migs={mitems?.flatMap((m) => m.supportedMigs).filter(isDefined) ?? []}
          value={currentFilters.migId}
          onChange={setFilter.migId}
        />
        <OwnerFilter
          owners={mitems?.map((m) => m?.owner2).filter(isDefined) ?? []}
          value={currentFilters.ownerId}
          onChange={setFilter.mitemOwnerId}
        />
        {features.tenantInitiativesEnabled && (
          <>
            <InitiativeFilter
              teamId={teamId}
              value={currentFilters.initiativeId}
              onChange={setFilter.initiativeId}
            />
            <MilestoneFilter
              teamId={teamId}
              value={currentFilters.milestoneId}
              onChange={setFilter.milestoneId}
            />
          </>
        )}
        <TagFilter
          teamId={teamId}
          value={currentFilters.tags ?? null}
          onChange={setFilter.tags}
        />
      </div>
      <div className="flx flx--gap">
        <ProgessStatusFilter
          status={'completed'}
          checked={currentFilters.status.has('COMPLETED')}
          onChange={() => setFilter.mitemStatus('COMPLETED')}
        />
        <ProgessStatusFilter
          status={'overdue'}
          checked={currentFilters.status.has('OVERDUE')}
          onChange={() => setFilter.mitemStatus('OVERDUE')}
        />

        <ProgessStatusFilter
          status={'upcoming'}
          checked={currentFilters.status.has('UPCOMING')}
          onChange={() => setFilter.mitemStatus('UPCOMING')}
        />
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ACTIVITY_FILTER_MITEMS = gql`
  fragment ActivityFilter_Mitem on Mitem {
    id
    supportedMigs {
      id
      name
      domainId {
        itemId
      }
    }
    owner2 {
      id
      domainId {
        itemId
      }
      name
      email
      displayName
    }
  }
`;
