import { Empty, Spin } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import scrollIntoView from 'scroll-into-view-if-needed';
import { ErrorAlert } from '../../../../../components/ErrorAlert';
import { stringSort } from '../../../../../services/stringSort';
import { MigListEntry } from './migRelationsDrawer/MigListEntry';
import './RelatedMigs.less';
import { gql, useQuery } from '@apollo/client';
import { GetTeamMigRelationsDocument } from '../../../../../generated/graphql';

interface Mig {
  id: string;
  name: string;
  team2: {
    id: string;
    name: string;
    domainId: {
      itemId: string;
    };
  };
  domainId: {
    itemId: string;
  };
}

interface Props {
  parentMigId: string;
  teamId: string;
  expandedMig: Mig | null;
  onExpandMig: (mig: Mig) => void;
  inspectedChildMig: Mig | null;
  onInspectChildMig: (mig: Mig | null) => void;
}

export const RelatedMigs = ({
  parentMigId,
  teamId,
  expandedMig,
  onExpandMig,
  onInspectChildMig,
}: Props) => {
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(GetTeamMigRelationsDocument, {
    variables: { teamId, migId: parentMigId },
  });

  const teamMig = data?.teamMig;

  const sortedRelations = [...(teamMig?.supportedByMigs ?? [])].sort((a, b) => {
    const teamNameDelta = stringSort(a.team2.name, b.team2.name);

    if (teamNameDelta !== 0) return teamNameDelta;

    return stringSort(a.name, b.name);
  });

  const firstMigElem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (firstMigElem.current) {
      scrollIntoView(firstMigElem.current, {
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [firstMigElem]);

  return (
    <div className="RelatedMigs">
      {loading && (
        <div className="center-content">
          <Spin spinning={loading} />
        </div>
      )}

      {!loading && sortedRelations.length === 0 && (
        <Empty
          description={t('RelatedMigs.noSupporters')}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}

      {sortedRelations.map((mig, index) => {
        const isExpanded = expandedMig?.id === mig.id;

        return (
          <div key={mig.id} ref={index === 0 ? firstMigElem : null}>
            <MigListEntry
              mig={mig}
              isExpanded={isExpanded}
              faded={!isExpanded && !!expandedMig}
              onExpandRelations={() => onExpandMig(mig)}
              onInspectChildMig={onInspectChildMig}
            />
          </div>
        );
      })}
      {error && (
        <ErrorAlert error={error} title={t('RelatedMigs.fetchError')} />
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RELATED_MIGS__MIG_V2 = gql`
  fragment TeamMigRelation on MigV2 {
    id
    name
    domainId {
      itemId
      tenantId
    }
    team2 {
      id
      domainId {
        itemId
      }
      name
    }
    ...MigListEntry_MigV2
    supportedByMigs {
      id
      ...TeamMigRelationMigLight
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TEAM_MIG_FRAGMENT_WITH_RELATIONS = gql`
  fragment TeamMigRelationMigLight on MigLight {
    id
    name
    domainId {
      itemId
      tenantId
      tenantId
      tenantId
    }
    team2 {
      id
      domainId {
        itemId
      }
      name
    }
    ...MigListEntry_MigLight
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_MIG_RELATIONS = gql`
  query getTeamMigRelations($teamId: ID!, $migId: ID!, $tenantId: ID) {
    teamMig(teamId: $teamId, migId: $migId, tenantId: $tenantId) {
      ...TeamMigRelation
    }
  }
`;
