import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import * as d3 from 'd3';
import React, { useEffect, useReducer, useState } from 'react';
import { RecursiveTreeNode } from './RecursiveTreeNode/RecursiveTreeNode';
import './Tree.less';
import { MapInteraction } from 'react-map-interaction';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Btn } from '../../../../../components/Button';
import { ga4 } from '../../../../../services/ga4/ga4';

const nodeWidth = 170;
const nodeHeight = 70;

const treeMap = d3
  .tree<Team>()
  .nodeSize([nodeWidth, nodeHeight * 2.5])
  .separation(() => 1.1);

const nodeCalculation = d3
  .stratify<Team>()
  .id((d) => {
    return d.domainId.itemId;
  })
  .parentId((d) => {
    return d.parentTeam?.domainId.itemId;
  });

interface Props {
  teamData: Team[];
  tenantName: string;
}

export interface ToggleableNode<T> extends d3.HierarchyPointNode<T> {
  _children?: ToggleableNode<T>[];
}

interface Team {
  id: string;
  domainId: {
    itemId: string;
  };
  name: string;
  parentTeam?: { id: string; domainId: { itemId: string } } | null;
  component: React.ReactNode;
}

const DEFAULT_SCALE = 1;
const DEFAULT_TRANSLATION = { x: 0, y: 100 };
export const Tree = ({ teamData, tenantName }: Props) => {
  const { t } = useTranslation();
  const [mapState, setMapState] = useState({
    scale: DEFAULT_SCALE,
    translation: DEFAULT_TRANSLATION,
  });
  //https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [treeData, setTreeData] = useState(() => {
    return getTreeData(t, teamData, tenantName);
  });

  useEffect(() => {
    setTreeData(getTreeData(t, teamData, tenantName));
  }, [t, teamData, tenantName]);

  const modifier = useKeyPress();

  const descendants = (d: ToggleableNode<Team>): ToggleableNode<Team>[] => {
    let desc = [d];
    const children = d.children || d._children;

    if (children && children.length) {
      desc = desc.concat(children);
      return desc.concat(...children.map(descendants));
    }

    return desc;
  };

  const toggleChildren = (
    clicked: ToggleableNode<Team>,
    e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    ga4.gtag('event', 'team_tree_interaction', {
      interaction_type: 'expand_children',
      team_id: clicked.data.domainId.itemId,
      level: clicked.depth,
    });

    if (e.shiftKey && e.altKey) {
      if (clicked.children) {
        descendants(clicked).forEach(hideChildren_mutating);
      } else {
        descendants(clicked).forEach(showChildren_mutating);
      }
    } else if (e.altKey) {
      descendants(treeData)
        .filter((n) => n.depth === clicked.depth)
        .forEach(hideChildren_mutating);
      descendants(clicked).forEach(showChildren_mutating);
    } else if (e.shiftKey) {
      if (clicked.children) {
        hideChildren_mutating(clicked);
      } else {
        showChildren_mutating(clicked);
      }
    } else {
      if (clicked.children) {
        hideChildren_mutating(clicked);
      } else {
        descendants(treeData)
          .filter((n) => n.depth >= clicked.depth)
          .forEach(hideChildren_mutating);
        showChildren_mutating(clicked);
      }
    }

    const newTreeData = treeMap(treeData);
    setTreeData(newTreeData);

    // We need to emulate forceUpdate since useState setters do not trigger a
    // re-render if the new state is the same as the previous,
    // which is always the case with treeData since D3 mutates the same instance
    // rather than creating a new one.
    forceUpdate();
  };

  return (
    <div className="Tree">
      <div className="Tree__controls">
        <Btn
          className="ml--auto"
          onClick={() => {
            setMapState({
              scale: DEFAULT_SCALE,
              translation: DEFAULT_TRANSLATION,
            });
          }}
        >
          {t('Tree.resetButtonLabel')}
        </Btn>
      </div>
      <MapInteraction
        showControls={true}
        value={mapState}
        onChange={setMapState}
        minScale={0.2}
        maxScale={2}
        plusBtnContents={<PlusOutlined />}
        minusBtnContents={<MinusOutlined />}
        btnClass="Tree__zoomButton ant-btn"
      >
        {({ translation, scale }) => {
          // Translate first and then scale.  Otherwise, the scale would affect the translation.
          const transform = `translate(${translation.x}px, ${translation.y}px) scale(${scale})`;
          return (
            <div
              style={{
                height: '100%',
                width: '100%',
                position: 'relative', // for absolutely positioned children
                overflow: 'hidden',
                touchAction: 'none', // Not supported in Safari :(
                msTouchAction: 'none',
                cursor: 'all-scroll',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
              }}
            >
              <div
                style={{
                  transform: transform,
                  transformOrigin: '0 0 ',
                }}
              >
                <div className="Tree__actualTree">
                  <RecursiveTreeNode
                    node={treeData}
                    component={treeData.data.component}
                    width={nodeWidth}
                    height={nodeHeight}
                    onToggle={toggleChildren}
                    modifier={modifier}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </MapInteraction>
    </div>
  );
};

interface Toggleable {
  _children?: Toggleable[];
  children?: Toggleable[];
}
const hideChildren_mutating = <T extends Toggleable>(node: T) => {
  node._children = node._children || node.children;
  node.children = undefined;
};

const showChildren_mutating = <T extends Toggleable>(node: T) => {
  node.children = node.children || node._children;
  node._children = undefined;
};

function getTreeData(t: TFunction, teamData: Team[], tenantName: string) {
  let hierarchialData;
  try {
    hierarchialData = nodeCalculation(teamData);
  } catch (e: any) {
    if (e.message === 'multiple roots') {
      const fakeRoot = createFakeRoot(tenantName);
      const teamsWithFakeRoot = teamData.map((t) =>
        t.parentTeam?.domainId.itemId
          ? t
          : {
              ...t,
              parentTeamId: fakeRoot.domainId.itemId,
              parentTeam: {
                id: fakeRoot.domainId.itemId,
                domainId: {
                  itemId: fakeRoot.domainId.itemId,
                },
              },
            }
      );
      teamsWithFakeRoot.push(fakeRoot);
      hierarchialData = nodeCalculation(teamsWithFakeRoot);
    } else {
      throw e;
    }
  }
  if (!hierarchialData) {
    throw new Error(t('Tree.noRootNodeError'));
  }
  return treeMap(
    hierarchialData.copy().eachAfter((n) => {
      if (n.depth >= 1 && n.children) {
        hideChildren_mutating(n);
      }
    })
  );
}

function useKeyPress() {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState({
    altKey: false,
    ctrlKey: false,
    shiftKey: false,
    metaKey: false,
  });

  function downHandler({ altKey, ctrlKey, shiftKey, metaKey }: KeyboardEvent) {
    if (
      [altKey, ctrlKey, shiftKey, metaKey].some((isDown) => isDown === true)
    ) {
      setKeyPressed({ altKey, ctrlKey, shiftKey, metaKey });
    }
  }

  type ModifierKey = 'altKey' | 'ctrlKey' | 'shiftKey' | 'metaKey';
  const upHandler = ({ altKey, ctrlKey, shiftKey, metaKey }: KeyboardEvent) => {
    const modifiers = Object.entries({
      altKey,
      ctrlKey,
      shiftKey,
      metaKey,
    }) as [ModifierKey, boolean][];
    if (modifiers.some(([key, isDown]) => keyPressed[key] !== isDown)) {
      setKeyPressed({ altKey, ctrlKey, shiftKey, metaKey });
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
}

const createFakeRoot = (tenantName: string) => ({
  id: 'fake-root',
  domainId: {
    itemId: 'fake-root',
  },
  name: 'Company', // FIXME this can probably be removed
  parentTeam: null,
  component: <div className="FakeRoot center-content">{tenantName}</div>,
});
