import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import './MilestoneSelectFormItem.less';
import { GetTeamMigsForSelectorDocument } from '../../../../../generated/graphql';

interface Props {
  teamId: string;
  disabled?: boolean;
  value?: string;
  onChange?: (value: string) => void;
}

export const MigSelectFormItem = ({
  teamId,
  value,
  onChange,
  disabled,
}: Props) => {
  const { data, loading } = useQuery(GetTeamMigsForSelectorDocument, {
    variables: { teamId },
  });

  const migs = data?.teamMigs.migs ?? [];

  if (migs.length === 0) {
    return (
      <Select
        placeholder={
          <span className="font-size--sm">
            No active or upcoming Migs for this team
          </span>
        }
        disabled
        style={{ minWidth: 200 }}
      />
    );
  }

  return (
    <Select
      placeholder={<span className="font-size--sm">Select Mig</span>}
      value={value}
      onChange={onChange}
      disabled={disabled}
      loading={loading}
      style={{ minWidth: 200 }}
      allowClear
      options={migs.map((mig) => ({
        label: <span className="font-size--sm">{mig.name}</span>,
        value: mig.id,
      }))}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAM_MIGS_FOR_SELECTOR = gql`
  query getTeamMigsForSelector($teamId: ID!) {
    teamMigs(teamId: $teamId, statusFilters: [ACTIVE, UPCOMING]) {
      migs {
        id
        name
        domainId {
          itemId
          teamId
        }
      }
    }
  }
`;
