import { useTranslation } from 'react-i18next';

import './ActivityStatus.less';
import { MitemStatus } from '../generated/graphql';
import { match } from 'ts-pattern';
import cx from 'classnames';
import { getSimpleStatus } from '../services/mitemUtils';
import { KeyActivityStatusIcon } from './KeyActivityStatusIcon';

interface Props {
  status: MitemStatus;
}

export const ActivityStatus = ({ status }: Props) => {
  const { t } = useTranslation();
  const simpleStatus = getSimpleStatus(status);

  const statusTag = match(simpleStatus)
    .with('COMPLETED', () => ({
      className: 'txt--success',
      text: t('common.simpleStatus.COMPLETED'),
    }))
    .with('OVERDUE', () => ({
      className: 'txt--warning',
      text: t('common.simpleStatus.OVERDUE'),
    }))
    .with('ARCHIVED', () => ({
      className: 'txt--secondary',
      text: t('common.simpleStatus.ARCHIVED'),
    }))
    .with('UPCOMING', () => ({
      className: 'txt--planned',
      text: t('common.simpleStatus.UPCOMING'),
    }))
    .exhaustive();

  return (
    <div
      className={cx('flx ActivityStatus font-size--sm', statusTag.className)}
    >
      <KeyActivityStatusIcon status={simpleStatus} />
      <span className="ml--s">{statusTag.text}</span>
    </div>
  );
};
