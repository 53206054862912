import {
  InfoCircleOutlined,
  RedoOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { Card, Collapse, Divider, Result, Spin, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  GetTeamMigsToUpdateDocument,
  MigStatus,
} from '../../generated/graphql';
import { getMigWarnings } from '../../services/getMigWarnings';
import { migDTOtoMigData } from '../../services/migUtils';
import { ErrorAlert } from '../ErrorAlert';
import { UpdateMig } from './UpdateMig';
import './UpdateMigs.less';
import { Btn } from '../Button';
import dayjs from 'dayjs';

interface Props {
  teamId: string;
  migId: string;
}

export const UpdateMigs = ({ teamId, migId }: Props) => {
  const { t } = useTranslation();
  const [activePanel, setActivePanel] = useState<string[]>([migId]);
  const { loading, data, error } = useQuery(GetTeamMigsToUpdateDocument, {
    variables: {
      teamId: teamId,
      statusFilters: [MigStatus.ACTIVE, MigStatus.OVERDUE, MigStatus.ENDED],
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !data)
    return (
      <div className="center-content">
        <Spin size="large" delay={250} />
      </div>
    );

  if (!data)
    return (
      <Result
        status="error"
        title={t('TeamPerformanceMigCard.fetchMigError')}
        extra={
          <Btn
            type="primary"
            key="reload"
            icon={<RedoOutlined />}
            onClick={() => window.location.reload()}
          >
            {t('TeamPerformanceMigCard.reloadPage')}
          </Btn>
        }
        subTitle={t('TeamPerformanceMigCard.fetchMigErrorLong')}
      />
    );
  const migs = data.teamMigs.migs.map((mig) => migDTOtoMigData(mig, true));
  const teamMigs = migs.filter((mig) => !mig.isMirrored);
  const mirroredMigs = migs.filter((mig) => mig.isMirrored);
  const activeAndOverdueTeamMigs = teamMigs.filter(
    (m) => m.status === MigStatus.OVERDUE || m.status === MigStatus.ACTIVE
  );

  return (
    <>
      <Collapse
        accordion
        expandIconPosition="end"
        activeKey={activePanel ?? undefined}
        onChange={(key) => setActivePanel(Array.isArray(key) ? key : [key])}
      >
        {activeAndOverdueTeamMigs.map((mig) => {
          const warnings = getMigWarnings(
            mig.periodData,
            mig.timePeriodInterval,
            dayjs()
          );

          const hasWarnings = warnings.length > 0;
          return (
            <Collapse.Panel
              key={mig.id}
              header={
                <div>
                  {hasWarnings && (
                    <WarningOutlined className="txt--warning UpdateMigs__iconWarning" />
                  )}
                  {mig.name}
                </div>
              }
            >
              <UpdateMig
                migData={mig}
                teamId={teamId}
                onClose={() => setActivePanel([])}
              />
            </Collapse.Panel>
          );
        })}
      </Collapse>
      {mirroredMigs.length > 0 && (
        <>
          <Divider className="mt--xl" />
          <div>
            <Typography.Title level={4}>
              {' '}
              {t('MirrorMig.title')}
            </Typography.Title>
            <div className="mb--l">
              <InfoCircleOutlined style={{ fontSize: 16, marginRight: 12 }} />
              {t('MirrorMig.helpText')}
            </div>
            {mirroredMigs.map((mig) => (
              <Card type="inner" className="mb--s" key={mig.id}>
                <div className="MigCard__title" title={mig.name}>
                  {mig.name}
                </div>
                <div>
                  {t(`common.mig.managedBy`)}
                  <Link to={`/team/${mig.domainId.teamId}`}>
                    {mig.team2.name}
                  </Link>
                </div>
              </Card>
            ))}
          </div>
        </>
      )}
      {error && (
        <ErrorAlert
          error={error}
          title={t('TeamPerformanceMigCard.fetchMigError')}
        />
      )}
    </>
  );
};

export const GET_TEAM_MIGS_TO_UPDATE = gql`
  query getTeamMigsToUpdate($teamId: ID!, $statusFilters: [MigStatus!]) {
    teamMigs(teamId: $teamId, statusFilters: $statusFilters) {
      migs {
        id
        rev
        timePeriodInterval
        isMirrored(teamId: $teamId)
        name
        unit
        status
        domainId {
          itemId
          teamId
          tenantId
        }
        periodData {
          targetDate
          actual
          goal
          historic
        }
        team2 {
          id
          domainId {
            itemId
          }
          name
        }
      }
    }
  }
`;
