import dayjs, { Dayjs } from 'dayjs';
import {
  getMonthsBetween,
  groupDeadlinesByMonth,
} from '../TeamActivitiesRoutes';
import { useMemo, useState } from 'react';

export const useActivityList = <
  T extends {
    deadline: string;
  },
  U extends {
    deadlineAt: string;
    domainId: { itemId: string };
  },
>(
  startDate: Dayjs,
  activitiesForMilestone: T[] = [],
  _milestones: U[] = []
) => {
  const [selectedMilestoneId, setSelectedMilestoneId] = useState<string | null>(
    null
  );

  const [monthRange, setMonthRange] = useState<{
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  }>(() => dateToDefaultRange(startDate));

  const milestones = useMemo(
    () =>
      _milestones.filter((m) =>
        monthRange.start.isSameOrBefore(m.deadlineAt)
      ) ?? [],
    [_milestones, monthRange.start]
  );

  const milestonesByMonth = useMemo(() => {
    return Array.from(groupDeadlinesByMonth(milestones ?? []).entries());
  }, [milestones]);

  const selectedMilestone = milestones.find(
    (m) => m.domainId.itemId === selectedMilestoneId
  );

  const monthsToDisplay = useMemo(() => {
    if (selectedMilestoneId) {
      if (activitiesForMilestone.length === 0) return [];

      const { firstDeadline, lastDeadline } = activitiesForMilestone.reduce(
        (acc, activity) => ({
          firstDeadline:
            !acc.firstDeadline ||
            dayjs(activity.deadline).isBefore(acc.firstDeadline)
              ? dayjs(activity.deadline)
              : acc.firstDeadline,
          lastDeadline:
            !acc.lastDeadline ||
            dayjs(activity.deadline).isAfter(acc.lastDeadline)
              ? dayjs(activity.deadline)
              : acc.lastDeadline,
        }),
        {
          firstDeadline: dayjs(activitiesForMilestone[0].deadline),
          lastDeadline: dayjs(activitiesForMilestone[0].deadline),
        }
      );

      return getMonthsBetween(firstDeadline, lastDeadline);
    }
    return getMonthsBetween(monthRange.start, monthRange.end);
  }, [monthRange, activitiesForMilestone, selectedMilestoneId]);

  return {
    monthsToDisplay,
    monthRange,
    setMonthRange,
    selectedMilestone,
    selectMilestone: (milestoneId: string | null) => {
      setSelectedMilestoneId(milestoneId);
    },
    milestonesByMonth,
    activitiesForMilestone,
  };
};

const dateToDefaultRange = (date: Dayjs) => ({
  start: date,
  end: date.add(2, 'months').endOf('month'),
});
