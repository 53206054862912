import { Divider } from 'antd';
import { Btn } from '../../../../Button';
import { MarkAsCompleteIcon } from '../../icons/MarkAsCompleteIcon';

import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

export const MarkInitiativeAsCompletedModalContentSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className="text-c flx flx--column">
      <h3>{t('MarkInitiativeAsCompletedModalContent.title')}</h3>
      <Skeleton width={300} />
      <div>
        <MarkAsCompleteIcon className="txt--success" style={{ fontSize: 70 }} />
      </div>
      <Divider />
      <div>
        <Btn disabled type="success">
          {t('MarkInitiativeAsCompletedModalContent.markAsCompleted')}
        </Btn>
      </div>
    </div>
  );
};
