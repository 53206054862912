import { useDraggable } from '@dnd-kit/core';

interface Props {
  children: React.ReactNode;
  activity: any;
}

export const Draggable = ({ children, activity }: Props) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: activity.id,
    data: { activity },
  });

  return (
    <div ref={setNodeRef} {...attributes} {...listeners}>
      {children}
    </div>
  );
};
