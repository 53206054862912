import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { Divider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  GetAssignedMilestonesForReportDrawerDocument,
  GetMilestoneCollapseItemDetailsDocument,
  MilestoneCollapseItemMarkAsCompleted_MutationDocument,
} from '../../../../../generated/graphql';
import { GroupsIcon } from '../../../../../icons/GroupsIcon';
import { DisplayDate } from '../../../../DisplayDate';
import { InitiativeTag } from '../../../../initiative/InitiativeTag';
import { AlignmentIcon } from '../../../../initiatives/InitiativeForm/Icons/AlignmentIcon';
import { EndingPointIcon } from '../../../../initiatives/InitiativeForm/Icons/EndingPointIcon';
import { TextAreaIconIcon } from '../../../../initiatives/InitiativeForm/Icons/TextAreaIcon';
import { MilestoneActivitiesTable } from '../../../../../appPages/company/initiatives/initiativeDetails/progressSection/components/milestoneTable/components/MilestoneActivitiesTable';
import { Btn } from '../../../../Button';
import { howweErrorParser } from '../../../../../services/howweErrorParser';
import { showNotification } from '../../../../../services/fetchNotificationProperties';
import { Link } from 'react-router-dom';
import { motion } from 'motion/react';
import { useState } from 'react';

interface Props {
  milestoneId: string;
}

export const MilestoneCollapseItem = ({ milestoneId }: Props) => {
  const { data } = useSuspenseQuery(GetMilestoneCollapseItemDetailsDocument, {
    variables: { milestoneId },
  });
  const { t } = useTranslation();
  const milestone = data.milestoneWithLinks;
  const supportsInitiatives = milestone.metadata.supportsInitiatives;

  const [showingCompleteMarker, setShowingCompleteMarker] = useState(false);

  const [markAsCompleted, { loading }] = useMutation(
    MilestoneCollapseItemMarkAsCompleted_MutationDocument,
    {
      onError: (error) => {
        const howweErrors = howweErrorParser(error);

        showNotification('error', {
          message: t('MilestoneCollapseItem.saveError'),
          description: (
            <strong>
              <ul>
                {howweErrors?.map((e, i) => <li key={i}>{e.translation}</li>)}
              </ul>
            </strong>
          ),
        });
      },
      onCompleted: () => {
        setShowingCompleteMarker(true);
      },
      refetchQueries: () => [
        { query: GetAssignedMilestonesForReportDrawerDocument },
      ],
    }
  );

  const handleMarkAsCompleted = () => {
    markAsCompleted({
      variables: {
        milestoneId: milestoneId,
        milestone: {
          completed: true,
          rev: data.milestoneWithLinks.rev,
        },
      },
    });
  };

  return (
    <div>
      <div className="relative">
        <div className="flx mb--xl">
          <Tooltip title={t('common.milestone.description')}>
            <TextAreaIconIcon className="mr--l txt--secondary font-size--lg" />
          </Tooltip>
          {milestone.description}
        </div>
        <div className="flx mb--xl">
          <Tooltip title={t('common.milestone.deadline')}>
            <EndingPointIcon className="mr--l txt--secondary font-size--lg" />
          </Tooltip>
          <DisplayDate date={milestone.deadlineAt} />
        </div>
        <div className="flx mb--xl">
          <Tooltip title={t('common.milestone.supports')}>
            <AlignmentIcon className="mr--l txt--secondary font-size--lg" />
          </Tooltip>
          <div>
            {supportsInitiatives.map((supportsInitiativ) => (
              <Link
                key={supportsInitiativ.domainId.itemId}
                to={`/company/initiatives/${supportsInitiativ.domainId.itemId}`}
              >
                <InitiativeTag
                  key={supportsInitiativ.data.tag.title}
                  tag={supportsInitiativ.data.tag}
                />
              </Link>
            ))}
          </div>
        </div>
        <div className="flx mb--xl">
          <Tooltip title={t('common.milestone.contributingTeams')}>
            <GroupsIcon className="mr--l txt--secondary font-size--lg" />
          </Tooltip>
          {milestone.linkedTeams.map((team) => team.name).join(', ')}
        </div>
        {showingCompleteMarker && (
          <div
            className="center-content txt--success"
            style={{ inset: 0, position: 'absolute' }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="1 5 30 24"
              height="100%"
            >
              <motion.polyline
                fill="none"
                stroke="currentColor"
                strokeWidth="4"
                strokeMiterlimit="10"
                points="3,17 11,25 29,7"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 0.4, ease: 'easeIn' }}
              />
            </svg>
          </div>
        )}
      </div>
      <h4 className="mb"> {t('MilestoneCollapseItem.connectedActivities')}</h4>
      <MilestoneActivitiesTable milestoneItemId={milestoneId} />
      <Divider />
      <Btn
        type="primary"
        onClick={handleMarkAsCompleted}
        loading={loading}
        disabled={!!milestone.metadata.completedAt}
      >
        {t('MilestoneCollapseItem.markAsCompleted')}
      </Btn>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_SECTION_MARK_MILESTONE_AS_COMPLETED = gql`
  mutation milestoneCollapseItemMarkAsCompleted_mutation(
    $milestoneId: ID!
    $milestone: MilestoneUpdateInput!
  ) {
    updateMilestoneWithLinks(milestoneId: $milestoneId, milestone: $milestone) {
      id
      name
      rev
      metadata {
        completedAt
        status
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_SECTION_GET_MILESTONE = gql`
  query getMilestoneCollapseItemDetails($tenantId: ID, $milestoneId: ID!) {
    milestoneWithLinks(tenantId: $tenantId, milestoneId: $milestoneId) {
      id
      name
      description
      deadlineAt
      rev
      linkedTeams {
        id
        domainId {
          itemId
        }
        name
      }
      metadata {
        status
        archived
        completedAt
        supportsInitiatives {
          id
          domainId {
            itemId
          }
          data {
            id
            name
            tag {
              colorCode
              iconId
              title
            }
          }
        }
      }
    }
  }
`;
