import { ColumnProps } from 'antd/lib/table';
import { UserAvatar } from '../../../../../../../../../components/UserAvatar';
import { friendlyUsername } from '../../../../../../../../../services/friendlyUsername';
import { Table } from 'antd';
import { friendlyDate } from '../../../../../../../../../services/dateFormats';
import {
  GetAkpiAchievementsDocument,
  WkaAchievementFragment,
} from '../../../../../../../../../generated/graphql';
import { gql, useQuery } from '@apollo/client';
import { stringSort } from '../../../../../../../../../services/stringSort';
import './ReportsTable.less';

interface Props {
  teamId: string;
  wkaId: string;
  unit: string;
}

export const ReportsTable = ({ teamId, wkaId, unit }: Props) => {
  const { data } = useQuery(GetAkpiAchievementsDocument, {
    variables: {
      teamId,
      akpiId: wkaId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const sortedWkaAchievementsByDate = [
    ...(data?.akpiAchievements.achievements ?? []),
  ]
    .sort((a, b) => stringSort(a.created, b.created))
    .reverse();

  const columns: ColumnProps<WkaAchievementFragment>[] = [
    {
      dataIndex: ['name'],
      key: 'name',
      render: (_, record) => {
        return (
          <div className="flx flx--jc-space-between  ">
            <div className="ReportsTable__leftSection flx flx--column">
              <div>
                <UserAvatar user={record.user2} size="small" />
                <span className="ReportsTable__textSize ml--l">
                  {friendlyUsername(record.user2)}
                </span>
              </div>

              <div>
                <h5 className="txt--secondary mt--s preserveLinebreaks">
                  {record.description ? (
                    record.description
                  ) : (
                    <div className="txt--secondary">--</div>
                  )}
                </h5>
              </div>
            </div>

            <div className="flx flx--column ">
              <span className="ReportsTable__textSize ">
                {friendlyDate(record.created)}
              </span>
              <h5 className="txt--secondary mt--s">
                {record.quantity} {unit}
              </h5>
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <Table
        size="small"
        dataSource={sortedWkaAchievementsByDate}
        columns={columns}
        pagination={false}
        rowKey="id"
        showHeader={false}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WKA_ACHIEVEMENT = gql`
  fragment WkaAchievement on AkpiAchievement {
    id
    accelerationKpiId
    quantity
    description
    userId
    created
    user2 {
      id
      domainId {
        itemId
        tenantId
      }
      email
      name
      displayName
      initials
    }
  }

  query getAkpiAchievements($teamId: ID!, $akpiId: ID!) {
    akpiAchievements(teamId: $teamId, akpiId: $akpiId) {
      achievements {
        ...WkaAchievement
      }
    }
  }
`;
