import dayjs, { Dayjs } from 'dayjs';
import { ConfigProvider, DatePicker } from 'antd';
import { DisplayDate } from './DisplayDate';
import React from 'react';
import './DeadlineDeluxe.less';
import { useTranslation } from 'react-i18next';

interface Props {
  deadline: string | Dayjs;
  onChange: (date: Dayjs) => void;
  disabledDate?: (current: Dayjs) => boolean;
}

export const DeadlineDeluxe = ({ deadline, onChange, disabledDate }: Props) => {
  const { t } = useTranslation();
  const InputReplacement = (props: {
    ref: React.RefObject<any>;
    value: Dayjs;
  }) => {
    return (
      <span ref={props.ref} className="DeadlineDeluxe__input">
        <DisplayDate date={deadline} />
      </span>
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: { paddingInline: 0 },
        },
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DatePicker
          panelRender={(node) => (
            <div>
              <h4 className="text-c pa--s">
                {t('DeadlineDeluxe.selectNewDeadline')}
              </h4>
              {node}
            </div>
          )}
          className="DeadlineDeluxe"
          onChange={(date) => {
            if (date) {
              onChange?.(date);
            }
          }}
          allowClear={false}
          disabledDate={disabledDate}
          value={dayjs(deadline)}
          inputReadOnly
          variant="borderless"
          suffixIcon={null}
          needConfirm
          components={{ input: InputReplacement }}
        />
      </div>
    </ConfigProvider>
  );
};
