import { Switch } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MitemStatus,
  SprintKeyActivityProgressLog_MitemSprintFragment,
} from '../../../../../../../../../generated/graphql';
import { SprintKeyActivityUserSection } from './SprintKeyActivityProgressLog/SprintKeyActivityUserSection';
import { UserContributionData } from '../hooks/usePreviousPeriodSummary';
import { TeamContributingMemberFilter } from '../common/TeamContributingMemberFilter';
import { isDefined } from '../../../../../../../../../services/isDefined';
import { gql } from '@apollo/client';

interface Props {
  teamId: string;
  userContributionData?: UserContributionData[] | null;
  sprint?: SprintKeyActivityProgressLog_MitemSprintFragment | null;
}

const filterSprintKeyActivities = (
  sprintKeyActivities: UserContributionData['sprintKeyActivities'],
  showOnlyOverdueKeyActivities: boolean
) => {
  return showOnlyOverdueKeyActivities
    ? sprintKeyActivities?.filter((keyActivity) => {
        return (
          keyActivity.status === MitemStatus.OVERDUE ||
          keyActivity.status === MitemStatus.NOT_COMPLETED
        );
      })
    : sprintKeyActivities;
};

export const SprintKeyActivityProgressLog = ({
  userContributionData,
  sprint,
  teamId,
}: Props) => {
  const { t } = useTranslation();
  const [filteredTeamMemberId, setFilteredTeamMemberId] = useState<
    string | 'ALL_MEMEMBERS'
  >('ALL_MEMEMBERS');
  const [showOnlyOverdueKeyActivities, setShowOnlyOverdueKeyActivities] =
    useState(false);
  const [expandAllReports, setExpandAllReports] = useState(false);

  const filteredUsers =
    filteredTeamMemberId !== 'ALL_MEMEMBERS'
      ? userContributionData?.filter((u) => u.user.id === filteredTeamMemberId)
      : userContributionData;

  const skaUserSections = filteredUsers
    ?.map((userContributions) => {
      const filteredKeyActivities = filterSprintKeyActivities(
        userContributions.sprintKeyActivities,
        showOnlyOverdueKeyActivities
      );

      return { userContributions, skas: filteredKeyActivities };
    })
    .filter((section) => section.skas.length > 0)
    .map((section) => (
      <div
        key={section.userContributions.user.id}
        className="borderBottom pb mt--l mb"
      >
        <SprintKeyActivityUserSection
          user={section.userContributions.user}
          sprint={sprint}
          filteredSprintKeyActivities={section.skas}
          expandReports={expandAllReports}
        />
      </div>
    ));

  return (
    <div>
      <h3>{t('SprintKeyActivityProgressLog.title')}</h3>
      <div className="borderBottom flx pb--s">
        <TeamContributingMemberFilter
          contributingMembers={userContributionData
            ?.filter((cm) => cm.sprintKeyActivities.length > 0)
            .map((u) => ({ ...u.user, domainId: { itemId: u.user.id } }))}
          setFileredTeamMember={setFilteredTeamMemberId}
          teamId={teamId}
          className={'mr'}
        />

        <div
          className="flx flx--ai-center clickable mr"
          onClick={() =>
            setShowOnlyOverdueKeyActivities(!showOnlyOverdueKeyActivities)
          }
        >
          <Switch className="mr" checked={showOnlyOverdueKeyActivities} />
          {t('SprintKeyActivityProgressLog.onlyOverdue')}
        </div>
        <div
          className="flx flx--ai-center clickable"
          onClick={() => setExpandAllReports(!expandAllReports)}
        >
          <Switch className="mr" checked={expandAllReports} />
          {t('SprintKeyActivityProgressLog.expandAllReports')}
        </div>
      </div>
      <div>
        {isDefined(skaUserSections) && skaUserSections.length > 0 ? (
          skaUserSections
        ) : (
          <div className="center-content mt">
            {t('SprintKeyActivityProgressLog.noResults')}
          </div>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SPRINT_KEY_ACTIVITY_SUMMARY = gql`
  fragment SprintKeyActivityProgressLog_MitemSprint on MitemSprint {
    id
    ...SprintKeyActivityUserSection_MitemSprint
  }
`;
