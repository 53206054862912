import { gql } from '@apollo/client';
import { MilestonePlaner_InitiativeFragment } from '../../../generated/graphql';
import { MilestoneColumn } from './MilestoneColumn';

import { stringSort } from '../../../services/stringSort';

interface Props {
  teamId: string;
  initiative?: MilestonePlaner_InitiativeFragment;
}

export const MilestonePlaner = ({ teamId, initiative }: Props) => {
  return (
    <div style={{ overflowX: 'scroll' }}>
      <div className="flx">
        {initiative?.metadata?.supportedBy?.milestones
          .sort((a, b) => stringSort(a.data.deadlineAt, b.data.deadlineAt))
          .map((m) => (
            <MilestoneColumn key={m.id} milestone={m.data} teamId={teamId} />
          ))}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_PLANER_INITIATIVE_FRAGMENT = gql`
  fragment MilestonePlaner_Initiative on InitiativeWithLinks {
    id
    domainId {
      itemId
    }
    metadata {
      status
      supportedBy {
        milestones {
          id
          domainId {
            itemId
          }
          data {
            id
            deadlineAt
            ...MilestoneColumn_Milestone
          }
        }
      }
    }
  }
`;
