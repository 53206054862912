import { gql, useSuspenseQuery } from '@apollo/client';
import { ProgressStats } from '../../../../../../../components/initiatives/ProgressStats';
import { GetSprintKeyActivitiesStatsForMilestoneCardDocument } from '../../../../../../../generated/graphql';
import { MilestoneCardActivityStatsError } from './MilestoneCardActivityStats.Error';

interface Props {
  milestoneId: string;
  teamId: string;
  fontSize?: number;
}

export const MilestoneCardActivityStats = ({
  milestoneId,
  teamId,
  fontSize,
}: Props) => {
  const { data } = useSuspenseQuery(
    GetSprintKeyActivitiesStatsForMilestoneCardDocument,
    { variables: { milestoneId, teamId } }
  );

  return (
    <ProgressStats
      stats={data.sprintKeyActivitiesForMilestone.sprintKeyActivityStatsForTeam}
      fontSize={fontSize}
    />
  );
};

MilestoneCardActivityStats.Error = MilestoneCardActivityStatsError;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_SPRINT_KEY_ACTIVITIES_FOR_MILESTONE_CARD = gql`
  query getSprintKeyActivitiesStatsForMilestoneCard(
    $tenantId: ID
    $milestoneId: ID!
    $teamId: ID!
  ) {
    sprintKeyActivitiesForMilestone(
      tenantId: $tenantId
      milestoneId: $milestoneId
    ) {
      sprintKeyActivityStatsForTeam(teamId: $teamId) {
        completed
        upcoming
        overdue
      }
    }
  }
`;
