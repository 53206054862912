import { gql, useQuery } from '@apollo/client';
import { Skeleton } from 'antd';
import { GetInitsForInitiativeSelectFormItemDocument } from '../../../../../generated/graphql';
import { InitiativeTag } from '../../../../initiative/InitiativeTag';

interface Props {
  teamId: string;
  value?: string;
  onChange?: (value: string) => void;
}

// Add loading skeleton component
const InitiativeTagSkeleton = () => {
  return (
    <div className="flx flx--gap--s flx--wrap">
      {[1, 2, 3, 4].map((key) => (
        <Skeleton.Button
          key={key}
          active
          className="initiative-tag-skeleton"
          style={{
            width: '120px',
            height: '16px',
            borderRadius: '16px',
          }}
        />
      ))}
    </div>
  );
};

export const InitiativeTagSelectFormItem = ({
  teamId,
  value,
  onChange,
}: Props) => {
  const { data: initiativeData, loading } = useQuery(
    GetInitsForInitiativeSelectFormItemDocument,
    {
      variables: { teamId },
    }
  );

  // Show loading state while fetching data
  if (loading) {
    return <InitiativeTagSkeleton />;
  }

  const initiatives =
    initiativeData?.initiativesLinkedDirectlyOrIndirectlyToTeam.initiatives.filter(
      (initiative) =>
        initiative.metadata.completedAt == null && !initiative.metadata.archived
    ) ?? [];

  return (
    <div className="flx flx--gap--s flx--wrap">
      {initiatives.map((initiative) => (
        <div
          className="clickable"
          key={initiative.id}
          onClick={() => onChange?.(initiative.id)}
        >
          <InitiativeTag
            useBgColor={initiative.id === value}
            tag={initiative.tag}
            completed={initiative.metadata.completedAt !== null}
            archived={initiative.metadata.archived}
          />
        </div>
      ))}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MITEM_FORM_INITIATIVES_FOR_TEAM = gql`
  query GetInitsForInitiativeTagSelectFormItem($tenantId: ID, $teamId: ID!) {
    initiativesLinkedDirectlyOrIndirectlyToTeam(
      tenantId: $tenantId
      teamId: $teamId
    ) {
      id
      initiatives {
        ...InitiativeTagSelectFormItem_initiative
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const INITIATIVE_FRAGMENT = gql`
  fragment InitiativeTagSelectFormItem_initiative on InitiativeWithLinks {
    id
    domainId {
      itemId
    }
    tag {
      title
      iconId
      colorCode
    }
    metadata {
      completedAt
      status
      archived
    }
  }
`;
