import { Typography, Divider, Card } from 'antd';
import { useEffect, useMemo } from 'react';
import {
  GetTeamsForLinkMigsDocument,
  LinkMigSteps_MigV2Fragment,
  MigDomainId,
  MigStatus,
} from '../../../generated/graphql';
import { MigCard } from '../../mig/MigCard';
import { MigRelations } from '../../mig/MigCard/MigDescription/MigRelations';
import { MigPreviewTable } from '../migSetupPage/MirrorMigModal/MigPreviewTable';
import { TeamSelector } from '../migSetupPage/MirrorMigModal/TeamSelector';
import { gql, useQuery } from '@apollo/client';
import { showNotification } from '../../../services/fetchNotificationProperties';
import { useTranslation } from 'react-i18next';
import { useTeamFullMigsLazyV2 } from '../migSetupPage/hooks/useTeamMigV2';

type State = {
  teamId: string | null;
  migDomainId: MigDomainId | null;
};

interface Props {
  value: State;
  onChange: (value: State) => void;
  linkedMigs: LinkMigSteps_MigV2Fragment[];
}

export const LinkMigSteps = ({ value, onChange, linkedMigs }: Props) => {
  const { t } = useTranslation();

  const [fetchFullTeamMigs, { teamMigs, loading: teamMigsLoading }] =
    useTeamFullMigsLazyV2();

  const selectedTeamId = value.teamId;

  useEffect(() => {
    if (selectedTeamId)
      fetchFullTeamMigs({ variables: { teamId: selectedTeamId } });
  }, [selectedTeamId, fetchFullTeamMigs]);

  const { loading, data: teamsData } = useQuery(GetTeamsForLinkMigsDocument, {
    fetchPolicy: 'cache-and-network',
    onError: () => {
      showNotification('error', {
        message: <strong> {t('LinkMigSteps.errorFetchingTeams')}</strong>,
      });
    },
  });

  const teamsWithoutUmbrellaTeams = useMemo(() => {
    return (
      teamsData?.teams2.teams.filter((t) => !t.umbrellaTeam) ?? [] // && teamId !== t.id
    );
  }, [teamsData]);

  const previewMig = teamMigs.find(
    (mig) => mig.domainId.itemId === value.migDomainId?.itemId
  );

  return (
    <div className="flx">
      <div className="flx--1 pa Divider TeamSelector">
        <Typography.Title level={3}>{t('LinkMigSteps.step1')}</Typography.Title>

        <div>
          <Typography.Text type="secondary">
            {t('LinkMigSteps.activeTeams')}
          </Typography.Text>
          <div>
            <TeamSelector
              teams={teamsWithoutUmbrellaTeams}
              onChanged={(teamId) => {
                onChange({ teamId, migDomainId: null });
              }}
              selectedTeam={value.teamId}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <div className="flx--2 pa Divider">
        <Typography.Title level={3}>{t('LinkMigSteps.step2')}</Typography.Title>
        <Typography.Text type="secondary">{t('common.active')}</Typography.Text>
        <div className="mb--l">
          <MigPreviewTable
            loading={teamMigsLoading}
            linkedMigs={linkedMigs}
            previewMigs={
              value.teamId
                ? teamMigs?.filter((mig) => mig.status === MigStatus.ACTIVE)
                : []
            }
            onChange={(migId) => onChange({ ...value, migDomainId: migId })}
            value={value.migDomainId}
          />
        </div>
        <Typography.Text type="secondary">
          {t('common.upcoming')}
        </Typography.Text>
        <MigPreviewTable
          linkedMigs={linkedMigs}
          loading={teamMigsLoading}
          previewMigs={
            value.teamId
              ? teamMigs?.filter((mig) => mig.status === MigStatus.UPCOMING)
              : []
          }
          onChange={(migId) => onChange({ ...value, migDomainId: migId })}
          value={value.migDomainId}
        />
        <Divider type="vertical" />
      </div>
      <div className="flx--2 pa Divider">
        <Typography.Title level={3}>{t('LinkMigSteps.step3')}</Typography.Title>
        <Typography.Text type="secondary">
          {t('LinkMigSteps.mangedByTeam')}
          <Typography.Text strong>{previewMig?.team2.name}</Typography.Text>
        </Typography.Text>
        <Card styles={{ body: { padding: 0 } }}>
          {previewMig && (
            <MigCard
              mig={previewMig}
              showDescription={false}
              graphHeight={120}
            />
          )}
          {!previewMig && (
            <Typography.Text
              type="secondary"
              className="center-content"
              style={{ height: 150 }}
            >
              {t('LinkMigSteps.migPreviewHelp')}
            </Typography.Text>
          )}
          <div className="pa">
            <Typography.Text strong>{t('common.description')}</Typography.Text>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {previewMig?.description || '-'}
            </div>
          </div>

          <div className="pa">
            <Typography.Text strong>
              {t('LinkMigSteps.supportTitle')}
            </Typography.Text>
            <MigRelations relations={previewMig?.supportsMigs ?? []} />
          </div>
          <div className="pa">
            <Typography.Text strong>
              {t('LinkMigSteps.supportedByTitle')}
            </Typography.Text>
            <MigRelations relations={previewMig?.supportedByMigs ?? []} />
          </div>
        </Card>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GET_TEAMS_TO_LINK_MIGS = gql`
  query getTeamsForLinkMigs {
    teams2(filters: { includeArchived: false }) {
      teams {
        name
        id
        domainId {
          itemId
        }
        umbrellaTeam
        parentTeam {
          id
          domainId {
            itemId
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LINK_MIG_STEPS_MIG_V2 = gql`
  fragment LinkMigSteps_MigV2 on MigV2 {
    id
    domainId {
      itemId
      tenantId
    }
  }
`;
