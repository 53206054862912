import { Skeleton } from 'antd';

export const ActivityFormSkeleton = () => {
  return (
    <div>
      <div className="mb--l">
        <Skeleton.Input active block />
      </div>

      <div className="flx flx--gap mb--l">
        <Skeleton.Input active block style={{ width: '50%' }} />
        <Skeleton.Input active block style={{ width: '50%' }} />
      </div>

      <div className="mb--l">
        <Skeleton.Input active block />
      </div>

      <div className="flx flx--gap mb--l">
        <Skeleton.Input active block style={{ width: '50%' }} />
        <Skeleton.Input active block style={{ width: '50%' }} />
      </div>

      <div className="mb--l">
        <Skeleton.Input active block />
      </div>

      <div className="borderTop pt--l mt--xl">
        <div className="flx flx--jc-end">
          <Skeleton.Button active />
        </div>
      </div>
    </div>
  );
};
