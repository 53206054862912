import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { TeamMilestonesForMilestoneFilterDocument } from '../../../../generated/graphql';

interface Props {
  value: string | null;
  onChange: (milestoneId: string | null) => void;
  teamId: string;
}

export const MilestoneFilter = ({ teamId, value, onChange }: Props) => {
  const { data, loading } = useQuery(TeamMilestonesForMilestoneFilterDocument, {
    variables: {
      teamId,
    },
  });
  const { t } = useTranslation();

  const milestones = data?.milestonesWithLinksForTeam?.milestones ?? [];

  return (
    <div className="flx--1">
      <h5>{t('common.milestoneTitle')}</h5>
      <Select
        loading={loading}
        allowClear
        onClear={() => onChange(null)}
        popupMatchSelectWidth={false}
        value={value}
        onChange={onChange}
        placeholder={t('MilestoneFilter.allMilestones')}
        className="d-block"
        options={milestones.map((o) => ({
          label: o.name,
          value: o.domainId.itemId,
        }))}
      />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MILESTONE_FILTER_QUERY = gql`
  query TeamMilestonesForMilestoneFilter($teamId: ID!) {
    milestonesWithLinksForTeam(teamId: $teamId) {
      milestones {
        id
        domainId {
          itemId
        }
        name
      }
    }
  }
`;
