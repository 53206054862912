import './ActivityMilestone.less';

import Skeleton from 'react-loading-skeleton';

export const ActivityMilestoneSkeleton = () => {
  return (
    <div className={`card mb ActivityMilestone ActivityMilestone--ARCHIVED`}>
      <div className="flx">
        <div className="mr">
          <Skeleton />
        </div>
        <h5>
          <Skeleton />
        </h5>
      </div>
      <div>
        <div>
          <Skeleton />
        </div>
      </div>
      <div className="divider mt--s mb--s" />
      <div className="flx">
        <div className="mr--s txt--secondary font-size--sm">Activities:</div>
        <Skeleton />
      </div>
    </div>
  );
};
