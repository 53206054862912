import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Alert, Modal, Select, Typography } from 'antd';
import { useEffect, useReducer, useState } from 'react';
import {
  WeeklyKaSelectGetTeamsDocument,
  WeeklyKaSelectGetTeamWeeklyKaDocument,
} from '../../../generated/graphql';
import { weeklyKeyActivityDTOtoAkpiData } from '../../../services/akpiUtils';
import { PeriodDataGraph } from '../../PeriodDataGraph';
import { useTranslation } from 'react-i18next';
import { Btn } from '../../Button';

interface WeeklyKa {
  id?: string;
  name: string;
  description?: string | null;
  acceptanceCriteria?: string | null;
  commitmentStrategyEnabled: boolean;
  achievementDescriptionMandatory: boolean;
  currentPeriodIndex: number | null;
  startDate: string;
  endDate: string;
  unit: string;
  periodData: Period[];
  migRelations?:
    | {
        mig: { id: string; name: string };
        team: { id: string; name: string };
      }[]
    | null;
  ignoreListedUsers?: string[];
}

interface Period {
  targetDate: string;
  label: string;
  goal?: number | null;
  actual?: number | null;
  committed?: number | null;
  historic?: number | null;
}

interface Props {
  tenantId?: string;
  className?: string;
  onConfirm?: (akpiMeta: WeeklyKa) => void;
}

export const CopyWeeklyKeyActivityModal = ({
  tenantId,
  onConfirm,
  className,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const [{ selectedTeam, selectedAkpi }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const { data: teamData } = useQuery(WeeklyKaSelectGetTeamsDocument, {
    variables: { tenantId },
  });

  const [getWeeklyKeyActivities, { data: wkaData }] = useLazyQuery(
    WeeklyKaSelectGetTeamWeeklyKaDocument
  );

  const weeklyKeyActivities = wkaData?.weeklyKeyActivities.map(
    weeklyKeyActivityDTOtoAkpiData
  );

  useEffect(() => {
    if (selectedTeam) {
      getWeeklyKeyActivities({ variables: { tenantId, teamId: selectedTeam } });
    }
  }, [selectedTeam, getWeeklyKeyActivities, tenantId]);

  return (
    <>
      <Btn className={className} onClick={() => setVisible(true)}>
        {t('CopyWeeklyKeyActivityModal.copy')}
      </Btn>

      <Modal
        title={t('CopyWeeklyKeyActivityModal.header')}
        open={visible}
        onCancel={() => {
          setVisible(false);
          dispatch({ type: 'reset' });
        }}
        okButtonProps={{ disabled: !selectedAkpi }}
        okText="Continue"
        onOk={() => {
          if (!selectedAkpi) return;
          onConfirm?.(selectedAkpi);
          setVisible(false);
          dispatch({ type: 'reset' });
        }}
        destroyOnClose
      >
        <div className="flx flx--column">
          <Select
            autoFocus
            showSearch
            optionFilterProp="label"
            className="mb"
            value={selectedTeam}
            onChange={(value) =>
              dispatch({ type: 'selectTeam', teamId: value })
            }
            placeholder={t('CopyWeeklyKeyActivityModal.selectTeam')}
            options={teamData?.teams2.teams.map((t) => ({
              value: t.domainId.itemId,
              label: t.name,
            }))}
          />
          <Select
            showSearch
            optionFilterProp="label"
            className="mb"
            value={selectedAkpi?.id}
            onChange={(value) =>
              dispatch({
                type: 'selectKeyActivity',
                keyActivity: weeklyKeyActivities?.find((a) => a.id === value),
              })
            }
            placeholder={t('CopyWeeklyKeyActivityModal.selectKa')}
            options={weeklyKeyActivities?.map((t) => ({
              value: t.id,
              label: t.name,
            }))}
          />

          {selectedAkpi && (
            <>
              <div>
                <Alert
                  className="mb"
                  type="info"
                  message={t('CopyWeeklyKeyActivityModal.info')}
                  description={
                    <>
                      <p>{t('CopyWeeklyKeyActivityModal.copyInfo')}</p>
                      <PeriodDataGraph
                        startDate={selectedAkpi.startDate}
                        periodData={selectedAkpi.periodData}
                        unit={selectedAkpi.unit}
                        height={200}
                      />
                    </>
                  }
                />

                <Typography.Text strong>
                  {t('common.description')}
                </Typography.Text>
                <Typography.Paragraph>
                  {selectedAkpi.description}
                </Typography.Paragraph>

                <Typography.Text strong>
                  {t('CopyWeeklyKeyActivityModal.dod')}
                </Typography.Text>
                <Typography.Paragraph>
                  {selectedAkpi.acceptanceCriteria}
                </Typography.Paragraph>

                <Typography.Text strong>
                  {t('CopyWeeklyKeyActivityModal.unit')}
                </Typography.Text>
                <Typography.Paragraph>{selectedAkpi.unit}</Typography.Paragraph>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

interface S {
  selectedTeam: string | undefined;
  selectedAkpi: WeeklyKa | undefined;
}

type A =
  | { type: 'selectTeam'; teamId?: string }
  | { type: 'selectKeyActivity'; keyActivity?: WeeklyKa }
  | { type: 'reset' };

const initialState: S = {
  selectedTeam: undefined,
  selectedAkpi: undefined,
};

function reducer(state: S, action: A) {
  switch (action.type) {
    case 'selectTeam':
      return { selectedTeam: action.teamId, selectedAkpi: undefined };
    case 'selectKeyActivity':
      return { ...state, selectedAkpi: action.keyActivity };
    case 'reset':
      return initialState;
    default:
      throw new Error();
  }
}

export const WEEKLY_KA_SELECT_LIST_TEAMS = gql`
  query weeklyKaSelectGetTeams($tenantId: ID) {
    teams2(tenantId: $tenantId) {
      teams {
        name
        id
        domainId {
          itemId
          tenantId
        }
      }
    }
  }
`;

export const WEEKLY_KA_SELECT_TEAM_AKPIS = gql`
  query weeklyKaSelectGetTeamWeeklyKa($tenantId: ID, $teamId: ID!) {
    weeklyKeyActivities(tenantId: $tenantId, teamId: $teamId) {
      id
      name
      description
      acceptanceCriteria
      currentPeriodIndex
      achievementDescriptionMandatory
      commitmentStrategyEnabled
      startDate
      endDate
      unit
      supportsMigsDetails {
        id
        name
        lastOutcomeDate
        domainId {
          itemId
          tenantId
          teamId
        }
        team2 {
          id
          domainId {
            itemId
            tenantId
          }
          name
        }
      }
      periodData {
        targetDate
        goal
        actual
      }
    }
  }
`;
