import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './MigDetails.less';
import { gql } from '@apollo/client';
import { MigDetails_MigV2Fragment } from '../../../../../../generated/graphql';
import { atLeastOne } from '../../../../../../services/error-parser/helpers';

interface Props {
  mig: MigDetails_MigV2Fragment;
}

export const MigDetails = ({ mig }: Props) => {
  const { t } = useTranslation();

  return (
    <div
      className="MigDetails flx flx--column ml--l scroll-y"
      data-image-ignore
    >
      <div className="mb--m" data-intercom-target="MIG description area">
        <h3>{t('common.description')}</h3>

        <div className="mt--s">
          {mig.description ? (
            <span className="MigDetails__description preserveLinebreaks ">
              {mig.description}
            </span>
          ) : (
            <span className="txt--disabled">
              {t('MigDetails.noMigDescription')}
            </span>
          )}
        </div>
      </div>

      {atLeastOne(mig.supportsMigs) && (
        <div className="mb--m" data-intercom-target="MIG supports area">
          <h4>{t('common.supports')}</h4>
          {mig.supportsMigs.map((m, index) => (
            <div className="MigDetails__supports mt--s" key={index}>
              <Link
                className="space--r"
                to={`/team/${m.team2.domainId.itemId}/overview`}
                target={'_blank'}
              >
                {m.team2.name}
              </Link>
              <span className="txt--secondary">
                {t('MigDetails.supportHeader')}
              </span>
              <ul className="pl">
                <li>{m.name}</li>
              </ul>
            </div>
          ))}
        </div>
      )}

      {atLeastOne(mig.supportedByMigs) && (
        <div data-intercom-target="MIG supported by area">
          <h4>{t('common.supportedBy')}</h4>
          {mig.supportedByMigs.map((m, index) => (
            <div className="MigDetails__supports mt--s" key={index}>
              <Link
                className="space--r"
                to={`/team/${m.team2.domainId.itemId}/overview`}
                target={'_blank'}
              >
                {m.team2.name}
              </Link>
              <span className="txt--secondary">
                {t('MigDetails.supportHeader')}
              </span>
              <ul className="pl">
                <li>{m.name}</li>
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MIG_DETAILS_MIG_FRAGMENT = gql`
  fragment MigDetails_MigV2 on MigV2 {
    id
    description
    supportedByMigs {
      id
      name
      team2 {
        id
        domainId {
          itemId
        }
        name
      }
    }
    supportsMigs {
      id
      name
      team2 {
        id
        domainId {
          itemId
        }
        name
      }
    }
  }
`;
